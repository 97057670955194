@rm-comp 'eGCkS_lnpp' {

    @import 'sass/variables';

    .projects-carousel {
        position: relative;
    }

    .controls {
        background: white;
        width: 100%;
        padding: 10px 20px;
        padding-bottom:25px;
        display: block;
        text-align: center;
        z-index:-1;
        @media #{$xs-only} {
            padding: 50px 15px;
            padding-top:10px;
            padding-bottom:20px;
        }
        @media #{$md-only} {
            padding-top:20px;
        }

        .dots {
            width:14px;
            height:14px;
            border:1px solid $black;
            border-radius:100%;
            display:inline-block;
            vertical-align:middle;
            margin:5px;
            border:1px solid $white;
            background-color:$black;
            @media #{$sm-only, $xs-only} {
                width:10px;
                height:10px;
                margin:4px;
            }
        }

        .selected-dot {
            background-color:$white;
            border:1px solid $black;
        }
    }
    
    .hidden-slider[hidden] {
        display: block !important;
    }
}