@rm-comp 'POvL6BZiSN' {

    @import 'sass/variables';

    .video {
        position:relative;
        background-color: black;
        @media #{$xl-up} {
            overflow:hidden;
        }
        .video-bg {
            position: relative;
            /* background-image: url(images/video-placeholder.jpg); */
            background-repeat:no-repeat;
            background-size:cover;
            background-position:center;
            width:100%;
            height:100%;
            height: 56.25vw;

            @media #{$xl-up} {
                height:calc(100vh - 219px);
                max-height: 55.25vw;
            }

            > * {
                width: 100%;

                @media #{$xl-up} {
                    position:absolute;
                    top:50%;
                    left:50%;
                    transform: translate(-50%,-50%);
                }

                > video {
                    width:100%;
                }
            }
        }

        .preloader {
            position: absolute;
            top:0;
            left:0;
            right:0;
            bottom:0;
            color:white;

            > * {
                margin: auto;
            }
        }

        .play-btn {
            position: absolute;
            top:0;
            left:0;
            right:0;
            bottom:0;
        }
    }

    .controls {
        opacity: 1;
        pointer-events: auto;

        transition: opacity 0.5s ease;
        &.hideControls {
            opacity: 0;
            pointer-events: none;
        }
    }
    
    .transcript {
        width: 30px;
        position: absolute;
        bottom: 36px;
        left: 190px;
        @media #{$sm-only} {
            bottom:8px;
            left:115px;
            transform: scale(0.75);
            transition: 0.5s ease-in-out;
        }
        @media #{$xs-only} {
            bottom: 4px;
            left: 80px;
            transform: scale(0.55);
            transition: 0.5s ease-in-out;
        }

        > * {
            vertical-align: top;
        }
    }

    .volume {
        background-image: url(images/volume.svg);
        /* width:40px; */
        background-repeat: no-repeat;
        background-size: 40px 32px;
        height:31px;
        position:absolute;
        bottom:40px;
        left:40px;
        color:white;
        font-size:20px;
        padding-top:5px;
        -webkit-animation:pop-in 0.5s;
        -moz-animation:pop-in 0.5s;
        -ms-animation:pop-in 0.5s;
        transition: 0.5s ease-in-out;

        @media #{$lg-up} {
            display: block;
        }
        @media #{$sm-only} {
            bottom:10px;
            left:5px;
            transform: scale(0.75);
            transition: 0.5s ease-in-out;
        }
        @media #{$xs-only} {
            bottom: 5px;
            left: -10px;
            transform: scale(0.55);
            transition: 0.5s ease-in-out;
        }
        .on-off.selected {
            color: $yellow;
        }
        &.volume-on {
            background-image: url(images/volume-y.svg);
        }
        .on-off {
            @media #{$xs-only, $sm-only} {
                font-size:16px;
            }
        }
        span.on {
            margin-left:50px;
        }
        img {
            display:inline-block;
            width:40px;
            margin-right:10px;
            @media #{$xs-only, $sm-only} {
                width:30px;
                vertical-align:middle;
                margin-top:-10px;
            }
        }
    }

    .social-media {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        position: absolute;
        bottom:40px;
        right:2%;
        -webkit-animation:pop-in 0.5s;
        -moz-animation:pop-in 0.5s;
        -ms-animation:pop-in 0.5s;
        @media #{$sm-up} {
            display: flex;
        }
        @media #{$xs-only, $sm-only} {
            bottom:5px;
/*            bottom:10px;*/
        }
            img {
                max-width: 30px;
                margin: 10px;
            }
            .bg-img {
                width:27px;
                height:25px;
                background-size:cover;
                margin:10px;
/*                margin:15px;*/
                transition:background-image 0.5s ease-in-out;
                @media #{$xs-only, $sm-only} {
                    width:20px;
                    height:20px;
                    margin:5px;
                }
            }
                .instagram {
                    background-image: url(images/icons/SVG/instagram-w.svg);
                    &:hover {
                        background-image: url(images/icons/SVG/instagram-y.svg);
                        transition:background-image 0.5s ease-in-out;
                    }
                }
                .twitter {
                    background-image: url(images/icons/SVG/twitter-w.svg);
                    &:hover {
                        background-image: url(images/icons/SVG/twitter-y.svg);
                        transition:background-image 0.5s ease-in-out;
                    }
                }
                .facebook {
                    background-image: url(images/icons/SVG/facebook-w.svg);
                    &:hover {
                        background-image: url(images/icons/SVG/facebook-y.svg);
                        transition:background-image 0.5s ease-in-out;
                    }
                }
                .linkedin {
                    background-image: url(images/icons/SVG/linkedin-w.svg);
                    &:hover {
                        background-image: url(images/icons/SVG/linkedin-y.svg);
                        transition:background-image 0.5s ease-in-out;
                    }
                }
            }



    .video-placeholder {
        background-image: url(images/video-placeholder.jpg);
        background-size:cover;
        background-position:center;
        background-repeat: no-repeat;
        width:100%;
        height:518px;
    }

    /* Repositioning the screen reader button, because I think it's causing the contents of this banner to 'jump' up the screen */

    .sr-playPauseButton {
        top: 50%;
    }

    
    .arrow-down {
        font-size:30px;
        display:block;
        margin:0 auto;
        height:40px;
        width:40px;
        position: absolute;
        z-index: 5;
        bottom: 4%;
        right: 50%;
        margin-right: -20px;
        border-radius: 50%;
        cursor:pointer;
        
        /* @media #{$lg-up} {
            display:block;
        } */
        
        .chevron {
            color:white;
            top: 6px;
            left: 11px;
            animation: arrow-scroll 1.5s ease-in infinite;
        }

    .chevron-video {
            color:white;
            top: 6px;
            left: 11px;
            animation: arrow-scroll 1.5s ease-in infinite;
        }
}
    
    .video-outer {
        position: relative;
    }

}