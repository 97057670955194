@rm-comp 'JEBmE_7h4e' {

    @import 'sass/variables';

    * {
        box-sizing: border-box;
        font-family: 'Open Sans', sans-serif;
        line-height:1.2;
    }

    .mobile {
        @media #{$xs-up} {
            display: block;
        }
        @media #{$md-up} {
            display: none;
        }
        /*@media #{$height} { display: block; }*/
        a {
            color: $black;
            text-decoration: none;
            cursor: pointer;
        }


        .download {
            width: 100%;
            margin: 0 auto;
            text-align: center;
            height: 80px;
            background-color: $black;
            color: $white;
            padding: 10px 0px;
            @media #{$height} {
                height: 120px;
                padding-top: 30px;
            }
            .download-icon {
                width: 30px;
                margin: 0 auto;
                text-align: center;
            }

            .download-flex {
                display: flex;
                width: 20%;
                align-items: center;
                justify-content: space-around;
                margin: 10px auto;

            }

        }


        .contact-us {
            width: 100%;
            background-color: $yellow;
            height: 230px;
            text-align: left;
            color: $black;
            padding: 20px 0px;
            transition: 0.5s ease-in-out;
            @media #{$xs-only, $sm-only} {
                height: 210px;
                transition: 0.5s ease-in-out;
            }
            @media #{$height} {
                height: 300px;
                padding: 40px 0px;
                padding-top: 60px
            }
            .contactus-flex {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                width: 80%;
                margin: 15px auto;
            }

            .text-title {
                font-size: 20pt;
                text-align: left;
                margin: 0 auto;
                width: 80%;
                font-weight: bolder;

            }

            .footer-icon {
                width: 27px;
                margin-right: 20px;
                margin-top: -5px;
                margin-left:0;
                @media #{$xs-only} {
                    min-width: 27px;
                }
            }
        }
        
        .arrow_down {
            position: absolute;
            margin-top: -15px;
            right: 10%;
        }
        
        .social-media {
            width: 100%;
            background-color: $black;
            color: $white;
            padding-bottom: 30px;
            min-height: calc(100vh - 1001px);
            .socialmedia-flex {
                width: 100%;
                display: flex;
                text-align: center;
                align-items: center;
                justify-content: space-around;
                padding: 20px 0px;
            }

            img {
                height: 25px;
            }
            .instagram {
                background-image: url(./images/icons/SVG/instagram-y.svg);
                width: 27px;
                height: 25px;
                background-size: cover;
                margin: 10px;
            }
            .twitter {
                background-image: url(./images/icons/SVG/twitter-y.svg);
                width: 27px;
                height: 25px;
                background-size: cover;
                margin: 10px;
            }
            .facebook {
                background-image: url(./images/icons/SVG/facebook-y.svg);
                width: 27px;
                height: 25px;
                background-size: cover;
                margin: 10px;
            }
            .linkedin {
                background-image: url(./images/icons/SVG/linkedin-y.svg);
                width: 27px;
                height: 25px;
                background-size: cover;
                margin: 10px;
            }
        }
        .footer-legal {
            color: $white;
            font-size: 10pt;
            text-align: center;
            margin-top: 5px;

        .footer-icon {
            display: none;
        }
            
            .privacy {
                color: $white;
                cursor: pointer;
                font-size: 10pt;
                text-decoration: none;

            }
        }
    }

}