@rm-comp '-QXYKuSOsjF' {

    @import 'sass/variables'; 

    img {
         clear: both;
    }

    input {
        font-size: 16px;
        padding: 4px;
        float: left;
        background-color: #fff;
        border: 2px solid #bbb;
    }

    a {
        text-decoration: none;
    }

    .search-wrapper {
        padding: 20px 20px;
        max-width: 1024px;
        background-color: #FFF;
        margin: 0 auto;
        overflow: hidden;
        position: relative;
        z-index: 0;
        min-height: calc(100vh - 400px);
    }

    .search {
        display: inline-block;
    }


    .search-btn{
        margin-left: 10px; 
        padding: 4px; 
        cursor: pointer; 
        border: 2px solid #FFC425; 
        font-weight:bold; 
    }

    .searchResultsFound {
        font-size: 20px;
        color: #edaa00;
        margin-bottom: 18px;
    }

    .searchResultTitle {
        color: #333333;
        margin-bottom: 5px;
        font-size: 20px;
    }

    .searchResultDescription {
        color: #666666;
        font-size: 16px;
        overflow: hidden;
    }

    .searchResultsList hr {
        margin: 15px 0;
        color: #cccccc;
        background-color: #cccccc;
        border-color: #cccccc;
    }

    .searchResultIcon {
        float: left;
        margin-right: 12px;
        border: 1px solid #cccccc;
    }

    .searchResultsList hr {
        margin: 15px 0;
        color: #cccccc;
        background-color: #cccccc;
        border-color: #cccccc;
        height: 1px;
        border: 0;
    }

    .keywords{
        background-color:rgba(231, 228, 79, .8);
    }
    .searchResult {
        height: 100%;
        display: block;
    }

}