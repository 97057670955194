.material_div {
    position: relative;
}

.material_field {
    position: absolute;
    // font-size: 11px;
    font-size: 13px;
    color: #b9b9b9;
    top: 6px;
    left: 11px;
    transition: 0.05s ease-in-out;
    @media #{$md-up} {
        left: 17px;
        font-size: 10px;
    }
}

.material_field_placeholder {
    position: absolute;
    font-size: 11px;
    color: #b9b9b9;
    top: 20px;
    left: 11px;
    transition: 0.05s ease-in-out;
    @media #{$md-up} {
        left: 17px;
        font-size: 15px;
    }
}

.no-label {
    transition: 0.05s ease-in-out;
    visibility: hidden;
}

.button-holder {
    position:absolute;
    right: 25px;
    top: 25px;
    width:60px;
    .canvas-site {
        position:absolute;
        right: 40px;
        top: -78px;
        width:175px;
        @media #{$xs-only} {
            top: -60px;
            right:0px;
        }
        canvas {
            @media #{$xs-only} {
                width:200px;
            }
        }
    }
    .pointer {
        path {
            stroke-dasharray: 50;
            stroke-dashoffset: -50;
            animation: draw 3s forwards;
        }
        &:hover {
            opacity: 1;
        }
        .point-arrow {
            position: absolute;
            width: 95px;
            top: 10px;
            height: 60px;
            right: 50px;
            z-index: 1;
            @media #{$xs-only} {
                right:20px;
                top:-12px;
            }
        }
    }

    .disappear {
        opacity: 0;
        transition: 0.5s ease-in-out;
        transition-delay: 2s;
    }
    .post-load {
        opacity: 0;
        transition: 0.5s ease-in-out;
        transition-delay: none;
        .canvas-site {
            opacity: 0;
            transition: 0.5s ease-in-out;
            overflow-x:hidden;
        }
    }
    .customize {
        position: absolute;
        width: 30px;
        height: 30px;
        left: 20px;
        top: -7px;
        margin: 5px;
        cursor: pointer !important;
        z-index: 7;
        transition: 0.5s ease-in-out;
        @media #{$xs-only} {
            top: 0px;
        }
        &:hover {
            -webkit-transform: scale(1.1);
            -webkit-transform-origin: center;
            -webkit-transition: 0.5s ease-in-out;
            -moz-transform: scale(1.1);
            -moz-transform-origin: center;
            -moz-transition: 0.5s ease-in-out;
            transform: scale(1.1);
            transform-origin: center;
            transition: 0.5s ease-in-out;
        }
        @media #{$sm-up} {
            width: 50px;
            height: 50px;
            left: 25px;
            top: 0px;
            margin: 0; 
        }
        @media #{$md-up} {
            width: 60px;
            height: 60px;
            left: 5px;
        }
        @media #{$lg-up} {
            width: 60px;
            height: 60px;
            left: 0;
        }
    }
}

.button-holder:hover .pointer {
    opacity: 1;
    .canvas-site {
        opacity: 1;
    }
}

.button-holder:hover .pointer.post-load.hover-off {
    opacity: 0;
}

.personalization-quiz {
    box-sizing: border-box;
    position: absolute;
    z-index: 6;
    top: 40px;
    right: 50px;
    min-height: 120px;
    background: rgba(26, 26, 26, 0.9);
    animation: quiz 0.5s ease;
    color: white;
    padding: 10px 16px;
    max-height: 520px;
    display: none;
    /* height: 360px;
    width: 230px;*/
    height: 395px;
    width: 252px;
    @media #{$xs-up} {
        top: 25px;
        right: 25px;
    }
    @media #{$sm-up} {
        right: 35px;
        top: 35px;
        padding: 20px 16px;
    }
    @media #{$md-up} {
        top: 40px;
        right: 43px;
        width: 271px;
        padding: 20px 16px;
        
    }
    @media #{$lg-up} {
        top: 70px;
        right: 75px;
        width: 271px;
    }
}

.one {
    margin: 0 auto;
}


.interests {
    overflow: auto;
    max-height: 350px;
}

.floating {
    position: fixed;
    bottom: 20px;
    right: 0;
    text-align: left;
    width: 100vw;
    height: 60px;
    color: black;
    background-color: $yellow;
    cursor: pointer;
    box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.4);
    padding: 0;
    @media #{$sm-up} {
        bottom: 8px;
        width: 100vw;
        text-align: left;
    }
    @media #{$md-up} {
        bottom: 8px;
        width: 100vw;
        text-align: left;
    }
    @media #{$lg-up} {
        bottom: 8px;
        width: 320px;
        text-align: left;
        padding: 0;
    }

    .card-container {
        width: 100%;
        margin-left: 10%;
        height: inherit;
        @media #{$sm-up} {
            margin-left: 25%;
        }
        @media #{$md-up} {
            margin-left: 30%;
        }
        @media #{$lg-up} {
            margin-left: 5%;
        }
    }

    .left {
        float: left;
        width: 60%;
        @media #{$sm-up} {
            width: 40%;
        }
        @media #{$md-up} {
            width: 32%;
        }
        @media #{$lg-up} {
            width: 75%;
        }
        h2 {
            font-size: 20px;
            color: black;
            padding: 5px 0 5px 0px;

            @media #{$md-up} {
                padding: 5px 0 0 5px;
            }
        }
    }

    .right {
        width: 25%;
        float: left;
        @media #{$md-up} {
            width: 40%;
        }
        @media #{$lg-up} {
            width: 25%;
        }
        .card {
            img {
                width: 95%;
            }
            position:absolute;
            width:65px;
            height:65px;
            border-radius:80px;
            background-color:$yellow;
            top:-10px;
            text-align:center;
        }
    }
    .text {
        color: black;
        padding: 5px 0 0 0px;
        @media #{$md-up} {
            padding: 5px 0 0 5px;
        }
    }
}

/* buttons */
.button {
    background-color: $yellow;
    color: black;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 1px;
    padding: 10px 30px;
    border: 1px solid $black;
    border-radius: 10px;
    text-align: center;
    transition: background-color 0.5s ease;
    display: inline-block;
    margin: 15px auto;
    @media #{$xs-only, $sm-only} {
        font-size: 12px;
    }
    @media #{$sm-up} {
        // padding: 5px 5px;

    }
    &:hover {
        background-color: $black;
        border: 1px solid $yellow;
        border-style: inset;
        color: $yellow;
        transition: background-color 0.5s ease;
    }
    .chevron {
        color: $black;
        top: 6px;
        left: 10px;
        &:hover {
            color: $yellow;
        }
    }
    &.two {
        margin: 25px 10px 10px 10px;

    }
    &.contact {
        margin: 5px;
        width: 175px;
        height: 35px;
        padding: 2px 22px;
        text-align: center;
        font-size: 12px;
        @media #{$lg-up} {
            margin: 25px 10px 10px 10px;
            width: 175px;
            height: 35px;
            padding: 2px 22px;
            text-align: center;
            font-size: 16px;
        }
    }
    a {
        text-decoration: none;
        color: black;
        &:hover {
            color: $yellow;
        }
    }
}

.button:hover .chevron {
    color: $yellow;

}

.button:hover a {
    color: $yellow;
}

/*type*/
h2 {
    font-size: 15px;
    color: $yellow;
    padding: 10px 0 0 0;
    @media #{$md-up} {
        font-size: 20px;
    }
}

h3 {
    font-size: 18px;
    color: $yellow;
    padding: 5px 0 0 0;
    @media #{$sm-up} {
        color: $yellow;
        padding: 5px 0 0 0;
    }
    @media #{$lg-up} {
        font-size: 30px;
        color: $yellow;
        padding: 5px 0 0 0;
    }
}

.copy {
    margin-left: 10px;
    text-align: center;
}

.am-email {
    font-size: 14px;
    @media #{$lg-up} {
        font-size: 18px;
    }
}

.am-phone {
    font-size: 14px;
    @media #{$lg-up} {
        font-size: 18px;
    }
}

p {
    font-size: 1em;
    color: white;
    padding: 15px 0 10px 0;
}

a {
    text-decoration: none;
    color: white;
    &:hover {
        color: $yellow;
    }
}

/*select dropdown*/
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    color: white;
    height: 40px;
    border-bottom: 1px solid white;
    padding: 0 10px 0 5px;
    // margin-top: 10px;
    margin-top: 17px;
    cursor: pointer;
    background-image: url(images/icons/arrow-down.png);
    background-repeat: no-repeat;
    background-position: right;
    // font-size: 12px;
    font-size: 16px;
    // width: 185px;
    width: 208px;
    @media #{$sm-up} {
        // width: 185px;
        width: 208px;
    }
    @media #{$md-up} {
        width: 215px;
        font-size: 15px;
        margin-top: 10px;
    }
    option {
        background-color: black;
        cursor: pointer;
        &:active {
            background-color: $yellow;
            color: black;
        }
        &:focus {
            background-color: $yellow;
            color: black;
        }
    }
}

.select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    color: white;
    height: 40px;
    border-bottom: 1px solid white;
    padding: 0 10px 0 5px;
    // margin-top: 10px;
    margin-top: 17px;
    cursor: pointer;
    // font-size: 12px;
    font-size: 16px;
    // width: 185px;
    width: 208px;
    @media #{$sm-up} {
        width: 185px;
        font-size: 15px;
    }
    @media #{$md-up} {
        width: 215px;
    }
    option {
        background-color: black;
        cursor: pointer;
        &:active {
            background-color: $yellow;
            color: black;
        }
        &:focus {
            background-color: $yellow;
            color: black;
        }
    }
}

.first:focus + .submenu,
.submenu:hover {
    display: block;
}

select::-ms-expand {
    display: none;
}

input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

input[type='number'] {
    -moz-appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.type-in {
    background: transparent;
    color: white;
    // width: 185px;
    width: 208px;
    height: 40px;
    border-bottom: 1px solid white;
    padding: 0 10px 0 5px;
    // margin-top: 10px;
    margin-top: 17px;
    cursor: pointer;
    // font-size: 12px;
    font-size: 16px;
    &:focus {
        border-bottom: 1px solid $yellow;

    }
    @media #{$sm-up} {
        // width: 185px;
        width: 208px;
        height: 40px;
    }
    @media #{$md-up} {
        width: 215px;
        font-size: 15px;
        height: 40px;
        margin-top: 10px;
    }
}

textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

::placeholder {
    color: white;
    opacity: 1;

}

.userMessage {
    background: transparent;
    color: white;
    border-bottom: 1px solid white;
    padding: 10px 10px 0 10px;
    margin-top: 10px;
    cursor: pointer;
    width: 260px;
    height: 60px;
    font-size: 13px;
    &:focus {
        border-bottom: 2px solid $yellow;
    }
    @media #{$sm-up} {
        width: 285px;
        font-size: 1em;
        height: 90px;
    }
}

/*contact*/

.info {
    width: 240px;
    height: auto;
    margin: auto;
    @media #{$md-up} {
        width: 250px;
    }
    @media #{$lg-up} {
        width: 300px;
    }
}

.icon {
    margin-top: 5px;
    text-align: left;
    img {
        vertical-align: top;
        margin-right: 5px;
    }
}

/*Checkboxes*/

.products {
    box-sizing: border-box;
}

.text {
    font-size: 13px;
    @media #{$sm-up} {
        font-size: 16px;
    }
}


/*constant variables*/

.rm-checkbox {
    display: inline-block;
    text-align: left;
}

.rm-checkbox input {
    position: absolute;
    width: 0px;
    height: 0px;
    opacity: 0;
}

.rm-checkbox .overlay {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    border-radius: 20px;
}

.rm-checkbox label {
    display: block;
}

.rm-checkbox input + label .overlay:before {
    position: absolute;
    content: "";
    -webkit-transform: scale(0);
    transform: scale(0);
}

.rm-checkbox input:checked + label .overlay:before {
    -webkit-transform: scale(1);
    transform: scale(1);
}

checked {
    display: none;
}


/*modifiable variables*/

.rm-checkbox .overlay {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border: 2px solid white;
    @media #{$md-up} {
        width: 25px;
        height: 25px;
    }
}

.rm-checkbox label {
    cursor: pointer;
    margin-top: 12px;
}

.rm-checkbox input + label .overlay:before {
    top: 5px;
    left: 5px;
    background: $yellow;
    width: 15px;
    height: 15px;
    border-radius: 20px;
    -webkit-transition: -webkit-transform 0.5s;
    transition: transform 0.5s;
    @media #{$md-up} {
        width: 10px;
        height: 10px;
    }
}

.rm-checkbox input:focus + label .overlay {
    border: 2px solid $yellow;
}

/*checkboxes columns*/

.column1,
.column2 {
    float: left;
    width: 100vw;
    text-align: left;
    @media #{$sm-up} {
        width: 49%
    }
    @media #{$md-up} {
        width: 49%
    }
}

.avatar {
    transition: 0.5s ease-in-out;
    margin: 0 auto;
    img {
        width: 20%;
        @media #{$lg-up} {
            width: 25%;
        }
    }
    @media #{$sm-up} {
        display: block;
    }
    @media #{$lg-up} {
        width: 80%;
        display: block;
    }
}

//.canvas-site {
//    display: flex;
//    width: 100%;
//    margin: 0 auto;
//    transition: 0.25s ease-in-out;
//    transform: scale(0.75);
//    transform-origin: right;
//    position:absolute;
//    top:-80px;
//    right:50px;
//        @media #{$xs-only} {
//            transform: scale(0.675);
//            transform-origin: right;
//            transition: 0.5s ease-in-out;
//            top: -76px;
//            right: -173px;
//        }    
//        @media #{$sm-only} {
//            transform: scale(0.675);
//            transform-origin: right;
//            transition: 0.5s ease-in-out;
//            top: -76px;
//            right: -173px;
//        }    
//        @media #{$md-only} {
//            transform: scale(0.675);
//            transform-origin: right;
//            transition: 0.5s ease-in-out;
//            top: -74px;
//            right: -166px;
//        }
//}


//.dots {
//    width: 120px;
//    margin: 0 auto;
//    height: auto;
//    .circle {
//        float: left;
//        margin: 5px;
//        width: 18px;
//        height: 18px;
//        border-radius: 18px;
//        border: 1px solid white;
//        &.filled {
//            background-color: white;
//        }
//        @media #{$lg-up} {
//            margin: 5px;
//            width: 10px;
//            height: 10px;
//            border-radius: 10px;
//
//        }
//    }
//
//    @media #{$lg-up} {
//        width: 80px;
//        margin: 0 auto;
//        height: auto;
//
//    }
//}

.person {
    -webkit-animation: showUp-2 3s ease-in-out;
    -moz-animation: showUp-2 3s ease-in-out;
    animation: showUp-2 3s ease-in-out;
}

.inner-circle {
    -webkit-animation: rotate2 5s ease-in-out infinite;
    -moz-animation: rotate2 5s ease-in-out infinite;
    animation: rotate2 5s ease-in-out infinite;
    transform-origin: center center;
    fill: $yellow;
}

svg {
    overflow: visible;
}

.hide {
    display: none;
    animation: quiz-gone 0.5s ease;
}

.show {
    display: block;
    text-align: center;
}

@keyframes dash {
    from {
        stroke-dashoffset: 1000;
    }
    to {
        stroke-dashoffset: 0;
    }
}

.suggestion-container {
    position: relative;
    display: inline-block;
}

.suggestion-items {
    position: absolute;
    text-align: left;
    width: 215px;
    font-size: 16px;
    background-color: black;
    color: white;
    border: 1px solid #ffffff;
    border-top: none;
    border-bottom: none;
    z-index: 30;
    top: 100%;
    left: 0px;
    right: 0px;
}

.suggestion-items.filled {
    border-top: 1px solid #ffffff;
    border-bottom: 1px solid #ffffff;
}

.suggestion-items div {
    padding-left: 5px;
    cursor: pointer;
    background-color: black;
}

.suggestion.suggestion-active {
    background-color: #ffc425;
    color: black;
}

.IE-arrow {
    margin-left: -65px;
    margin-top: 15px;
    display: none;
} 

.IE-customize {
    display: none;
    height: 42px;
    width: 42px;
    background-color: #ffffff;
    border-radius: 50%;

    img {
        height: 100%;
        width: 100%;
    }
}
// IE ONLY //
@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
    .IE-arrow {
        display: block;
    }
    
    .IE-customize {
        display: block;
    }
    .customize-btn {
        display: none;
    }
   
}

