@rm-comp 'GHZy_EdRmby' {

    @import 'sass/variables';
    .wrapper {
        background-color:$black90;
    }
    .content {
        max-width: 1100px;
        display: block;
        margin: 0 auto;
        padding-left: 20px;
        padding-right: 20px;
        padding-top:100px;
        padding-bottom:100px;
        @media #{$xs-only, $sm-only} {
            padding-top:20px;
        }
        .two-columns {
            display:flex;
            width:100%;
            flex-wrap:wrap;
            .column {
                width:50%;
                @media #{$xs-only, $sm-only} {
                    width:100%;
                }
                h1 {
                    font-size:50px;
                    font-weight:bold;
                    color:$yellow;
                    max-width:350px;
                    margin-bottom:20px;
                    animation-name: fadeIn;
                    animation-duration: 1s;
                    @media #{$sm-only} {
                        font-size:29px;
                    }
                    @media #{$md-only} {
                        font-size:45px;
                    }
                    @media #{$xs-only, $sm-only} {
                        max-width:300px;
                        font-size:35px;
                        max-width:none;
                    }
                }
                h2 {
                    margin-bottom:10px;
                }
                .job-details {
                    display:flex;
                    width:100%;
                    .icon {
                        width:20%;
                        animation-name: fadeIn;
                        animation-duration: 1s;
                        @media #{$xs-only, $sm-only} {
                            width:12%;
                        }
                    }
                    .details {
                        width:80%;
                        @media #{$xs-only, $sm-only, $md-only} {
                            margin-left:10px;
                        }
                    }
                }
            }
            .column1 {
                width:40%;
                max-width:400px;
                margin-right:90px;
                @media #{$sm-only} {
                    margin-right:20px;
                }
                @media #{$md-only} {
                    margin-right:55px;
                }
                @media #{$xs-only, $sm-only} {
                    width:100%;
                    max-width:none;
                    margin-right:0;
                }
            }
            .column2 p {
                margin-bottom:10px;
            }
            .column2 img {
                @media #{$sm-only, $xs-only} {
                    max-width:50px;
                    width:100%;
                }
            }
            .subcategory {
                margin-top:25px;
            }
        }
    }

    .desktop {
        display:none;
        @media #{$md-up} {
            display:inline-block;
        }
    }
    h2 {
        color:$yellow;
        font-size:20px;
        font-weight:bold;
        margin-bottom:15px;
        animation-name: fadeIn;
        animation-duration: 1s;
    }
    p {
        color:white;
        font-size:16px;
        line-height:21px;
        animation-name: fadeIn;
        animation-duration: 1s;
        margin: 10px 0;
    }
    .yellow-button {
        margin-top:30px;
        margin-left:0;
        animation-name: fadeIn;
        animation-duration: 1s;
    }
    .divider {
        height:1px;
        background-color:$yellow;
        width:90%;
        margin-top:25px;
        margin-bottom:25px;
    }
    .apply-mobile {
        display:none;
        @media #{$xs-only, $sm-only} {
            display:contents;
        }
    }
    .mobile {
        display:none;
        @media #{$xs-only, $sm-only} {
            display:table;
        }
    }
}