@rm-comp 'eVAzhnJ_SB' {

    @import 'sass/variables';

    .template {
        background-color: $black90;
        .project-imgs {
            overflow: hidden;
            .portfolio-project {
                width: 100%;
                .devices {
                    position: relative;
                    text-align: center;
                    width: 100%;
                    display: inline-block;
                    margin-left: 260px;
                    margin-top: -30px;
                    @media #{$md-only, $sm-only, $xs-only} {
                        margin-left: 0;
                        width: 100%;
                        display: block;
                        margin: 0 auto;
                    }
                    @media #{$lg-only} {
                        width: 82%;
                        left: 5%;
                        margin-top: 0;
                    }
                    @media #{$xs-only} {
                        width: 165%;
                        max-width: 545px;
                        left: 50%;
                        -ms-transform: translateX(-50%);
                        transform: translateX(-50%);
                    }
                    @media #{$sm-only} {
                        max-width: 630px;
                        margin-top: -10px;
                    }
                    @media #{$md-only} {
                        max-width: 680px;
                        left: 9%;
                    }
                    .device {
                        display: inline-block;
                        width: 50%;
                        .tablet {
                            position: relative;
                            display: inline-block;
                            width: 110%;
                            z-index: 8000;
                            bottom: 20px;
                            @media #{$sm-only, $xs-only} {
                                left: 31%
                            }
                        }
                        .tablet-img {
                            position: absolute;
                            top: 24%;
                            width: 40%;
                            left: 7%;
                            @media #{$sm-only, $xs-only} {
                                left: 22.5%
                            }
                        }
                        .phone {
                            position: relative;
                            display: inline-block;
                            width: 50%;
                            margin-top: 1%;
                            right: 20%;
                            z-index: 10000;
                            @media #{$sm-only, $xs-only} {
                                right: 12%;
                            }
                        }
                        .phone-img {
                            position: absolute;
                            width: 19.7%;
                            left: 55.2%;
                            top: 14.6%;
                            z-index: 9000;
                            @media #{$sm-only, $xs-only} {
                                left: 59.2%;
                            }
                        }
                    }
                }
            }
        }
    }

    .left {
        display: none;
        @media #{$lg-up} {
            display: block;
        }
    }

    .center {
        display: block;
        @media #{$lg-up} {
            display: none;
        }
    }

}