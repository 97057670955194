@rm-comp 'JqxMZYmsy-' {

    @import 'sass/variables';
    .banner {
        max-height:none;
    }
    .banner-video {
        .text {
            color: white;
            text-align: center;
            .title {
                padding-top: 60px;
                @media #{$xs-only} {
                    padding-top: 45px;
                }
                @media #{$md-only} {
                    padding-top: 30px;
                }
            }
            .copy {
                /*color: white;*/
                margin-bottom: 30px;
                margin-top: 20px;
                @media #{$xs-only} {
                    margin-bottom: 20px;
                }
            }
        }

        .device {
            text-align: center;
            padding-bottom: 35px;
            img {
                width: 100%;
            }
        }
        .buttons {
            padding-bottom: 50px;
            text-align: center;
            @media #{$sm-only, $xs-only} {
                padding-bottom: 40px;
            }
            @media #{$md-only} {
                padding-bottom: 30px;
            }
            .yellow-button {
                margin: 10px;
                @media #{$xs-only} {
                    margin: 3px;
                }
            }
            .yellow-button .selected {
                border: 1px solid $yellow;
                transition: 0.5s ease;
                margin: 10px;
                .chevron {
                    color: $black;
                }
                &:hover {
                    background-color: $black;
                    border: 1px solid white;
                    border-style: inset;
                    color: $white;
                    transition: background-color 0.5s ease;
                }
            }
            .black-button {
                background-color: $black90;
                color: white;
                border: 1px solid white;
                .chevron {
                    color: $white;
                }
            }
            .selected {
                background-color: $yellow;
                color: $black;
                .chevron {
                    color: $black;
                }
            }
        }
        &.yellow-banner {
            .black-button {
                background-color: $yellow;
                color: black;
                border: 1px solid black;
                .chevron {
                    color: black;
                }
            }
            .black-button:hover {
                background-color: $black;
                color: $yellow;
                border: 1px solid $black;
                .chevron {
                    color: $yellow;
                }
            }
        }
    }

    .gallery {
        display: flex;
        width: 100%;
        max-height: 250px;
        .column {
            .small {
                max-height: 125px;
            }
            .large {
                max-height: 250px;
            }
        }
    }

    .img-gallery {
        display: flex;
        margin-bottom: 45px;
        @media #{$xs-only, $sm-only} {
            flex-wrap: wrap;
        }
        .article {
            padding-top: 56.25%;
        }
        .img3 {
            min-width: 200px;
            @media #{$xs-only, $sm-only} {
                min-width: 0;
            }
            @media #{$md-only} {
                min-width: 222px;
            }

        }
        .column {
            width: 16.66vw;
            @media #{$xs-only, $sm-only} {
                width: 33.33%;
            }
            @media #{$md-only} {
                width: 33.33%;
            }
            @media #{$lg-only, $xl-only} {
                flex: 1;
            }
        }
        .column2 {
            @media #{$md-only} {
                display: none;
            }
        }
        .column1 {
            @media #{$xs-only, $sm-only} {
                width: 33.3%;
            }
            @media #{$lg-only, $xl-only} {
                flex: 1;
            }
        }
        .column2 {
            width: 33.332vw;
            @media #{$xs-only, $sm-only} {
                width: 66.66%;
            }
            @media only screen and (min-width : 992px) and (max-width : 1023px) {
                flex: 2;
            }
            @media only screen and (min-width : 1025px) and (max-width : 1224px) {
                flex: 2;
            }
            @media only screen and (min-width : 1226px) {
                flex: 2;
            }

        }
    }

    .photo-tile {
        overflow: hidden;
        position: relative;
        >* {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            transition: all 1s ease;
            &:hover {
                transform: scale(1.5) rotate(0.1deg);
            }
        }
    }


    .learn-more {
        background-color: $black90;
        padding-top: 60px;
        padding-bottom: 60px;
        padding-left: 20px;
        padding-right: 20px;
        .learn-more-content {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            max-width: 1100px;
            margin: 0 auto;
            justify-content: center;
            .item {
                width: 24%;
                text-align: center;
                color: white;
                padding: 10px;
                @media #{$sm-only, $xs-only} {
                    width: 100%;
                    text-align: left;
                    display: flex;
                    align-items: center;
                }
                img {
                    width: 70px;
                    margin-bottom: 5px;
                    flex-shrink: 0;
                    -webkit-animation: pop-in 0.5s 0.2s both;
                    -moz-animation: pop-in 0.5s 0.2s both;
                    -ms-animation: pop-in 0.5s 0.2s both;
                    animation-delay: 0.7s;
                    @media #{$sm-only, $xs-only} {
                        margin-bottom: 0;
                        margin-right: 20px;
                    }
                }
                p {
                    -webkit-animation: fadeIn 1s 0.2s both;
                    -moz-animation: fadeIn 1s 0.2s both;
                    -ms-animation: fadeIn 1s 0.2s both;
                    animation-delay: 0.8s;
                    width: 95%;
                    margin: 0 auto;
                    &.even {
                        width: 80%;
                    }
                }
            }
            .item-line {
                @media #{$sm-only, $xs-only} {
                    width: 100%;
                    height: 1px;
                    background-color: $white;
                    margin-top: 20px;
                    margin-bottom: 20px;
                    animation: grow-width2 0.5s 0.2s both;
                    animation-delay: 0.6s;
                }
            }
            .item3 {
                @media #{$sm-only, $xs-only} {
                    padding-top: 20px;
                }

            }
            .line {
                max-width: 1px;
                width: 1%;
                height: 150px;
                background-color: $white;
                animation: grow-height 0.5s 0.2s both;
                animation-delay: 0.6s;
                @media #{$sm-only, $xs-only} {
                    display: none;
                }
            }
        }

        &.yellow {
            background-color: $yellow;
            .learn-more-content {
                .line {
                    background-color: $black;
                }
                p {
                    color: $black;
                }

            }
        }

    }

}