@import 'sass/variables';

#rm-slider {
    display: block;
    width:350px;
    margin:0 auto;
    height:60px;
    border-radius: 30px;
    background: white;
    padding-left: 30px;
    padding-right: 30px;
    position:relative;
    @media #{$xs-only} {
        width:200px;
        height:40px;
        padding-left: 20px;
        padding-right: 20px;
    }
    &:after {
        content:"";
        display:block;
        position:absolute;
        background: #808080;
        top:0;
        bottom:0;
        right:0;
        width:31px;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        @media #{$xs-only} {
            width:21px;
        }
    }
}
.rm-slider-track {
    display: block;
    position: relative;
    height: 60px;
    @media #{$xs-only} {
        height:40px;
    }
}
.rm-slider-track-before {
    position: absolute;
    top:0;
    left:0;
    height:60px;
    @media #{$xs-only} {
        height:40px;
    }
}
.rm-slider-track >.dot-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    & .dot {
        margin-top: -4px;
        width: 10px;
        height: 10px;
        background: $yellow;
        border: 1px solid $yellow;
        border-radius: 20px;
        transition: 0.5s ease;
        z-index: 1;
    }
    & .dot:hover {
        cursor: pointer;
        background-color: $white;
        border: none;
    }
} 
.rm-slider.rm-slider-vertical .rm-slider-track-before {
    height: auto;
    width: 2px;
}
.rm-slider-track-after {
    position: absolute;
    top:0;
    right:0;
    height:60px;
    //border-radius:30px;
    background: #808080;
    @media #{$xs-only} {
        height:40px;
    }
}
.rm-slider.rm-slider-vertical .rm-slider-track-after {
    height: auto;
    width: 2px;
    top:auto;
    bottom:0;
}
.rm-slider-handle {
    position: absolute;
    top: 0;
    width: 60px;
    height: 60px;
    border-radius: 50px;
    background-color: white;
    z-index: 2;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.45);
    transform:translate(-50%, 0);
    @media #{$xs-only} {
        height:40px;
        width:40px;
    }
}

.rm-slider-handle:hover {
    cursor: pointer;
}

.rm-slider-handle:focus {
    outline:0;
    border:1px solid white;
}

.rm-slider-handle::after {
    content: attr(slider-value);
    margin-top: 37px;
    display: block;
    color: $white;
    
    @media #{$xs-only, $sm-only} {
        margin-top: 5px;
        margin-left: 50px;
    }
}

.rm-slider.rm-slider-vertical .rm-slider-handle {
    // transform: translate(-7px,-8px);
}


@media #{$xs-only, $sm-only} {
    .slider-wrapper {
        display:flex;
        max-width: 90px;
        justify-content: center;
        margin: 0 auto;
        .rm-slider {
            -ms-transform: rotate(90deg);
            margin: 0 auto;
            position: relative;
            display: inline-flex;
            padding: 10px 10px;
            -ms-flex-direction: column;
            flex-direction: column;
            align-items: center;
            width: 100%;
            margin: 0 auto;
            left: 50%;
            transform: translate(-50%, 0%);
        }
        .rm-slider-track {
            display: block;
            /*position: absolute;
            height: 2px;*/
            background: #FFC425;
            height: 400px;
            width: 2px;
            -ms-flex-positive: 1;
            flex-grow: 1;
            margin: 0 auto;
            .dot-container {
                display: flex;
                width: 100%;
                -ms-flex-pack: justify;
                justify-content: space-between;
                flex-direction: column;
                height: 100%;
                .dot {
                    margin-left:-4px;
                }
            }
        }
        #slider-values {
            justify-content: space-between;
            -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
            height: 424px;
            padding-left: 0;
            position: relative;
            left: 35px;
            top: -9px;
        }
    }
}