@rm-comp 'ciK1voKc5ib' {

    @import 'sass/variables';
    @import 'sass/survey-common';

    h1 {
        color: #1a1a1a;
        font-size: 50px;
        font-weight: normal;
        padding-bottom: 20px;
        width:100%;
        @media #{$xs-only, $sm-only} {
            font-size: 40px;
        }
    }

    h2 {
        width: 80%;
        color: #1a1a1a;
        font-size: 25px;
        font-weight: 400;
        padding-bottom: 40px;
        padding-left: 10px;
        padding-right: 10px;

        @media #{$xs-only, $sm-only} {
            font-size: 17px;
        }
    }

    h3 {
        font-size: 23px;
        font-weight: 600;
        vertical-align: super;
        display: inline;

        @media #{$xs-only, $sm-only} {
            font-size: 18px;
        }
    }

    .mobile h2 {
        padding-bottom: 15px;
    }

    .mobile p {
        padding-bottom: 20px;
        font-size: 14px;
    }

    .text-container-products {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        text-align: center;
        padding: 40px 20px 0 20px;
        position:inherit;
        max-width:1400px;
        margin:0 auto;
        @media #{$sm-only, $xs-only, $md-only} {
            padding: 40px 10px 0 10px;
        }

    }

    .validation {
        margin-top: 10px;

        .exclamation {
            width: 20px;
            height: 20px;
        }

        p {
            display: inline;
            margin-left: 10px;
            font-size: 14px;
            vertical-align: text-top;
        }
    }

    .products-page {
        display: flex;
        justify-content: center;
        width: 100%;
        @media #{$md-up} {
            .products-column {
                margin-left: 20px;
                margin-right: 20px;
            }
        }

        .products-row {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            max-width: 1200px;

            @media #{$md-up} {
                margin-left: 36px;
            }
            @media #{$lg-only} {
                margin-bottom: 70px;
            }
            @media #{$xs-only, $sm-only} {
                flex-direction: column;
                align-items: center;
                margin-bottom: 20px;
            }
            @media #{$lg-up} {
                @media (min-height: 700px) and (max-height: 820px) {
                    margin-bottom: 120px;
                }
            }    
        }


        .products-column {
            width: 306px;
            margin-bottom: 40px;

            @media #{$xs-only, $sm-only} {
                width: 250px;
                margin-bottom: 15px;
                margin-left: 25px;
            }

            .products-icon {
                width: 40px;
                height: 40px;
                margin-right: 15px;
            }

            .title {
                margin-bottom: 20px;
                text-align: left;
            }

            .item {
                margin-bottom: 12px;
                text-align: left;
                margin-left: 10px;
            }

            input[type="checkbox"] {
                width: 22px;
                height: 22px;
                border: 1px solid #1a1a1a;
                margin-right: 15px;
                -webkit-appearance: none;
                transition: 0.2s ease;
                background-position: center;
                background-size: 0%;
                float: left;
            }
            
            input[type="checkbox"]::-ms-check {
                color: transparent;
                background-color: transparent;
                border:1px solid #1a1a1a;
            }

            input[type="checkbox"]:checked {
                background-color: #1a1a1a;
                background-repeat: no-repeat;
                background-position: center;
                background-image: url(./images/survey/checkmark-white.png);
                background-size: 75%;
            }

            input[type="checkbox"]:hover,
            input[type="checkbox"]:focus {
                cursor: pointer;
                background-color: #1a1a1a;
            }

            input[type="checkbox"]:focus {
                outline: none;
                border:1px solid white;
            }

            .text input[type="text"] {
                width: 185px;
                height: 40px;
                padding: 0 10px;
                border-bottom: 1px solid #1a1a1a;
                background-color: rgba(255, 255, 255, 0);
                margin-top: -20px;
            }

            .text input[type="text"]::placeholder {
                color: #1a1a1a;
            }


            label {
                font-size: 20px;
                vertical-align: top;
                display: block;
                margin-left: 35px;
            }
        }
    }

    .back-button-container, .next-button-container {
        bottom:70px;
        @media #{$xs-only} {
            bottom:20px;
        }
    }
    
    .action-btn-wrapper {
        @media #{$lg-up} {
            @media screen and (max-height: 699px) {
                padding-top:100px;
            }
        }    
        @media (min-height: 821px) and (max-height: 900px) {
            padding-top:100px;
        }
        @media (min-height: 700px) and (max-height: 820px) {
            margin-top: 0px;
        }
    }
    
}