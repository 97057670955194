@rm-comp 'HoxF_e9Rlwg' {

    @import "sass/variables";
    @import "sass/survey-common";

    /* Before/after default styles */
    #before-after {
        display: block;
        position: relative;
        height: 550px;
        width: 100%;
        max-width: 1280px;
        margin: 0 auto;
        top: 50%;
        /*        margin-top: 50px;*/
        @media #{$xs-only} {
            margin-top: 0px;
        }
        @media #{$md-only} {
            margin-top: 20px;
        }
        @media #{$xs-only, $sm-only, $md-only} {
            padding-bottom: 56.5%;
            height: auto;
        }
    }

    .rm-ba-left,
    .rm-ba-right {
        position: absolute;
        overflow: hidden;
        width: 100%;
        height: 100%;
        @media #{$xs-only} {
            width: 120%;
            height: 120%;
            left: -10%;
        }
    }

    .rm-ba-left *,
    .rm-ba-right * {
        display: block;
        position: absolute;
        max-height: 100%;
        max-width: 100%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        @media #{$lg-up} {
            top: 42%;
        }
    }

    .rm-ba-right * {
        z-index: 1;
    }

    .rm-ba-left * {
        z-index: 2;
    }

    .number {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
    }

    .slider-outer {
        z-index: 4;
        position: inherit;
        @media #{$lg-up} {
            position: absolute;
        }
        @media (max-height: 400px) {
            margin-top: -80px;
        }
    }

    .main-container {
        overflow: hidden;
    }

    @keyframes sliderAnim {
        from {
            left: 0;
        }

        75% {
            left: 100%;
        }

        to {
            left: 0;
        }
    }
}