@rm-comp 'GSFYP3aC9P' {

    @import 'sass/variables';

    .logo-bg {
        background-color: $black90;
    }
    
    .logo-carousel {
        background-color: $black90;
        animation-name: fadeIn2;
        animation-duration: 1s;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
    }

    .logo-card {
        background-color: $black90;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 40px 20px;
        @media #{$xs-only, $sm-only} {
            padding: 30px 10px;
        }

    }

    .img-wrapper {
        padding: 0 20px;
        max-width: 260px;
        @media #{$xs-only, $sm-only} {
            padding: 0 5px;
        }
    }

    .client-logo {
        width: 100%;
        display: block;
    }



}