@rm-comp 'cqlAXzbPYkJ' {

    @import "sass/variables";
    @import "sass/survey-common";

    h1 {
        color: $black90;
        font-size: 50px;
        font-weight: normal;
        padding-bottom: 20px;

        @media #{$xs-only, $sm-only} {
            font-size: 36px;
        }
    }

    h2 {
        color: $black90;
        font-size: 25px;
        font-weight: 400;
        padding-bottom: 50px;

        @media #{$xs-only, $sm-only} {
            font-size: 17px;
            margin-top: 25px;
        }
    }

    .main-container {
        background-color: $yellow;
        overflow: hidden;
        min-height: auto;
    }

    .text-container {
        padding: 20px 20px;
        transform: none;
        position: relative;
        top: auto;
        left: auto;
        transform: none;
        display: block;
        margin: 0 auto;
        @media #{$lg-up} {
            margin-bottom: 105px;
        }
    }

    label {
        color: $black90;
        font-size: 16px;
        font-weight: 600;

        @media #{$xs-only, $sm-only} {
            font-size: 14px;
        }
    }

    .desktop {
        @media #{$xs-only, $sm-only} {
            display: none;
        }
    }

    .mobile {
        @media #{$md-up} {
            display: none;
        }
    }

    .mobile h2 {
        padding-bottom: 15px;
    }

    .mobile p {
        font-size: 14px;
    }

    .form-row {
        display: flex;
        justify-content: center;
        padding-bottom: 20px;
        margin-left: 10px;

        @media #{$xs-only, $sm-only} {
            flex-direction: column;
            align-items: center;
        }
    }

    .form-row .first-row {
        margin-left: -50px;
        margin-right: -7.5px;
        padding-right: 0px;

        @media #{$xs-only, $sm-only} {
            margin-left: -45px;
            margin-right: 0px;
        }
    }

    .no-label-lastname {
        margin-left: 55px;
        margin-right: -7.5px;

        @media #{$xs-only, $sm-only} {
            margin-top: 10px;
            margin-left: 0px;
            margin-right: 0px;
            margin-bottom: 10px;
        }
    }

    #ccInput {
        @media #{$xs-only, $sm-only} {
            margin-top: 10px;
            margin-left: -40px;
            margin-bottom: 10px;
        }
    }

    .text label {
        display: inline;
        padding-right: 20px;
    }

    .text input {
        height: 50px;
        border-radius: 10px;
        width: 270px;
        padding-left: 20px;
        padding-right: 20px;

        @media #{$xs-only, $sm-only} {
            width: auto;
            font-size: 14px;
        }
        &:focus {
            border: 1px solid $black90;
            outline: none;
        }
    }
    .text .cc-mail {
        &:focus {
            border: 1px solid white;
            outline: none;
        }
    }

    .mail-text label {
        padding-top: 0px;
    }

    .mail-text textarea {
        height: 375px;
        max-width: 691px;
        width: 100%;
        border: 1px solid white;
        border-radius: 10px;
        padding: 15px;
        transition: 0.2s ease-in-out;

        @media #{$xs-only, $sm-only} {
            font-size: 14px;
        }

        @media #{$xs-only} {
            width: 100%;
            transition: 0.2s ease-in-out;
        }
        &:focus {
            border: 1px solid $black90;
            outline: none;
        }
    }

    .mail-text {
        max-width: 610px;
        padding-left: 15px;
        margin: 0 auto;
        @media #{$xs-only, $sm-only} {
            padding-top: 20px;
            padding-bottom: 20px;
            padding-right: 20px;
        }
        @media #{$xs-only} {
            display: flex;
            justify-content: flex-end;
            padding-right: 20px;
        }
    }

    .cc-mail {
        background-color: rgba(0, 0, 0, 0);
        border: 1px solid #1a1a1a;
    }

    .validation {
        margin-top: 10px;
        .exclamation {
            width: 20px;
            height: 20px;
        }
        p {
            display: inline;
            margin-left: 10px;
            font-size: 14px;
            vertical-align: text-top;
        }
    }

    a.btn {
        padding-top: 10px;
        cursor: pointer;
    }
    .btn {
        background-color: $black90;
        color: $white;
        font-size: 16px;
        font-family: "Open Sans", sans-serif;
        width: 160px;
        height: 40px;
        border-radius: 10px;
        transition: 0.5s ease;
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
        display: block;
        &:focus {
            outline: none;
            border: 1px solid white;
            border-radius: 10px;
        }
    }

    @media (-ms-high-contrast: none), (pointer: fine) {
        .btn:hover {
            background-color: $white;
            color: $black90;
            border: none;
        }
    }
    @media (-ms-high-contrast: none), (pointer: coarse) {
        .btn:hover {
            background-color: $white;
            color: $black90;
            border: none;
        }
    }

    .back-button {
        background-color: white;
        color: #1a1a1a;
        &:hover {
            background-color: #1a1a1a;
            color: white;
            border: 1px solid white;
        }
    }

    .next-button {
        background-color: $yellow;
        border: 1px solid #1a1a1a;
        color: #1a1a1a;
        &:hover {
            background-color: white;
            border: 1px solid white;
        }
    }

    .skip {
        background-color: rgba(0, 0, 0, 0);
        border: 1px solid $black90;
        color: $black90;
        margin-bottom: 20px;
        &:focus {
            outline: none;
            border: 1px solid $black90;
            background-color: white;
        }
    }

    #sendBtn {
        &:focus {
            border: 1px solid $black90;
        }
    }
}