@rm-comp 'dmq0ZcQQFN' {

    @import 'sass/variables';

    .template {
        background-color: $black90;
        .content {
            @media #{$sm-only} {
                max-width: 428px;
            }
            .project {
                width: 612px;
                @media #{$xs-only} {
                    width: 100%;
                }
                @media #{$sm-only} {
                    max-width: 383px;
                }
                .project-description {
                    @media #{$lg-up} {
                        max-width: 460px;
                    }
                }
            }
        }
        .side-imgs {
            z-index: 1000;
            margin-top: 3%;
            .side {
                position: absolute;
                width: 70%;
                right: 235px;
                top: 93px;
                @media #{$lg-only} {
                    width: 70%;
                }
            }
            .side1 {
                z-index: 1000;
            }
            @media #{$sm-only, $xs-only, $md-only} {
                display: none;
            }
            img {
                width: 90%;
                @media #{$lg-only} {
                    width: 80%;
                }
            }
        }
        .project-imgs {
            overflow: hidden;
            .devices {

                width: 100%;
                @media #{$md-only} {
                    align-items: center;
                }
                @media #{$xl-only} {
                    margin-top: 15px;
                    margin-left: 470px;
                }
                @media #{$lg-only} {
                    margin-top: 42px;
                    margin-left: 440px;
                }
                .imac {
                    width: 63%;
                    position: relative;
                    margin-bottom: 18px;
                    @media #{$md-only} {
                        width: 536px;
                        margin-left: 0;
                        display: block;
                        margin: 0 auto;
                        margin-bottom: 20px;
                    }
                    @media #{$sm-only} {
                        width: 350px;
                        margin-left: 0;
                        display: block;
                        margin: 0 auto;
                        margin-bottom: 20px;
                        margin-top: 20px;
                    }
                    @media #{$xs-only} {
                        width: 300px;
                        margin-left: 0;
                        display: block;
                        margin: 0 auto;
                        margin-bottom: 20px;
                        margin-top: 20px;
                    }

                    .desktop-img {
                        display: none;
                        border: 1px solid $dark-gray;
                        @media #{$md-up} {
                            display: block;
                            width: 100%;
                        }
                        @media #{$lg-up} {
                            width: 80.5%;
                        }

                        @media #{$xl-up} {
                            width: 88%;
                        }

                    }
                    .mobile-img {
                        display: block;
                        border: 1px solid $dark-gray;
                        width: 100%;
                        height: 100%;
                        @media #{$md-up} {
                            display: none;
                        }

                    }
                }
                .gif-box {
                    width: 55.5%;
                    display: flex;
                    justify-content: space-between;
                    @media #{$lg-only} {
                        width: 50.5%;
                    }
                    @media #{$md-only} {
                        width: 536px;
                        left: 18.5%;
                        margin-left: 0;
                        display: block;
                        margin: 0 auto;
                    }
                    @media #{$sm-only} {
                        width: 350px;
                        margin-left: 0;
                        display: block;
                        margin: 0 auto;
                    }
                    @media #{$xs-only} {
                        width: 300px;
                        margin-left: 0;
                        display: block;
                        margin: 0 auto;
                    }
                    .gif {
                        width: 31%;
                        height: 31%;
                        border: 1px solid $dark-gray;
                        @media #{$xs-only} {
                            width: 49%;
                        }
                        &.long {
                            width: 100%;
                            height: 100%;
                        }
                    }

                }
            }
        }

        .yellow-button {
            @media #{$md-only, $sm-only, $xs-only} {
                max-width: 150px;
                display: block;
                margin: 0 auto;
            }
        }


        .dv {
            display: none;
            @media #{$lg-up} {
                display: block;
            }
        }

        .mv {
            display: block;
            @media #{$lg-up} {
                display: none;
            }
        }
    }

    .left {
        display: none;
        @media #{$lg-up} {
            display: block;
        }
    }

    .center {
        display: block;
        @media #{$lg-up} {
            display: none;
        }
    }

}