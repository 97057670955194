//*************************************************************//
// NOTE: You should never have to change anything in this file //
//*************************************************************//

*, *:before, *:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

/* Fix IE and iOS text resizing on orientation change */
html {
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    scroll-behavior: smooth;
}

html, body {
    margin:0;
    padding: 0;
}

body {
    font-size: 1rem;
}

button {
    appearance: none;
    font-size: inherit;
    cursor: pointer;
    border:none;
    background:transparent;

    &.link {
        border: 0;
        background: transparent;
        text-align: left;
    }
}

input, select, textarea {
    font-size: inherit;
    font-family: inherit;
    color: inherit;
    border: none;
}
a.btn:link {
    text-decoration: none;
    text-align: center;
    display: inline-block;

    &:hover {
        text-decoration: none;
    }
}

[hidden] {
    display: none !important;
}

/* turn off buttons in input[type=number] */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* removes "x" button from IE10/11 input fields */
input::-ms-clear {
    display: none;
}


$display-types: none inline inline-block block table table-cell table-row flex flex-column inline-flex inline-flex-column;
$justify-types: flex-start flex-end center space-between space-around space-evenly;
$align-types: flex-start flex-end center baseline stretch self-start-self-end;

@function list-contains($list, $item) { @return index($list, $item) != null; }

$widths: (0: ('0'), auto: ('auto'));
@each $d in (1 2 3 4 5 6 8 9 10 12 15 16 20) {
    @for $i from 1 through $d {
        $v: $i / $d;
        $wv: map-get($widths, $v);
        @if ($wv == null) {
            $widths: map-merge($widths, ($v: ('#{$i}-#{$d}')));
        } @else {
            $widths: map-merge($widths, ($v: append($wv, '#{$i}-#{$d}')));
        }
    }
}

@mixin breakpoint($breakpoint) {
    @include applyMedia($breakpoint) {
        @content;
    }
}

@mixin applyMedia($breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    @if ($breakpoint-value > 0) {
        @media (min-width: $breakpoint-value) {
            @content;
        }
    } @else {
        @content;
    }
}

@mixin setGutter($gutter) {
    margin-left: -$gutter;
    margin-right: -$gutter;

    > * {
        padding-left: $gutter;
        padding-right: $gutter;
    }
}

@mixin buildWidth($bpText) {
    @each $v, $selList in $widths {
        $sel: ();
        @each $s in $selList {
            $sel: append($sel, unquote('.w#{$bpText}-#{$s}'), comma);
        }
        #{$sel} {
            @if (type-of($v) == 'number') { width: percentage($v); }
            @else { width: $v }
        }
    }
}

%col-container {
    display:flex;
    flex-wrap: wrap;
    align-items: stretch;
}

.#{$grid-prefix}container {
    width:100%;
    padding-left: $gutter;
    padding-right: $gutter;
    margin-left:auto;
    margin-right:auto;

    &.no-gutter {
        padding-left: 0;
        padding-right: 0;
    }

    &:not(.full) {
        max-width: $container-max;
    }
}

.#{$grid-prefix}row {
    @extend %col-container;
    @include setGutter($gutter);

    &.no-gutter {
        @include setGutter(0);
    }
}

.#{$grid-prefix}form-row {
    @extend %col-container;
    @include setGutter($gutter-forms);
    align-items: baseline;

    &.no-gutter {
        @include setGutter(0);
    }
}

.#{$grid-prefix}inline-buttons {
    @extend %col-container;
    margin: -5px;

    > * {
        flex: 0 0 auto;
        margin: 5px;
    }
}

.menubar {
    border: 1px solid transparent;
}

.#{$grid-prefix}form-item {
    @extend %col-container;
    margin: 1rem 0;
    align-items: baseline;

    > * {
        flex: 0 0 auto;
        width:100%;
    }
}

.gutter {
    padding-left: $gutter;
    padding-right: $gutter;
}
.form-gutter {
    padding-left: $gutter-forms;
    padding-right: $gutter-forms;
}

@each $breakpoint, $bp-value in $breakpoints {
    @include applyMedia($breakpoint) {
        $breakpoint-value: map-get($breakpoints, $breakpoint);
        $bp-sel: if($breakpoint-value == 0, "", '-#{$breakpoint}');

        //.#{$pre + $breakpoint + $post} {
        .col#{$bp-sel} {
            flex: 1 0 0;
        }

        @for $i from 1 through $grid-columns {
            .col#{$bp-sel}-#{$i} {
                $w: 100% * $i / $grid-columns;
                flex: 0 0 auto;
                width: $w;
                max-width: $w
            }
        }

        @include buildWidth($bp-sel);

        @each $display-type in $display-types {
            .d#{$bp-sel}-#{$display-type} {
                @if ($display-type == 'flex-column') {
                    display: flex !important;
                    flex-direction: column;
                } @else if ($display-type == 'inline-flex-column') {
                    display: inline-flex !important;
                    flex-direction: column;
                } @else {
                    display: $display-type !important;
                }
            }
        }

        @each $justify-type in $justify-types {
            .j#{$bp-sel}-#{$justify-type} {
                justify-content: $justify-type;
            }
        }
        @each $align-type in $align-types {
            .a#{$bp-sel}-#{$align-type} {
                align-items: $align-type;
            }
            .as#{$bp-sel}-#{$align-type} {
                align-self: $align-type;
            }
        }
    }
}

.modal {
    display:none;
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;

    &.open {
        display: block;
    }

    > .modal-bg {
        position: absolute;
        top:0;
        right: 0;
        bottom:0;
        left: 0;
    }

    > .modal-content-outer {
        position: absolute;
        top:0;
        right: 0;
        bottom:0;
        left: 0;
        overflow-x: hidden;
        overflow-y: scroll;
        pointer-events: auto;

        > .modal-content-middle {
            display:table;
            width:100%;
            height:100%;

            > .modal-content-inner {
                display:table-cell;
                vertical-align: middle;
                width:100%;

                > * {
                    pointer-events: auto;
                }
            }
        }
    }
}

.accordion {
    > .accordion-header {
        cursor: pointer;
    }

    > .accordion-panel {
        display:none;
    }

    &.open {
        > .accordion-panel {
            display: block;
        }
    }
}

.menu {
    > *, .btn {
        display: block;
        width:100%;
    }

    &.w-auto {
        display: inline-flex;
        flex-direction: column;
    }
}
