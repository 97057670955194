@rm-comp '30GWi2SroJ' {

    @import 'sass/variables';

    .template {
        background-color: $black90;
        .project-imgs {
            overflow: hidden;
            .portfolio-project {
                width: 100%;
                .devices {
                    position: relative;
                    text-align: center;
                    width: 100%;
                    display: inline-block;
                    left: 21%;
                    margin-top: -30px;

                    @media #{$md-only, $sm-only, $xs-only} {
                        margin-left: 0;
                        width: 100%;
                        display: block;
                        margin: 0 auto;
                        left: 0;
                    }
                    @media #{$lg-only} {
                        width: 90%;
                        left: 25%;
                        margin-top: 0;
                    }
                    @media #{$xs-only} {
                        width: 140%;
                        max-width: 545px;
                        margin: auto;
                        left: -5%;
                    }
                    @media only screen and (min-width: 420px) and (max-width: 575px) {
                        width: 110%;
                        left: 5%;
                    }
                    @media #{$md-only} {
                        width: 100%;
                        max-width: 690px;
                    }
                    @media #{$sm-only} {
                        max-width: 640px;
                    }
                    .device {
                        display: inline-block;
                        width: 50%;
                        .laptop {
                            position: relative;
                            display: inline-block;
                            top: 0;
                            width: 120%;
                            z-index: 10000;
                            @media #{$md-only} {
                                left: 20%;
                            }
                            @media #{$sm-only} {
                                left: 18%;
                            }
                            @media #{$xs-only} {
                                left: 12%;
                            }
                        }
                        .desktop-img {
                            position: absolute;
                            top: 27%;
                            width: 44%;
                            left: 8%;
                            @media #{$md-only} {
                                width: 44%;
                                left: 18%;
                                top: 28%;
                            }
                            @media #{$sm-only} {
                                left: 16%;
                            }
                            @media #{$xs-only} {
                                left: 14%;
                                width: 44%;
                            }
                        }
                        .phone {
                            position: relative;
                            display: inline-block;
                            width: 50%;
                            margin-top: 1%;
                            right: 20%;
                            z-index: 20000;
                            @media #{$md-only} {
                                right: 6%;
                            }
                            @media #{$sm-only} {
                                right: 13%;
                            }
                            @media #{$xs-only} {
                                right: 30%;
                            }
                        }
                        .phone-img {
                            position: absolute;
                            width: 19.5%;
                            left: 55.2%;
                            top: 13%;
                            z-index: 15000;
                            @media #{$md-only} {
                                width: 20%;
                                left: 61.7%;
                            }
                            @media #{$sm-only} {
                                width: 19%;
                                left: 58.9%;
                            }
                            @media #{$xs-only} {
                                left: 50.4%
                            }
                        }
                    }
                }
            }
        }
    }

    .left {
        display: none;
        @media #{$lg-up} {
            display: block;
        }
    }

    .center {
        display: block;
        @media #{$lg-up} {
            display: none;
        }
    }

}