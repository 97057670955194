@rm-comp 'qzuUa0mvqGi' {

    @import "sass/variables";
    @import "sass/survey-common";

    h1 {
        color: $yellow;
        font-size: 50px;
        font-weight: normal;
        padding-bottom: 20px;

        @media #{$xs-only, $sm-only, $md-only} {
            font-size: 36px;
        }
    }

    h2 {
        color: #fff;
        font-size: 25px;
        font-weight: 400;
        padding-bottom: 20px;

        @media #{$xs-only, $sm-only, $md-only} {
            font-size: 17px;
            padding-bottom: 0px;
        }

        &.reccomend-to-colleague {
            padding-bottom: 20px;
        }
    }

    .results-container {
        position: relative;
        padding: 40px 20px;
        height: 100%;
        h1 {
            @media #{$lg-up} {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    .rating-btns {
        display: flex;
        justify-content: center;

        .btn {
            background-color: $yellow;
            color: $black90;
            font-size: 22px;
            font-family: "Open Sans", sans-serif;
            font-weight: 600;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            transition: 0.5s ease;
            margin: 0 30px;
        }
    }

    .thumbs {
        width: 29px;
        padding-top: 7px;
    }

    @media (-ms-high-contrast: none), (pointer: fine) {
        .btn:hover {
            background-color: #fff;
            outline: none;
            color: #1a1a1a;
        }
    }
    @media (-ms-high-contrast: none), (pointer: coarse) {
        .btn:active {
            background-color: #fff;
            outline: none;
            color: #1a1a1a;
        }
    }
    .btn:focus {
        background-color: #fff;
        outline: none;
        color: #1a1a1a;
        border: 1px solid $yellow;
    }

    .btn.selected {
        background: $white;
        color: #1a1a1a;
        outline: none;
    }

    .action-btn-wrapper {
        @media #{$xs-only, $sm-only, $md-only} {
            @media screen and (max-height: 800px) {
                position: absolute;
                bottom: 30px;
                left: 50%;
                transform: translate(-50%, -50%);
                padding-bottom: 0;
            }
        }
    }

    .main-container {
        overflow-x: hidden;
        position: relative;
    }

    .hide {
        display: none;
    }
}