@rm-comp 'b8jAqXuhr6' {

    @import 'sass/variables';

    $page-header-height: 90px;
    $mobile-header-height: 67px;

    * {
        line-height: 1.2em;
        box-sizing: border-box;
        font-family: 'Open Sans', sans-serif !important;
    }

    .nav-space {
        height: $mobile-header-height;

        @media #{$md-up} {
            height: $page-header-height;
        }
    }

    .nav-wrapper{
        width: 100%;
        position: fixed;
        top: 0;
        z-index: 1002;
    }

    header {
        background-color: $yellow;
        z-index: 1003;
        height: $mobile-header-height;
        box-shadow: 0 2px 10px 0 rgba(0,0,0,0.15);

        @media #{$md-up} {
            height: $page-header-height;
        }

        .page-header {
            height: $page-header-height;
            padding:20px;
            padding-top:10px;
            max-width:1100px;
            margin:0 auto;
            
            @media #{$xs-only, $sm-only} {
                display:none;
            }
            @media #{$md-only} {
                padding-top:20px;
                padding-left:10px;
                padding-right:10px;
            }
            nav {
                max-width: 1100px;
                width: 100%;
                height: auto;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin:0 auto;
                margin-top:10px;
                .header-logo {
                    height: 38.83px;
                    max-width:200px;
                    width:100%;
                    margin-right:30px;
                    margin-top:-10px;
                    margin-left:30px; /*added for doodle space*/
                    @media #{$md-only} {
                        margin-right:0;
                        margin-left:33px; /*added for doodle space*/
                    }
                    a {
                        padding-left:0;
                    }
                }
                .nav-links {
                    flex-grow: 1;
                    display: inline-block;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                a {
                    text-transform: uppercase;
                    text-decoration: none;
                    color:$dark-gray;
                    font-size:18px;
                    letter-spacing:1px;
                    font-weight:600;
                    /*padding:20px 40px 20px 40px;*/
                    padding-bottom:5px;
                    @media #{$md-only} {
                        padding:7px 13px 7px 13px;
                        font-size:15px;
                        letter-spacing:0px;
                    }
                    @media #{$lg-up} {
                        padding:20px 25px 20px 25px;
                    }
                    @media #{$lg-only} {
                        padding:20px 20px 20px 20px;
                    }
                }

                .search-container {
                    position:relative;
                    height:100%;
                }
            }
        }

        .mobile-header {
            background-color:$yellow;
            height: $mobile-header-height;
            @media #{$md-up} {
                display:none;
            }
            .header-logo {
                text-align:center;
                width:100%;
                display:block;
                margin:0 auto;
                padding-top:12px;
                padding-bottom:12px;
                box-shadow: 0 2px 10px 0 rgba(0,0,0,0.15);
                img {
                    @media #{$xs-only} {
                        max-width:150px;
                    }
                }
            }
        }
    }

    .click-container{
        position: absolute;
        z-index: 1001;
        width: 100vw;
        height: 100vh;
    }
    
    .mobile-nav{
        @media #{$md-up} {
            display:none;
        }
        transition: transform 0.5s ease;
        transform: translateX(-200px);
        &.showNav{
            transform: translateX(0);
        }
        background: #2f2f2f;
        position: absolute;
        width: 200px;
        z-index: 1004;
        height: 100vh;

        ul{
            list-style: none;
            width: 100%;
            li{
                /* &:not(:first-child){ */
                    border-top: 1px solid #1d1d1d;
                /* } */
                a {
                    text-transform: uppercase;
                    display: inline-block;
                    text-align: center;
                    width: 100%;
                    padding: 10px;
                    text-decoration: none;
                    color: #fff;
                    /* transition: background 0.25s ease; */
                    &:hover{
                        /* background: $yellow; */
                        background-position: 20px 0;
                        color: #2f2f2f !important;
                    }
                    &.selected{
                        color: $yellow;
                    }

                    background-image: linear-gradient(to left, transparent, transparent 50%, $yellow 50%, $yellow);
                    background-size: 200% 100%;
                    background-position: 100% 0;
                    transition: background 0.25s ease;
                }
            }
        }
    }

    .morph-icon {
        $primary-color: $yellow;
        $secondary-color: $black;
        $size: 3;
        $animation-speed: 0.5s;
        $animation-timing: ease;

        position: absolute;
        width: 15px * $size;
        height: 15px * $size;
        background: $primary-color;
        cursor: pointer;
        transition: all $animation-speed $animation-timing;
        padding-left:20px;
        margin-top:10px;
        .morph-icon-line {
            position: absolute;
            width: 9px * $size;
            height: 1px * $size;
            left: 3px * $size;
            background: $secondary-color;
            transition: transform $animation-speed $animation-timing,
                        opacity $animation-speed $animation-timing,
                        top $animation-speed $animation-timing,
                        bottom $animation-speed $animation-timing;
        }
        .morph-icon-line-top {
            top: 5px * $size;
        }
        .morph-icon-line-mdl {
            top: 7px * $size;
        }
        .morph-icon-line-btm {
            top: 9px * $size;
        }
        &.morphed {
            background: $primary-color;
            .morph-icon-line {
                background: $secondary-color;
            }
            .morph-icon-line-top {
                top: 7px * $size;
                transform: rotate(135deg);
            }
            .morph-icon-line-mdl {
                opacity: 0;
                transform: rotate(-135deg);
            }
            .morph-icon-line-btm {
                top: 7px * $size;
                transform: rotate(-135deg);
            }
        }
    }
    
    .doodle {
        display:none;
/*        display:inline;*/
        z-index:10;
        position:absolute;
        margin-left:-49px;

    }
    
    .reason_container {
        position: relative;
    }
    
    .reason_arrow, .reason_arrow2, .reason_arrow3 {
        position: absolute;
        top: -20px;
        width: 300px;
        left: -300px;
            @media #{$md-up} {
            left: 0px;
        }
    }
    
    .reason_text, .reason_text2, .reason_text3 {
        font-family: 'Kalam', cursive !important;
        font-size: 20px;
        color: #FFFFFF;
        margin-top: -60px;
        width: 100%;
        text-shadow: 0px 0px 30px #000000;
        pointer-events: none;
    }
    
    .reason_text {
        margin-top: 36px;
        margin-left: -88px;
    }
    
    .reason_text2 {
        margin-left: -160px;
        margin-top: 35px;
    }
    
    .reason_text3 {
        margin-left: -80px;
        margin-top: 36px;
    }
    
     .reason_text_mobile {
        font-family: 'Kalam', cursive !important;
        font-size: 15px;
        color: #FFFFFF;
        margin-top: 48px;
        margin-left: 72px;
        width: 100%;
        transition: 0.2s ease-in-out;
        text-shadow: 0px 0px 20px #000000;
         @media #{$xs-only} {
             font-size: 12px;
             transition: 0.25s ease-in-out;
         }
    }
    
    .IE_reason_arrow {
            display: none;


        } /* IE ONLY */
        @media screen and (-ms-high-contrast: active),
        (-ms-high-contrast: none) {
            .IE_reason_arrow {
                display: block;
                position: absolute;
                left: 0px;
                top: -15px;
                transform: scale(-0.5, 0.5);
            }
        }
    
    .IE_reason_mobile {
            display: none;


        } /* IE ONLY */
        @media screen and (-ms-high-contrast: active),
        (-ms-high-contrast: none) {
            .IE_reason_mobile {
                display: block;
                position: absolute;
                left: 16px;
                top: -3px;
                transform: scale(0.25, 0.25);
                transition: 0.25s ease-in-out;
                    @media #{$sm-up} {
                    transform: scale(0.4, 0.4);
                    left: 8px;
                    top: -5px;
                    transition: 0.25s ease-in-out;
                }
            }
        }
    
    .cls-1, .cls-2, cls-3 {
        transform: scale(-0.9, 0.9);
        position: absolute;
        top: 0px;
        left: -14px;
    }
    
    .cls-mobile {
        transform: scale(0.5);
        position: absolute;
        top: 10px;
        left: 24px;
        transition: 0.25s ease-in-out;
        
        @media #{$sm-up}{
            transform: scale(0.9);
            top: 10px;
            left: 24px;
            transition: 0.25s ease-in-out;
        }
    }
    
    path {
            stroke-dasharray: 70;
            stroke-dashoffset: -70;
            animation: draw 3s forwards;
            animation-delay: 1s;
    }
                
    .reason_arrow {
        position: absolute;
        top: 20px;
        left: 180px;
            @media #{$lg-up} {
            left: 280px;
            }
    }
    
    .reason_arrow2 {
        top: 20px;
        left: 77px;
           @media #{$lg-up} {
            top: 20px;
            left: 133px;
        }
    }
    
    .reason_arrow3 {
        top: 20px;
        left: 85px;
            
        @media #{$md-up} {
            left: 277px;
        }
        
           @media #{$lg-up} {
            left: 417px;
        }
    }
    
    .arrow_flip{
        transform: scaleX(-1);
        stroke-dasharray: 50;
        stroke-dashoffset: -50;
        animation: draw 3s forwards;
        display: none;
    }
    
    .arrow_flip2{
        transform: scaleX(-1);
        margin-left: 190px;
        width: 50px;
        height: 50px;
        transition: 0.5s ease-in-out;
        
        @media #{$lg-up} {
        width: 80px;
        height: 80px;
        transition: 0.5s ease-in-out;
        }
    }
    
    .reason_mobile {
        position: absolute;
        left: -20px;
        top: 25px;
        transition: 0.25s ease-in-out;
        pointer-events: none;
            
        @media #{$sm-up} {
            top: 38px;
            left: -10px;
            transition: 0.25s ease-in-out;
        }
        
        @media #{$md-up} {
            opacity: 0;
            transition: 0.1s ease-in-out;
        }
    }
    
    .reason_arrow_mobile {
        width: 70px;
        transform: scale(0.5);
        margin-left: 6px;
        margin-top: -10px;
    }
    
    .mobile_arrow, .mobile_arrow2, .mobile_arrow3 {
        position: absolute;
        transform: scale(0.22);
        left: 84px;
    }
    
    .mobile_arrow {
        top: -24px;
    }
    
    .mobile_arrow2 {
        top: 16px;
    }
    
     .mobile_arrow3 {
        top: 56px;
    }
    
    /*
    .reason_container:hover, .reason_arrow:hover, .reason_arrow2:hover, .reason_arrow3:hover, .arrow_flip:hover, .arrow_flip2:hover, .reason_text:hover, .reason_text2:hover, .reason_text3:hover {
        opacity: 0;
        transition: 0.5s ease-in-out;
    }
    */

    .fade {
        opacity: 0;
        transition: 0.3s ease-in-out;
    }

    .appear {
        opacity: 1;
        transition: 0.3s ease-in-out;
    }
    header .page-header nav .header-logo a {
        padding:0;
    }

    
}