@rm-comp 'ewyHOeWrLPW' {

    @import 'sass/variables';
    @import 'sass/survey-common';

    h1 {
        color: #1a1a1a;
        font-size: 50px;
        font-weight: normal;
        padding-bottom: 20px;
        @media #{$xs-only, $sm-only} {
            font-size: 40px;
        }
    }

    h2 {
        color: #1a1a1a;
        font-size: 25px;
        font-weight: 400;
        padding-bottom: 40px;

        @media #{$xs-only, $sm-only} {
            font-size: 17px;
        }
    }

    .landing-container {
        min-height: calc(100vh - 409px);        
        background: radial-gradient(circle, #FCCB5E, #FFC425, #FFC425, #FFC425, #FFC425, #FFC425);
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        flex-wrap:wrap;
        
        @media #{$lg-only} {
            min-height: calc(100vh - 411px);        
        }
        
        @media #{$md-only, $sm-only} {
            height:auto; 
        }
    }

    .text-container {
        text-align: center;
        padding: 20px 20px;
        width:100%;
        @media #{$xs-only} {
            padding: 20px 10px;
        }
        @media #{$md-only} {
            padding-bottom:100px;   
        }
        .mobile {
            height:107px;
        }
    }

    .rating-btns {
        display: flex;
        justify-content: center;
        
        .rating-btns-mobile {
            display: flex;
        }

        @media #{$xs-only, $sm-only} {
            /* flex-direction: column-reverse;
            align-items: center;*/
            flex-wrap: wrap;
        }
    }

    .rating-desc {
        padding-top: 15px;
    }

    .desktop {
        @media #{$xs-only, $sm-only} {
            display: none;
        }
    }

    .mobile {
        @media #{$md-up} {
            display: none;
        }
    }

    .mobile h2 {
        padding-bottom: 15px;
    }

    .mobile p {
        padding-bottom: 20px;
        font-size: 14px;
    }

    @media (-ms-high-contrast: none), (pointer: fine) {
        .btn:hover {  
            background: $white;
            color: #1a1a1a;
            outline: none;
        }
    }

    @media (-ms-high-contrast: none), (pointer: coarse) {
        .btn:active { 
            background: white;
            color: black;
        }
    }

    .black-banner {
        background: $black90;
        h1, p {
            color: $yellow;
        }
        h2 {
            color:white;
        }
        button {
            background: radial-gradient(circle, #FCCB5E, #FFC425, #FFC425, #FFC425, #FFC425, #FFC425);
            transition: 0.25s ease;
            & p {
                color: $black90;
            }
        }
        
        .back-button {
            background: url("images/icons/back-button-yellow.svg");
            background-size:cover;
            &:focus {
                border: 1px solid $yellow;
                border-radius: 100%;
            }
        }
        .next-button {
            background: url("images/icons/back-button-yellow.svg");
            background-size:cover;
            &:focus {
                border: 1px solid $yellow;
                border-radius: 100%;
            }
            
        }       
        .btn:focus {
            background-color: $white;
            outline: none;
            border: 1px solid $yellow;
        }   
        
        @media (-ms-high-contrast: none), (pointer: fine) {
            .back-button:hover, .next-button:hover {
                background: url("images/icons/back-button-white.svg");
                outline: none;
                background-size: cover;
            }
            .back-button:hover + .back-text, .next-button:hover + .next-text {
                opacity: 1;
            }
        }
        @media (-ms-high-contrast: none), (pointer: coarse) {
            .back-button:active, .next-button:active { 
                background: url("images/icons/back-button-white.svg");
                outline: none;
                background-size: cover;
            }
        }
    
        .back-text {
            color: #fff;
        }
        .rating-desc {
            color:white;
        }
    }
    
}