@rm-comp 'BpUoUzUnlk' {

    @import 'sass/variables';

    .banner-video {
        @media #{$md-only} {
            max-height: 530px;
        }
        .text {
            color: white;
            text-align: center;
            .title {
                padding-top: 60px;
                @media #{$md-only} {
                    padding-top: 30px;
                }
            }
            h2.black-banner {
                color: #FFC425;
            }
            h2.yellow-banner {
                color: #000000;
            }
            .copy {
                /*color: white;*/
                margin-bottom: 35px;
                margin-top: 50px;
                @media #{$sm-only} {
                    margin-top: 30px;
                    margin-bottom: 0;
                }
                @media #{$xs-only} {
                    margin-bottom: 30px;
                    margin-top: 30px;
                }
                @media #{$md-only} {
                    margin-top: 30px;
                    margin-bottom: 30px;
                }
            }

        }
        .device {
            text-align: center;
            overflow: hidden;
            padding-bottom: 35px;
            @media #{$md-only, $sm-only, $xs-only} {
                padding-bottom: 0px;
            }
        }
        .buttons {
            padding-bottom: 0;
            @media #{$sm-only, $xs-only} {
                padding-bottom: 40px;
            }
            @media #{$md-only} {
                padding-bottom: 30px;
            }
            .yellow-button {
                margin: 10px;
                @media #{$xs-only} {
                    margin: 3px;
                }
            }
            .yellow-button .selected {
                border: 1px solid $yellow;
                transition: 0.5s ease;
                margin: 10px;
                .chevron {
                    color: $black;
                }
                &:hover {
                    background-color: $black;
                    border: 1px solid white;
                    border-style: inset;
                    color: $white;
                    transition: background-color 0.5s ease;
                }
            }
            .black-button {
                background-color: $black90;
                color: white;
                border: 1px solid white;
                .chevron {
                    color: $white;
                }
            }
            .selected {
                background-color: $yellow;
                color: $black;
                .chevron {
                    color: $black;
                }
            }
        }
        &.yellow-banner {
            .black-button {
                background-color: $yellow;
                color: black;
                border: 1px solid black;
                .chevron {
                    color: black;
                }
            }
            .black-button:hover {
                background-color: $black;
                color: $yellow;
                border: 1px solid $black;
                .chevron {
                    color: $yellow;
                }
            }
        }
    }
    
    .transcript {
        width: 30px;
        position: relative;
        bottom: 171px;
        left: 310px;
        @media #{$sm-only} {
            bottom: 10px;
            left: 20px;
            position: absolute;
        }
        @media #{$xs-only} {
            bottom: 10px;
            left: 20px;
            position: absolute;
        }
        @media #{$md-only} {
            bottom: 125px;
            left: 235px;
        }

        >* {
            vertical-align: top;
        }
    }

    .video-el {
        width: 100%;
        max-width: 590px;
        position: relative;
        min-height: 100px;
        @media #{$md-up} {
            bottom: 60px;
            padding-top: 40px;
        }
        @media #{$md-only} {
            max-width: 435px;
        }
        @media #{$sm-only, $xs-only} {
            bottom: 0px;
        }
        @media #{$xs-only} {
            max-width: 420px;
        }
    }

    .mobile {
        display: none;
        @media #{$xs-only, $sm-only} {
            display: block;
            padding-bottom: 40px;
        }
    }

    .desktop {
        display: none;
        @media #{$md-up} {
            display: block;
        }
    }


    .learn-more {
        background-color: $black90;
        padding-right: 20px;
        padding-left: 20px;
        padding-top: 90px;
        .learn-more-content {
            display: flex;
            justify-content: Center;
            flex-wrap: wrap;
            width: 100%;
            max-width: 1100px;
            margin: 0 auto;
            padding-bottom: 90px;
            .column {
                width: 50%;
                max-width: 450px;
                @media #{$xs-only, $sm-only} {
                    width: 100%;
                    max-width: none;
                }
                .item {
                    display: flex;
                    align-items: center;
                    color: white;
                    img {
                        width: 70px;
                        max-width: 70px;
                        margin-right: 20px;
                        flex-shrink: 0;
                        -webkit-animation: pop-in 0.5s 0.2s both;
                        -moz-animation: pop-in 0.5s 0.2s both;
                        -ms-animation: pop-in 0.5s 0.2s both;
                        animation-delay: 0.7s;
                    }
                    p {
                        -webkit-animation: fadeIn 1s 0.2s both;
                        -moz-animation: fadeIn 1s 0.2s both;
                        -ms-animation: fadeIn 1s 0.2s both;
                        animation-delay: 0.8s;
                    }
                }
                .line {
                    width: 100%;
                    height: 1px;
                    background-color: $white;
                    margin-top: 20px;
                    margin-bottom: 20px;
                    animation: grow-width2 0.5s 0.2s both;
                    animation-delay: 0.6s;
                }
                .line-mobile {
                    @media #{$xs-only, $sm-only} {
                        width: 100%;
                        height: 1px;
                        background-color: $white;
                        margin-top: 20px;
                        margin-bottom: 20px;
                        animation: grow-width2 0.5s 0.2s both;
                        animation-delay: 0.6s;
                        @media #{$md-up} {
                            opacity: 0;
                        }
                    }
                }
                .hide {
                    @media #{$xs-only, $sm-only} {
                        display: none;
                    }
                }
            }
            .column1 {
                padding-right: 60px;
                @media #{$md-only} {
                    padding-right: 20px;
                }
                @media #{$xs-only, $sm-only} {
                    padding-right: 0;
                }
            }
            .column2 {
                padding-left: 60px;
                @media #{$md-only} {
                    padding-left: 20px;
                }
                @media #{$xs-only, $sm-only} {
                    padding-left: 0;
                }
            }
        }
        &.yellow {
            background-color: $yellow;
            .learn-more-content {
                .column {
                    .line,
                    .line-mobile {
                        background-color: $black;
                    }
                    p {
                        color: $black;
                    }
                }
            }
        }
        .fDesc {
            font-size: 20px;
        }
    }

    .personalized-finance {
        color: #FFFFFF;
        
        &.black {
            color: #000000;
        }
    }
    
}