
// only put variables or mixins in this file

//*******************************************************
// Colors
//*******************************************************

$yellow:#FFC425;
$black:#000000;
$dark-gray:#333333;
$black90:#1A1A1A;
$white:#FFF;

//*******************************************************
// Screens-sizes
//*******************************************************

$xs-up: "only screen and (min-width : 320px)";
$sm-up: "only screen and (min-width : 576px)";
$md-up: "only screen and (min-width : 768px)";
$lg-up: "only screen and (min-width : 992px)";
$xl-up: "only screen and (min-width : 1200px)";

$xs-only: "only screen and (max-width : 575px)";
$sm-only: "only screen and (min-width : 576px) and (max-width : 767px)";
$md-only: "only screen and (min-width : 768px) and (max-width : 991px)";
$lg-only: "only screen and (min-width : 992px) and (max-width : 1199px)";
$xl-only: "only screen and (min-width : 1200px)";

$height: "only screen and (min-height : 1132px)";

//*******************************************************
// Grid setup
//*******************************************************

$grid-prefix: "";
$grid-columns: 12;
$gutter: 15px;
$gutter-forms: 5px;
$container-max: 1200px;
$breakpoints: (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px);

// animations
    
@keyframes slidein {
    from {
        display: none;
        margin-bottom: -100%;
    }
    to {
        display: block;
        margin-bottom: 0%;
    }
}