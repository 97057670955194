@rm-comp 'VUi9zeCapj' {

    @import 'sass/variables';
    .signedUp {
        position: absolute;
        top: 67%;
        left: 50%;
        transform: translate(-50%);
        padding: 5px;
        user-select: none;
/*        opacity: 0;*/
        animation: singupBox 1s forwards ease;
        font-size: 14px;
    }

    .submitBtn {
        margin-top: 40px;
    }

    .social-media {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: Center;
        -webkit-animation: pop-in 0.5s;
        -moz-animation: pop-in 0.5s;
        -ms-animation: pop-in 0.5s;
        img {
            max-width: 30px;
            margin: 10px;
        }
        .social {
            width: 27px;
            height: 25px;
            background-size: cover;
            margin: 10px;
            margin-right: 20px;
            margin-left: 20px;
            transition: background-image 0.5s ease-in-out;
        }
        .instagram {
            background-image: url(images/icons/SVG/instagram-y.svg);
            &:hover {
                background-image: url(images/icons/SVG/instagram-w.svg);
                transition: background-image 0.5s ease-in-out;
            }
        }
        .twitter {
            background-image: url(images/icons/SVG/twitter-y.svg);
            &:hover {
                background-image: url(images/icons/SVG/twitter-w.svg);
                transition: background-image 0.5s ease-in-out;
            }
        }
        .facebook {
            background-image: url(images/icons/SVG/facebook-y.svg);
            &:hover {
                background-image: url(images/icons/SVG/facebook-w.svg);
                transition: background-image 0.5s ease-in-out;
            }
        }
        .linkedin {
            background-image: url(images/icons/SVG/linkedin-y.svg);
            &:hover {
                background-image: url(images/icons/SVG/linkedin-w.svg);
                transition: background-image 0.5s ease-in-out;
            }
        }
    }

    .contact {
        background-color: $black90;
        width: 100%;
        .two-columns {
            display: flex;
            padding-top: 60px;
            max-width: 1100px;
            margin: 0 auto;
            padding-left: 20px;
            padding-right: 20px;
            @media #{$xs-only, $sm-only} {
                flex-wrap: wrap;
                padding-top: 30px;
            }
            width:100%;
            .column {
                width: 100%;
                padding-left: 20px;
                padding-right: 20px;
                padding-bottom: 50px;
                @media #{$md-up} {
                    width: 50%;
                }
                @media #{$xs-only, $sm-only} {
                    padding-bottom: 20px;
                }
            }
            .heading {
                color: white;
                margin-right: 60px;
                @media #{$xs-only, $sm-only} {
                    margin-right: 0px;
                }
                h1 {
                    font-size: 50px;
                    margin-bottom: 5px;
                    animation-name: fadeIn;
                    animation-duration: 1s;
                    animation-timing-function: ease-in-out;
                    animation-fill-mode: forwards;
                    @media #{$xs-only, $sm-only} {
                        font-size: 30px;
                        text-align: center;
                        margin-bottom: 20px;
                    }
                }
                p {
                    font-size: 20px;
                    animation-name: fadeIn;
                    animation-duration: 1s;
                    @media #{$xs-only, $sm-only} {
                        font-size: 16px;
                        text-align: center;
                    }
                }
                .contact-item {
                    display: flex;
                    margin-top: 30px;
                    margin-bottom: 30px;
                    .icon {
                        width: 20%;
                        max-width: 70px;
                        margin-right: 20px;
                        -webkit-animation: pop-in 0.5s;
                        -moz-animation: pop-in 0.5s;
                        -ms-animation: pop-in 0.5s;
                        @media #{$xs-only} {
                            width: 18%;
                            margin-top: -10px;
                            margin-right: 25px;
                        }
                        img {
                            @media #{$xs-only} {
                                width: 50px;
                            }
                            @media #{$sm-only} {
                                width: 60px;
                            }
                        }
                    }
                    .copy {
                        width: 80%;
                        @media #{$xs-only, $sm-only} {
                            width: 112%;
                        }
                        h3, h2 {
                            color: $yellow;
                            font-size: 20px;
                            margin-bottom: 10px;
                        }
                        p {
                            font-size: 16px;
                            @media #{$xs-only, $sm-only} {
                                text-align: left;
                            }
                            span {
                                @media #{$xs-only} {
                                    display: block;
                                }
                                @media #{$xs-up} {
                                    display: inline-block;
                                }
                            }
                        }
                        a {
                            color: white;
                            text-decoration: none;
                            animation-name: fadeIn;
                            animation-duration: 1s;
                            &:hover {
                                color: $yellow;
                            }
                        }
                    }
                }
            }
            .sign-up {
                text-align: center;
                color: white;
                img {
                    max-width: 105px;
                    -webkit-animation: pop-in 0.5s;
                    -moz-animation: pop-in 0.5s;
                    -ms-animation: pop-in 0.5s;
                }
                h2,
                h3 {
                    text-transform: uppercase;
                    animation-name: fadeIn;
                    animation-duration: 1s;
                }
                h2 {
                    color: $yellow;
                    margin-top: 30px;
                    margin-bottom: 10px;
                }
                h3 {
                    margin-top: 50px;
                }
                p {
                    animation-name: fadeIn;
                    animation-duration: 1s;
                }
                input[type="text"] {
                    display: block;
                    margin: 0 auto;
                    border-radius: 5px;
                    padding: 10px;
                    margin-top: 10px;
                    width:242px;
                    margin-bottom: 5px;
                    color: $black;
                    border-bottom: 4px solid white;
                    transition: border-bottom 0.25s ease;
                    -webkit-animation: pop-in 0.5s;
                    -moz-animation: pop-in 0.5s;
                    -ms-animation: pop-in 0.5s;
                    &.invalid {
                        border-bottom: 4px solid tomato;
                    }
                }
            }
        }
        .bottom {
            padding-top: 40px;
            padding-bottom: 40px;
        }
        .map {
            display: block;
            width: 100%;
            height: 380px;
            animation-name: fadeIn;
            animation-duration: 1s;
            /* background-image: url(images/contact-map.jpg);
            background-size:cover;
            background-repeat:no-repeat;
            background-position:center;*/
        }
        .mobile {
            display: none;
            @media #{$xs-only, $sm-only} {
                display: block;
                padding-bottom: 30px;
            }
        }
        .desktop {
            display: none;
            @media #{$md-up} {
                display: block;
            }
        }
    }

    .careers {
        background-color: $black90;
        text-align: center;
        .looking {
            color: white;
            font-size: 18px;
            .yellow {
                color: $yellow;
            }
            .bold {
                font-weight: bold;
            }
            a {
                text-decoration: none;
                color: $yellow;
                &:hover {
                    color: white;
                }
            }
        }
    }
}