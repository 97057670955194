@rm-comp 'R0KhMDnNKc' {

    @import 'sass/variables';

    .portfolio {
        background-color:$black90;

        .portfolio-nav {
            max-width:1060px;
            display:block;
            margin:0 auto;
            padding-top:15px;

            &:hover {
                fdfs:ddssd;
            }

            @media #{$xs-only} {
                display:none;
            }
            @media #{$md-only} {
                max-width:750px;
            }
            @media #{$sm-only} {
                max-width:556px;
            }

            a {
                color:$yellow;
                padding:20px;
                text-decoration:none;
                display:inline-block;
                position: relative;

                @media #{$md-only} {
                    padding:10px;
                }

                @media #{$sm-only} {
                    padding:5px;
                }
                &.selected {
                    /* &:after {
                        content: "";
                        display: block;
                        margin: 0 auto;
                        width: 30%;
                        padding-top: 10px;
                        border-bottom: 1px solid $yellow;
                        position: absolute;
                        top: 35px;
                        left: 35%;

                        @media #{$md-only} {
                            top: 25px;
                        }

                        @media #{$sm-only} {
                            top:20px;
                        }
                    } */
                }
            }
        }
        .mobile-nav {
            position: relative;
            display:block;
            max-width:320px;
            margin:0 auto;
            border-bottom:1px solid $yellow;
            @media #{$sm-up} {
                display:none;
            }
            .dropdown-content {
                display: none;
                position: absolute;
                background-color: $black90;
                min-width: 320px;
                overflow: auto;
                z-index: 1;

                &.show {
                    display:block;
                }

                a {
                    color: $yellow;
                    padding: 12px 16px;
                    text-decoration: none;
                    display: block;
                    border-top:0.5px solid $yellow;
                }
            }
            .nav-button {
                background-color: $black90;
                color: $yellow;
                padding: 12px;
                font-size: 16px;
                border: none;
                cursor: pointer;
                text-transform: uppercase;
                min-width: 320px;
            }
        }
    }
    .show {display:block;}

    .mobile-portfolio-nav {
        position: relative;
        display: block;
        max-width: 320px;
        margin: 0 auto;
        border-bottom: 1px solid $yellow;
        @media #{$sm-up} {
            display: none;
        }
        .dropdown-content {
            display: none;
            position: absolute;
            background-color: $black90;
            min-width: 320px;
            overflow: auto;
            z-index: 1006;

            &.show {
                display: block;
            }

            a {
                color: $yellow;
                padding: 12px 16px;
                text-decoration: none;
                display: block;
                border-top: 0.5px solid $yellow;
            }
        }
        .nav-button {
            background-color: $black90;
            color: $yellow;
            padding: 12px;
            padding-top:20px;
            font-size: 16px;
            border: none;
            cursor: pointer;
            text-transform: uppercase;
            display: block;
            outline: none;
        }
    }

    .chevron-nav {
    	border-style: solid;
    	border-width: 0.1em 0.1em 0 0;
    	content: '';
    	display: inline-block;
    	height: 0.45em;
    	left: 0.15em;
    	position: relative;
    	top: 0.25em;
    	transform: rotate(-45deg);
    	vertical-align: top;
    	width: 0.45em;
        color:$yellow;
        &:hover {
            color:$yellow;
        }
    }

    .chevron-nav.bottom {
    	top: 0;
    	transform: rotate(135deg);
        margin-top:10px;
        text-align:right;
        float:right;
        margin-right:10px;
    }

    .mobile-portfolio-nav {
        .nav-button {
            width:100%;
            text-align:left;
        }
    }

    #nav-blocker {
        position: fixed;
        top:0;
        left:0;
        right:0;
        bottom: 0;
        z-index:1005;
        display:none;

        &.show {
            display:block;
        }
    }

}