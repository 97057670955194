.enable-redacted {

    .redact,
    .redact2,
    .redact3 {
        color: transparent;
        background-image: url('./images/icons/redact.png');
        background-repeat: repeat-x;
        background-position-y: 4px;
        transition: 0.1s ease-in-out;
        pointer-events: auto;
    }

    .redact {
        background-size: 100% 70%;
    }

    .redact2 {
        background-size: 200% 70%;
    }

    .redact3 {
        background-size: 300% 70%;
    }

    .redact-header {
        color: transparent;
        background-image: url('./images/icons/redact.png');
        background-repeat: repeat-x;
        background-size: 350% 85%;
        background-position-y: 4px;
        transition: 0.1s ease-in-out;
        pointer-events: auto;
    }


    .redact-white,
    .redact-white2,
    .redact-white3 {
        color: transparent;
        background-image: url('./images/icons/redact-white.png');
        background-repeat: repeat-x;
        background-position-y: 4px;
        transition: 0.1s ease-in-out;
        pointer-events: auto;
    }

    .redact-white {
        background-size: 100% 70%;
    }


    .redact-white2 {
        background-size: 200% 70%;
    }

    .redact-white3 {
        background-size: 300% 70%;
    }

    .redact-yellow,
    .redact-yellow2,
    .redact-yellow3 {
        color: transparent;
        background-image: url('./images/icons/redact-yellow.png');
        background-repeat: repeat-x;
        background-position-y: 4px;
        transition: 0.1s ease-in-out;
        pointer-events: auto;
    }

    .redact-yellow {
        background-size: 100% 70%;
    }

    .redact-yellow2 {
        background-size: 200% 70%;
    }

    .redact-yellow3 {
        background-size: 300% 70%;
    }

    .redact-yellow-header {
        color: transparent;
        background-image: url('./images/icons/redact-yellow.png');
        background-repeat: repeat-x;
        background-size: 350% 85%;
        background-position-y: 4px;
        transition: 0.1s ease-in-out;
        pointer-events: auto;
    }

    .redact:hover,
    .redact2:hover,
    .redact3:hover {
        color: #000000;
        transition: 0.1s ease-in-out;
        background-image: none;

    }

    .redact-header:hover {
        color: #000000;
        transition: 0.1s ease-in-out;
        background-image: none;
    }

    .redact-yellow-header:hover {
        color: $yellow;
        transition: 0.1s ease-in-out;
        background-image: none;

    }

    .redact-white:hover,
    .redact-white2:hover,
    .redact-white3:hover,
        {
        color: #FFFFFF;
        transition: 0.1s ease-in-out;
        background-image: none;

    }

    .redact-yellow:hover,
    .redact-yellow2:hover,
    .redact-yellow3:hover {
        color: $yellow;
        transition: 0.1s ease-in-out;
        background-image: none;

    }
}