@keyframes slidein {
  from {
    display: none;
    margin-bottom: -100%;
  }
  to {
    display: block;
    margin-bottom: 0%;
  }
}
*, *:before, *:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Fix IE and iOS text resizing on orientation change */
html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  scroll-behavior: smooth;
}

html, body {
  margin: 0;
  padding: 0;
}

body {
  font-size: 1rem;
}

button {
  appearance: none;
  font-size: inherit;
  cursor: pointer;
  border: none;
  background: transparent;
}
button.link {
  border: 0;
  background: transparent;
  text-align: left;
}

input, select, textarea {
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  border: none;
}

a.btn:link {
  text-decoration: none;
  text-align: center;
  display: inline-block;
}
a.btn:link:hover {
  text-decoration: none;
}

[hidden] {
  display: none !important;
}

/* turn off buttons in input[type=number] */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* removes "x" button from IE10/11 input fields */
input::-ms-clear {
  display: none;
}

.form-item, .inline-buttons, .form-row, .row {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}

.container {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}
.container.no-gutter {
  padding-left: 0;
  padding-right: 0;
}
.container:not(.full) {
  max-width: 1200px;
}

.row {
  margin-left: -15px;
  margin-right: -15px;
}
.row > * {
  padding-left: 15px;
  padding-right: 15px;
}
.row.no-gutter {
  margin-left: 0;
  margin-right: 0;
}
.row.no-gutter > * {
  padding-left: 0;
  padding-right: 0;
}

.form-row {
  margin-left: -5px;
  margin-right: -5px;
  align-items: baseline;
}
.form-row > * {
  padding-left: 5px;
  padding-right: 5px;
}
.form-row.no-gutter {
  margin-left: 0;
  margin-right: 0;
}
.form-row.no-gutter > * {
  padding-left: 0;
  padding-right: 0;
}

.inline-buttons {
  margin: -5px;
}
.inline-buttons > * {
  flex: 0 0 auto;
  margin: 5px;
}

.menubar {
  border: 1px solid transparent;
}

.form-item {
  margin: 1rem 0;
  align-items: baseline;
}
.form-item > * {
  flex: 0 0 auto;
  width: 100%;
}

.gutter {
  padding-left: 15px;
  padding-right: 15px;
}

.form-gutter {
  padding-left: 5px;
  padding-right: 5px;
}

.col {
  flex: 1 0 0;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
  max-width: 100%;
}

.w-0 {
  width: 0%;
}

.w-auto {
  width: auto;
}

.w-1-1, .w-2-2, .w-3-3, .w-4-4, .w-5-5, .w-6-6, .w-8-8, .w-9-9, .w-10-10, .w-12-12, .w-15-15, .w-16-16, .w-20-20 {
  width: 100%;
}

.w-1-2, .w-2-4, .w-3-6, .w-4-8, .w-5-10, .w-6-12, .w-8-16, .w-10-20 {
  width: 50%;
}

.w-1-3, .w-2-6, .w-3-9, .w-4-12, .w-5-15 {
  width: 33.3333333333%;
}

.w-2-3, .w-4-6, .w-6-9, .w-8-12, .w-10-15 {
  width: 66.6666666667%;
}

.w-1-4, .w-2-8, .w-3-12, .w-4-16, .w-5-20 {
  width: 25%;
}

.w-3-4, .w-6-8, .w-9-12, .w-12-16, .w-15-20 {
  width: 75%;
}

.w-1-5, .w-2-10, .w-3-15, .w-4-20 {
  width: 20%;
}

.w-2-5, .w-4-10, .w-6-15, .w-8-20 {
  width: 40%;
}

.w-3-5, .w-6-10, .w-9-15, .w-12-20 {
  width: 60%;
}

.w-4-5, .w-8-10, .w-12-15, .w-16-20 {
  width: 80%;
}

.w-1-6, .w-2-12 {
  width: 16.6666666667%;
}

.w-5-6, .w-10-12 {
  width: 83.3333333333%;
}

.w-1-8, .w-2-16 {
  width: 12.5%;
}

.w-3-8, .w-6-16 {
  width: 37.5%;
}

.w-5-8, .w-10-16 {
  width: 62.5%;
}

.w-7-8, .w-14-16 {
  width: 87.5%;
}

.w-1-9 {
  width: 11.1111111111%;
}

.w-2-9 {
  width: 22.2222222222%;
}

.w-4-9 {
  width: 44.4444444444%;
}

.w-5-9 {
  width: 55.5555555556%;
}

.w-7-9 {
  width: 77.7777777778%;
}

.w-8-9 {
  width: 88.8888888889%;
}

.w-1-10, .w-2-20 {
  width: 10%;
}

.w-3-10, .w-6-20 {
  width: 30%;
}

.w-7-10, .w-14-20 {
  width: 70%;
}

.w-9-10, .w-18-20 {
  width: 90%;
}

.w-1-12 {
  width: 8.3333333333%;
}

.w-5-12 {
  width: 41.6666666667%;
}

.w-7-12 {
  width: 58.3333333333%;
}

.w-11-12 {
  width: 91.6666666667%;
}

.w-1-15 {
  width: 6.6666666667%;
}

.w-2-15 {
  width: 13.3333333333%;
}

.w-4-15 {
  width: 26.6666666667%;
}

.w-7-15 {
  width: 46.6666666667%;
}

.w-8-15 {
  width: 53.3333333333%;
}

.w-11-15 {
  width: 73.3333333333%;
}

.w-13-15 {
  width: 86.6666666667%;
}

.w-14-15 {
  width: 93.3333333333%;
}

.w-1-16 {
  width: 6.25%;
}

.w-3-16 {
  width: 18.75%;
}

.w-5-16 {
  width: 31.25%;
}

.w-7-16 {
  width: 43.75%;
}

.w-9-16 {
  width: 56.25%;
}

.w-11-16 {
  width: 68.75%;
}

.w-13-16 {
  width: 81.25%;
}

.w-15-16 {
  width: 93.75%;
}

.w-1-20 {
  width: 5%;
}

.w-3-20 {
  width: 15%;
}

.w-7-20 {
  width: 35%;
}

.w-9-20 {
  width: 45%;
}

.w-11-20 {
  width: 55%;
}

.w-13-20 {
  width: 65%;
}

.w-17-20 {
  width: 85%;
}

.w-19-20 {
  width: 95%;
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-table-row {
  display: table-row !important;
}

.d-flex {
  display: flex !important;
}

.d-flex-column {
  display: flex !important;
  flex-direction: column;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-inline-flex-column {
  display: inline-flex !important;
  flex-direction: column;
}

.j-flex-start {
  justify-content: flex-start;
}

.j-flex-end {
  justify-content: flex-end;
}

.j-center {
  justify-content: center;
}

.j-space-between {
  justify-content: space-between;
}

.j-space-around {
  justify-content: space-around;
}

.j-space-evenly {
  justify-content: space-evenly;
}

.a-flex-start {
  align-items: flex-start;
}

.as-flex-start {
  align-self: flex-start;
}

.a-flex-end {
  align-items: flex-end;
}

.as-flex-end {
  align-self: flex-end;
}

.a-center {
  align-items: center;
}

.as-center {
  align-self: center;
}

.a-baseline {
  align-items: baseline;
}

.as-baseline {
  align-self: baseline;
}

.a-stretch {
  align-items: stretch;
}

.as-stretch {
  align-self: stretch;
}

.a-self-start-self-end {
  align-items: self-start-self-end;
}

.as-self-start-self-end {
  align-self: self-start-self-end;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
    max-width: 100%;
  }
  .w-sm-0 {
    width: 0%;
  }
  .w-sm-auto {
    width: auto;
  }
  .w-sm-1-1, .w-sm-2-2, .w-sm-3-3, .w-sm-4-4, .w-sm-5-5, .w-sm-6-6, .w-sm-8-8, .w-sm-9-9, .w-sm-10-10, .w-sm-12-12, .w-sm-15-15, .w-sm-16-16, .w-sm-20-20 {
    width: 100%;
  }
  .w-sm-1-2, .w-sm-2-4, .w-sm-3-6, .w-sm-4-8, .w-sm-5-10, .w-sm-6-12, .w-sm-8-16, .w-sm-10-20 {
    width: 50%;
  }
  .w-sm-1-3, .w-sm-2-6, .w-sm-3-9, .w-sm-4-12, .w-sm-5-15 {
    width: 33.3333333333%;
  }
  .w-sm-2-3, .w-sm-4-6, .w-sm-6-9, .w-sm-8-12, .w-sm-10-15 {
    width: 66.6666666667%;
  }
  .w-sm-1-4, .w-sm-2-8, .w-sm-3-12, .w-sm-4-16, .w-sm-5-20 {
    width: 25%;
  }
  .w-sm-3-4, .w-sm-6-8, .w-sm-9-12, .w-sm-12-16, .w-sm-15-20 {
    width: 75%;
  }
  .w-sm-1-5, .w-sm-2-10, .w-sm-3-15, .w-sm-4-20 {
    width: 20%;
  }
  .w-sm-2-5, .w-sm-4-10, .w-sm-6-15, .w-sm-8-20 {
    width: 40%;
  }
  .w-sm-3-5, .w-sm-6-10, .w-sm-9-15, .w-sm-12-20 {
    width: 60%;
  }
  .w-sm-4-5, .w-sm-8-10, .w-sm-12-15, .w-sm-16-20 {
    width: 80%;
  }
  .w-sm-1-6, .w-sm-2-12 {
    width: 16.6666666667%;
  }
  .w-sm-5-6, .w-sm-10-12 {
    width: 83.3333333333%;
  }
  .w-sm-1-8, .w-sm-2-16 {
    width: 12.5%;
  }
  .w-sm-3-8, .w-sm-6-16 {
    width: 37.5%;
  }
  .w-sm-5-8, .w-sm-10-16 {
    width: 62.5%;
  }
  .w-sm-7-8, .w-sm-14-16 {
    width: 87.5%;
  }
  .w-sm-1-9 {
    width: 11.1111111111%;
  }
  .w-sm-2-9 {
    width: 22.2222222222%;
  }
  .w-sm-4-9 {
    width: 44.4444444444%;
  }
  .w-sm-5-9 {
    width: 55.5555555556%;
  }
  .w-sm-7-9 {
    width: 77.7777777778%;
  }
  .w-sm-8-9 {
    width: 88.8888888889%;
  }
  .w-sm-1-10, .w-sm-2-20 {
    width: 10%;
  }
  .w-sm-3-10, .w-sm-6-20 {
    width: 30%;
  }
  .w-sm-7-10, .w-sm-14-20 {
    width: 70%;
  }
  .w-sm-9-10, .w-sm-18-20 {
    width: 90%;
  }
  .w-sm-1-12 {
    width: 8.3333333333%;
  }
  .w-sm-5-12 {
    width: 41.6666666667%;
  }
  .w-sm-7-12 {
    width: 58.3333333333%;
  }
  .w-sm-11-12 {
    width: 91.6666666667%;
  }
  .w-sm-1-15 {
    width: 6.6666666667%;
  }
  .w-sm-2-15 {
    width: 13.3333333333%;
  }
  .w-sm-4-15 {
    width: 26.6666666667%;
  }
  .w-sm-7-15 {
    width: 46.6666666667%;
  }
  .w-sm-8-15 {
    width: 53.3333333333%;
  }
  .w-sm-11-15 {
    width: 73.3333333333%;
  }
  .w-sm-13-15 {
    width: 86.6666666667%;
  }
  .w-sm-14-15 {
    width: 93.3333333333%;
  }
  .w-sm-1-16 {
    width: 6.25%;
  }
  .w-sm-3-16 {
    width: 18.75%;
  }
  .w-sm-5-16 {
    width: 31.25%;
  }
  .w-sm-7-16 {
    width: 43.75%;
  }
  .w-sm-9-16 {
    width: 56.25%;
  }
  .w-sm-11-16 {
    width: 68.75%;
  }
  .w-sm-13-16 {
    width: 81.25%;
  }
  .w-sm-15-16 {
    width: 93.75%;
  }
  .w-sm-1-20 {
    width: 5%;
  }
  .w-sm-3-20 {
    width: 15%;
  }
  .w-sm-7-20 {
    width: 35%;
  }
  .w-sm-9-20 {
    width: 45%;
  }
  .w-sm-11-20 {
    width: 55%;
  }
  .w-sm-13-20 {
    width: 65%;
  }
  .w-sm-17-20 {
    width: 85%;
  }
  .w-sm-19-20 {
    width: 95%;
  }
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-flex-column {
    display: flex !important;
    flex-direction: column;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-inline-flex-column {
    display: inline-flex !important;
    flex-direction: column;
  }
  .j-sm-flex-start {
    justify-content: flex-start;
  }
  .j-sm-flex-end {
    justify-content: flex-end;
  }
  .j-sm-center {
    justify-content: center;
  }
  .j-sm-space-between {
    justify-content: space-between;
  }
  .j-sm-space-around {
    justify-content: space-around;
  }
  .j-sm-space-evenly {
    justify-content: space-evenly;
  }
  .a-sm-flex-start {
    align-items: flex-start;
  }
  .as-sm-flex-start {
    align-self: flex-start;
  }
  .a-sm-flex-end {
    align-items: flex-end;
  }
  .as-sm-flex-end {
    align-self: flex-end;
  }
  .a-sm-center {
    align-items: center;
  }
  .as-sm-center {
    align-self: center;
  }
  .a-sm-baseline {
    align-items: baseline;
  }
  .as-sm-baseline {
    align-self: baseline;
  }
  .a-sm-stretch {
    align-items: stretch;
  }
  .as-sm-stretch {
    align-self: stretch;
  }
  .a-sm-self-start-self-end {
    align-items: self-start-self-end;
  }
  .as-sm-self-start-self-end {
    align-self: self-start-self-end;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
    max-width: 100%;
  }
  .w-md-0 {
    width: 0%;
  }
  .w-md-auto {
    width: auto;
  }
  .w-md-1-1, .w-md-2-2, .w-md-3-3, .w-md-4-4, .w-md-5-5, .w-md-6-6, .w-md-8-8, .w-md-9-9, .w-md-10-10, .w-md-12-12, .w-md-15-15, .w-md-16-16, .w-md-20-20 {
    width: 100%;
  }
  .w-md-1-2, .w-md-2-4, .w-md-3-6, .w-md-4-8, .w-md-5-10, .w-md-6-12, .w-md-8-16, .w-md-10-20 {
    width: 50%;
  }
  .w-md-1-3, .w-md-2-6, .w-md-3-9, .w-md-4-12, .w-md-5-15 {
    width: 33.3333333333%;
  }
  .w-md-2-3, .w-md-4-6, .w-md-6-9, .w-md-8-12, .w-md-10-15 {
    width: 66.6666666667%;
  }
  .w-md-1-4, .w-md-2-8, .w-md-3-12, .w-md-4-16, .w-md-5-20 {
    width: 25%;
  }
  .w-md-3-4, .w-md-6-8, .w-md-9-12, .w-md-12-16, .w-md-15-20 {
    width: 75%;
  }
  .w-md-1-5, .w-md-2-10, .w-md-3-15, .w-md-4-20 {
    width: 20%;
  }
  .w-md-2-5, .w-md-4-10, .w-md-6-15, .w-md-8-20 {
    width: 40%;
  }
  .w-md-3-5, .w-md-6-10, .w-md-9-15, .w-md-12-20 {
    width: 60%;
  }
  .w-md-4-5, .w-md-8-10, .w-md-12-15, .w-md-16-20 {
    width: 80%;
  }
  .w-md-1-6, .w-md-2-12 {
    width: 16.6666666667%;
  }
  .w-md-5-6, .w-md-10-12 {
    width: 83.3333333333%;
  }
  .w-md-1-8, .w-md-2-16 {
    width: 12.5%;
  }
  .w-md-3-8, .w-md-6-16 {
    width: 37.5%;
  }
  .w-md-5-8, .w-md-10-16 {
    width: 62.5%;
  }
  .w-md-7-8, .w-md-14-16 {
    width: 87.5%;
  }
  .w-md-1-9 {
    width: 11.1111111111%;
  }
  .w-md-2-9 {
    width: 22.2222222222%;
  }
  .w-md-4-9 {
    width: 44.4444444444%;
  }
  .w-md-5-9 {
    width: 55.5555555556%;
  }
  .w-md-7-9 {
    width: 77.7777777778%;
  }
  .w-md-8-9 {
    width: 88.8888888889%;
  }
  .w-md-1-10, .w-md-2-20 {
    width: 10%;
  }
  .w-md-3-10, .w-md-6-20 {
    width: 30%;
  }
  .w-md-7-10, .w-md-14-20 {
    width: 70%;
  }
  .w-md-9-10, .w-md-18-20 {
    width: 90%;
  }
  .w-md-1-12 {
    width: 8.3333333333%;
  }
  .w-md-5-12 {
    width: 41.6666666667%;
  }
  .w-md-7-12 {
    width: 58.3333333333%;
  }
  .w-md-11-12 {
    width: 91.6666666667%;
  }
  .w-md-1-15 {
    width: 6.6666666667%;
  }
  .w-md-2-15 {
    width: 13.3333333333%;
  }
  .w-md-4-15 {
    width: 26.6666666667%;
  }
  .w-md-7-15 {
    width: 46.6666666667%;
  }
  .w-md-8-15 {
    width: 53.3333333333%;
  }
  .w-md-11-15 {
    width: 73.3333333333%;
  }
  .w-md-13-15 {
    width: 86.6666666667%;
  }
  .w-md-14-15 {
    width: 93.3333333333%;
  }
  .w-md-1-16 {
    width: 6.25%;
  }
  .w-md-3-16 {
    width: 18.75%;
  }
  .w-md-5-16 {
    width: 31.25%;
  }
  .w-md-7-16 {
    width: 43.75%;
  }
  .w-md-9-16 {
    width: 56.25%;
  }
  .w-md-11-16 {
    width: 68.75%;
  }
  .w-md-13-16 {
    width: 81.25%;
  }
  .w-md-15-16 {
    width: 93.75%;
  }
  .w-md-1-20 {
    width: 5%;
  }
  .w-md-3-20 {
    width: 15%;
  }
  .w-md-7-20 {
    width: 35%;
  }
  .w-md-9-20 {
    width: 45%;
  }
  .w-md-11-20 {
    width: 55%;
  }
  .w-md-13-20 {
    width: 65%;
  }
  .w-md-17-20 {
    width: 85%;
  }
  .w-md-19-20 {
    width: 95%;
  }
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-flex-column {
    display: flex !important;
    flex-direction: column;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-inline-flex-column {
    display: inline-flex !important;
    flex-direction: column;
  }
  .j-md-flex-start {
    justify-content: flex-start;
  }
  .j-md-flex-end {
    justify-content: flex-end;
  }
  .j-md-center {
    justify-content: center;
  }
  .j-md-space-between {
    justify-content: space-between;
  }
  .j-md-space-around {
    justify-content: space-around;
  }
  .j-md-space-evenly {
    justify-content: space-evenly;
  }
  .a-md-flex-start {
    align-items: flex-start;
  }
  .as-md-flex-start {
    align-self: flex-start;
  }
  .a-md-flex-end {
    align-items: flex-end;
  }
  .as-md-flex-end {
    align-self: flex-end;
  }
  .a-md-center {
    align-items: center;
  }
  .as-md-center {
    align-self: center;
  }
  .a-md-baseline {
    align-items: baseline;
  }
  .as-md-baseline {
    align-self: baseline;
  }
  .a-md-stretch {
    align-items: stretch;
  }
  .as-md-stretch {
    align-self: stretch;
  }
  .a-md-self-start-self-end {
    align-items: self-start-self-end;
  }
  .as-md-self-start-self-end {
    align-self: self-start-self-end;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
    max-width: 100%;
  }
  .w-lg-0 {
    width: 0%;
  }
  .w-lg-auto {
    width: auto;
  }
  .w-lg-1-1, .w-lg-2-2, .w-lg-3-3, .w-lg-4-4, .w-lg-5-5, .w-lg-6-6, .w-lg-8-8, .w-lg-9-9, .w-lg-10-10, .w-lg-12-12, .w-lg-15-15, .w-lg-16-16, .w-lg-20-20 {
    width: 100%;
  }
  .w-lg-1-2, .w-lg-2-4, .w-lg-3-6, .w-lg-4-8, .w-lg-5-10, .w-lg-6-12, .w-lg-8-16, .w-lg-10-20 {
    width: 50%;
  }
  .w-lg-1-3, .w-lg-2-6, .w-lg-3-9, .w-lg-4-12, .w-lg-5-15 {
    width: 33.3333333333%;
  }
  .w-lg-2-3, .w-lg-4-6, .w-lg-6-9, .w-lg-8-12, .w-lg-10-15 {
    width: 66.6666666667%;
  }
  .w-lg-1-4, .w-lg-2-8, .w-lg-3-12, .w-lg-4-16, .w-lg-5-20 {
    width: 25%;
  }
  .w-lg-3-4, .w-lg-6-8, .w-lg-9-12, .w-lg-12-16, .w-lg-15-20 {
    width: 75%;
  }
  .w-lg-1-5, .w-lg-2-10, .w-lg-3-15, .w-lg-4-20 {
    width: 20%;
  }
  .w-lg-2-5, .w-lg-4-10, .w-lg-6-15, .w-lg-8-20 {
    width: 40%;
  }
  .w-lg-3-5, .w-lg-6-10, .w-lg-9-15, .w-lg-12-20 {
    width: 60%;
  }
  .w-lg-4-5, .w-lg-8-10, .w-lg-12-15, .w-lg-16-20 {
    width: 80%;
  }
  .w-lg-1-6, .w-lg-2-12 {
    width: 16.6666666667%;
  }
  .w-lg-5-6, .w-lg-10-12 {
    width: 83.3333333333%;
  }
  .w-lg-1-8, .w-lg-2-16 {
    width: 12.5%;
  }
  .w-lg-3-8, .w-lg-6-16 {
    width: 37.5%;
  }
  .w-lg-5-8, .w-lg-10-16 {
    width: 62.5%;
  }
  .w-lg-7-8, .w-lg-14-16 {
    width: 87.5%;
  }
  .w-lg-1-9 {
    width: 11.1111111111%;
  }
  .w-lg-2-9 {
    width: 22.2222222222%;
  }
  .w-lg-4-9 {
    width: 44.4444444444%;
  }
  .w-lg-5-9 {
    width: 55.5555555556%;
  }
  .w-lg-7-9 {
    width: 77.7777777778%;
  }
  .w-lg-8-9 {
    width: 88.8888888889%;
  }
  .w-lg-1-10, .w-lg-2-20 {
    width: 10%;
  }
  .w-lg-3-10, .w-lg-6-20 {
    width: 30%;
  }
  .w-lg-7-10, .w-lg-14-20 {
    width: 70%;
  }
  .w-lg-9-10, .w-lg-18-20 {
    width: 90%;
  }
  .w-lg-1-12 {
    width: 8.3333333333%;
  }
  .w-lg-5-12 {
    width: 41.6666666667%;
  }
  .w-lg-7-12 {
    width: 58.3333333333%;
  }
  .w-lg-11-12 {
    width: 91.6666666667%;
  }
  .w-lg-1-15 {
    width: 6.6666666667%;
  }
  .w-lg-2-15 {
    width: 13.3333333333%;
  }
  .w-lg-4-15 {
    width: 26.6666666667%;
  }
  .w-lg-7-15 {
    width: 46.6666666667%;
  }
  .w-lg-8-15 {
    width: 53.3333333333%;
  }
  .w-lg-11-15 {
    width: 73.3333333333%;
  }
  .w-lg-13-15 {
    width: 86.6666666667%;
  }
  .w-lg-14-15 {
    width: 93.3333333333%;
  }
  .w-lg-1-16 {
    width: 6.25%;
  }
  .w-lg-3-16 {
    width: 18.75%;
  }
  .w-lg-5-16 {
    width: 31.25%;
  }
  .w-lg-7-16 {
    width: 43.75%;
  }
  .w-lg-9-16 {
    width: 56.25%;
  }
  .w-lg-11-16 {
    width: 68.75%;
  }
  .w-lg-13-16 {
    width: 81.25%;
  }
  .w-lg-15-16 {
    width: 93.75%;
  }
  .w-lg-1-20 {
    width: 5%;
  }
  .w-lg-3-20 {
    width: 15%;
  }
  .w-lg-7-20 {
    width: 35%;
  }
  .w-lg-9-20 {
    width: 45%;
  }
  .w-lg-11-20 {
    width: 55%;
  }
  .w-lg-13-20 {
    width: 65%;
  }
  .w-lg-17-20 {
    width: 85%;
  }
  .w-lg-19-20 {
    width: 95%;
  }
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-flex-column {
    display: flex !important;
    flex-direction: column;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-inline-flex-column {
    display: inline-flex !important;
    flex-direction: column;
  }
  .j-lg-flex-start {
    justify-content: flex-start;
  }
  .j-lg-flex-end {
    justify-content: flex-end;
  }
  .j-lg-center {
    justify-content: center;
  }
  .j-lg-space-between {
    justify-content: space-between;
  }
  .j-lg-space-around {
    justify-content: space-around;
  }
  .j-lg-space-evenly {
    justify-content: space-evenly;
  }
  .a-lg-flex-start {
    align-items: flex-start;
  }
  .as-lg-flex-start {
    align-self: flex-start;
  }
  .a-lg-flex-end {
    align-items: flex-end;
  }
  .as-lg-flex-end {
    align-self: flex-end;
  }
  .a-lg-center {
    align-items: center;
  }
  .as-lg-center {
    align-self: center;
  }
  .a-lg-baseline {
    align-items: baseline;
  }
  .as-lg-baseline {
    align-self: baseline;
  }
  .a-lg-stretch {
    align-items: stretch;
  }
  .as-lg-stretch {
    align-self: stretch;
  }
  .a-lg-self-start-self-end {
    align-items: self-start-self-end;
  }
  .as-lg-self-start-self-end {
    align-self: self-start-self-end;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
    max-width: 100%;
  }
  .w-xl-0 {
    width: 0%;
  }
  .w-xl-auto {
    width: auto;
  }
  .w-xl-1-1, .w-xl-2-2, .w-xl-3-3, .w-xl-4-4, .w-xl-5-5, .w-xl-6-6, .w-xl-8-8, .w-xl-9-9, .w-xl-10-10, .w-xl-12-12, .w-xl-15-15, .w-xl-16-16, .w-xl-20-20 {
    width: 100%;
  }
  .w-xl-1-2, .w-xl-2-4, .w-xl-3-6, .w-xl-4-8, .w-xl-5-10, .w-xl-6-12, .w-xl-8-16, .w-xl-10-20 {
    width: 50%;
  }
  .w-xl-1-3, .w-xl-2-6, .w-xl-3-9, .w-xl-4-12, .w-xl-5-15 {
    width: 33.3333333333%;
  }
  .w-xl-2-3, .w-xl-4-6, .w-xl-6-9, .w-xl-8-12, .w-xl-10-15 {
    width: 66.6666666667%;
  }
  .w-xl-1-4, .w-xl-2-8, .w-xl-3-12, .w-xl-4-16, .w-xl-5-20 {
    width: 25%;
  }
  .w-xl-3-4, .w-xl-6-8, .w-xl-9-12, .w-xl-12-16, .w-xl-15-20 {
    width: 75%;
  }
  .w-xl-1-5, .w-xl-2-10, .w-xl-3-15, .w-xl-4-20 {
    width: 20%;
  }
  .w-xl-2-5, .w-xl-4-10, .w-xl-6-15, .w-xl-8-20 {
    width: 40%;
  }
  .w-xl-3-5, .w-xl-6-10, .w-xl-9-15, .w-xl-12-20 {
    width: 60%;
  }
  .w-xl-4-5, .w-xl-8-10, .w-xl-12-15, .w-xl-16-20 {
    width: 80%;
  }
  .w-xl-1-6, .w-xl-2-12 {
    width: 16.6666666667%;
  }
  .w-xl-5-6, .w-xl-10-12 {
    width: 83.3333333333%;
  }
  .w-xl-1-8, .w-xl-2-16 {
    width: 12.5%;
  }
  .w-xl-3-8, .w-xl-6-16 {
    width: 37.5%;
  }
  .w-xl-5-8, .w-xl-10-16 {
    width: 62.5%;
  }
  .w-xl-7-8, .w-xl-14-16 {
    width: 87.5%;
  }
  .w-xl-1-9 {
    width: 11.1111111111%;
  }
  .w-xl-2-9 {
    width: 22.2222222222%;
  }
  .w-xl-4-9 {
    width: 44.4444444444%;
  }
  .w-xl-5-9 {
    width: 55.5555555556%;
  }
  .w-xl-7-9 {
    width: 77.7777777778%;
  }
  .w-xl-8-9 {
    width: 88.8888888889%;
  }
  .w-xl-1-10, .w-xl-2-20 {
    width: 10%;
  }
  .w-xl-3-10, .w-xl-6-20 {
    width: 30%;
  }
  .w-xl-7-10, .w-xl-14-20 {
    width: 70%;
  }
  .w-xl-9-10, .w-xl-18-20 {
    width: 90%;
  }
  .w-xl-1-12 {
    width: 8.3333333333%;
  }
  .w-xl-5-12 {
    width: 41.6666666667%;
  }
  .w-xl-7-12 {
    width: 58.3333333333%;
  }
  .w-xl-11-12 {
    width: 91.6666666667%;
  }
  .w-xl-1-15 {
    width: 6.6666666667%;
  }
  .w-xl-2-15 {
    width: 13.3333333333%;
  }
  .w-xl-4-15 {
    width: 26.6666666667%;
  }
  .w-xl-7-15 {
    width: 46.6666666667%;
  }
  .w-xl-8-15 {
    width: 53.3333333333%;
  }
  .w-xl-11-15 {
    width: 73.3333333333%;
  }
  .w-xl-13-15 {
    width: 86.6666666667%;
  }
  .w-xl-14-15 {
    width: 93.3333333333%;
  }
  .w-xl-1-16 {
    width: 6.25%;
  }
  .w-xl-3-16 {
    width: 18.75%;
  }
  .w-xl-5-16 {
    width: 31.25%;
  }
  .w-xl-7-16 {
    width: 43.75%;
  }
  .w-xl-9-16 {
    width: 56.25%;
  }
  .w-xl-11-16 {
    width: 68.75%;
  }
  .w-xl-13-16 {
    width: 81.25%;
  }
  .w-xl-15-16 {
    width: 93.75%;
  }
  .w-xl-1-20 {
    width: 5%;
  }
  .w-xl-3-20 {
    width: 15%;
  }
  .w-xl-7-20 {
    width: 35%;
  }
  .w-xl-9-20 {
    width: 45%;
  }
  .w-xl-11-20 {
    width: 55%;
  }
  .w-xl-13-20 {
    width: 65%;
  }
  .w-xl-17-20 {
    width: 85%;
  }
  .w-xl-19-20 {
    width: 95%;
  }
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-flex-column {
    display: flex !important;
    flex-direction: column;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-inline-flex-column {
    display: inline-flex !important;
    flex-direction: column;
  }
  .j-xl-flex-start {
    justify-content: flex-start;
  }
  .j-xl-flex-end {
    justify-content: flex-end;
  }
  .j-xl-center {
    justify-content: center;
  }
  .j-xl-space-between {
    justify-content: space-between;
  }
  .j-xl-space-around {
    justify-content: space-around;
  }
  .j-xl-space-evenly {
    justify-content: space-evenly;
  }
  .a-xl-flex-start {
    align-items: flex-start;
  }
  .as-xl-flex-start {
    align-self: flex-start;
  }
  .a-xl-flex-end {
    align-items: flex-end;
  }
  .as-xl-flex-end {
    align-self: flex-end;
  }
  .a-xl-center {
    align-items: center;
  }
  .as-xl-center {
    align-self: center;
  }
  .a-xl-baseline {
    align-items: baseline;
  }
  .as-xl-baseline {
    align-self: baseline;
  }
  .a-xl-stretch {
    align-items: stretch;
  }
  .as-xl-stretch {
    align-self: stretch;
  }
  .a-xl-self-start-self-end {
    align-items: self-start-self-end;
  }
  .as-xl-self-start-self-end {
    align-self: self-start-self-end;
  }
}
.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.modal.open {
  display: block;
}
.modal > .modal-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.modal > .modal-content-outer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  pointer-events: auto;
}
.modal > .modal-content-outer > .modal-content-middle {
  display: table;
  width: 100%;
  height: 100%;
}
.modal > .modal-content-outer > .modal-content-middle > .modal-content-inner {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
}
.modal > .modal-content-outer > .modal-content-middle > .modal-content-inner > * {
  pointer-events: auto;
}

.accordion > .accordion-header {
  cursor: pointer;
}
.accordion > .accordion-panel {
  display: none;
}
.accordion.open > .accordion-panel {
  display: block;
}

.menu > *, .menu .btn {
  display: block;
  width: 100%;
}
.menu.w-auto {
  display: inline-flex;
  flex-direction: column;
}

.enable-redacted .redact,
.enable-redacted .redact2,
.enable-redacted .redact3 {
  color: transparent;
  background-image: url("./images/icons/redact.png");
  background-repeat: repeat-x;
  background-position-y: 4px;
  transition: 0.1s ease-in-out;
  pointer-events: auto;
}
.enable-redacted .redact {
  background-size: 100% 70%;
}
.enable-redacted .redact2 {
  background-size: 200% 70%;
}
.enable-redacted .redact3 {
  background-size: 300% 70%;
}
.enable-redacted .redact-header {
  color: transparent;
  background-image: url("./images/icons/redact.png");
  background-repeat: repeat-x;
  background-size: 350% 85%;
  background-position-y: 4px;
  transition: 0.1s ease-in-out;
  pointer-events: auto;
}
.enable-redacted .redact-white,
.enable-redacted .redact-white2,
.enable-redacted .redact-white3 {
  color: transparent;
  background-image: url("./images/icons/redact-white.png");
  background-repeat: repeat-x;
  background-position-y: 4px;
  transition: 0.1s ease-in-out;
  pointer-events: auto;
}
.enable-redacted .redact-white {
  background-size: 100% 70%;
}
.enable-redacted .redact-white2 {
  background-size: 200% 70%;
}
.enable-redacted .redact-white3 {
  background-size: 300% 70%;
}
.enable-redacted .redact-yellow,
.enable-redacted .redact-yellow2,
.enable-redacted .redact-yellow3 {
  color: transparent;
  background-image: url("./images/icons/redact-yellow.png");
  background-repeat: repeat-x;
  background-position-y: 4px;
  transition: 0.1s ease-in-out;
  pointer-events: auto;
}
.enable-redacted .redact-yellow {
  background-size: 100% 70%;
}
.enable-redacted .redact-yellow2 {
  background-size: 200% 70%;
}
.enable-redacted .redact-yellow3 {
  background-size: 300% 70%;
}
.enable-redacted .redact-yellow-header {
  color: transparent;
  background-image: url("./images/icons/redact-yellow.png");
  background-repeat: repeat-x;
  background-size: 350% 85%;
  background-position-y: 4px;
  transition: 0.1s ease-in-out;
  pointer-events: auto;
}
.enable-redacted .redact:hover,
.enable-redacted .redact2:hover,
.enable-redacted .redact3:hover {
  color: #000000;
  transition: 0.1s ease-in-out;
  background-image: none;
}
.enable-redacted .redact-header:hover {
  color: #000000;
  transition: 0.1s ease-in-out;
  background-image: none;
}
.enable-redacted .redact-yellow-header:hover {
  color: #FFC425;
  transition: 0.1s ease-in-out;
  background-image: none;
}
.enable-redacted .redact-white:hover,
.enable-redacted .redact-white2:hover,
.enable-redacted .redact-white3:hover {
  color: #FFFFFF;
  transition: 0.1s ease-in-out;
  background-image: none;
}
.enable-redacted .redact-yellow:hover,
.enable-redacted .redact-yellow2:hover,
.enable-redacted .redact-yellow3:hover {
  color: #FFC425;
  transition: 0.1s ease-in-out;
  background-image: none;
}

@keyframes slidein {
  from {
    display: none;
    margin-bottom: -100%;
  }
  to {
    display: block;
    margin-bottom: 0%;
  }
}
#rm-slider {
  display: block;
  width: 350px;
  margin: 0 auto;
  height: 60px;
  border-radius: 30px;
  background: white;
  padding-left: 30px;
  padding-right: 30px;
  position: relative;
}
@media only screen and (max-width : 575px) {
  #rm-slider {
    width: 200px;
    height: 40px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
#rm-slider:after {
  content: "";
  display: block;
  position: absolute;
  background: #808080;
  top: 0;
  bottom: 0;
  right: 0;
  width: 31px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}
@media only screen and (max-width : 575px) {
  #rm-slider:after {
    width: 21px;
  }
}

.rm-slider-track {
  display: block;
  position: relative;
  height: 60px;
}
@media only screen and (max-width : 575px) {
  .rm-slider-track {
    height: 40px;
  }
}

.rm-slider-track-before {
  position: absolute;
  top: 0;
  left: 0;
  height: 60px;
}
@media only screen and (max-width : 575px) {
  .rm-slider-track-before {
    height: 40px;
  }
}

.rm-slider-track > .dot-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.rm-slider-track > .dot-container .dot {
  margin-top: -4px;
  width: 10px;
  height: 10px;
  background: #FFC425;
  border: 1px solid #FFC425;
  border-radius: 20px;
  transition: 0.5s ease;
  z-index: 1;
}
.rm-slider-track > .dot-container .dot:hover {
  cursor: pointer;
  background-color: #FFF;
  border: none;
}

.rm-slider.rm-slider-vertical .rm-slider-track-before {
  height: auto;
  width: 2px;
}

.rm-slider-track-after {
  position: absolute;
  top: 0;
  right: 0;
  height: 60px;
  background: #808080;
}
@media only screen and (max-width : 575px) {
  .rm-slider-track-after {
    height: 40px;
  }
}

.rm-slider.rm-slider-vertical .rm-slider-track-after {
  height: auto;
  width: 2px;
  top: auto;
  bottom: 0;
}

.rm-slider-handle {
  position: absolute;
  top: 0;
  width: 60px;
  height: 60px;
  border-radius: 50px;
  background-color: white;
  z-index: 2;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.45);
  transform: translate(-50%, 0);
}
@media only screen and (max-width : 575px) {
  .rm-slider-handle {
    height: 40px;
    width: 40px;
  }
}

.rm-slider-handle:hover {
  cursor: pointer;
}

.rm-slider-handle:focus {
  outline: 0;
  border: 1px solid white;
}

.rm-slider-handle::after {
  content: attr(slider-value);
  margin-top: 37px;
  display: block;
  color: #FFF;
}
@media only screen and (max-width : 575px), only screen and (min-width : 576px) and (max-width : 767px) {
  .rm-slider-handle::after {
    margin-top: 5px;
    margin-left: 50px;
  }
}

@media only screen and (max-width : 575px), only screen and (min-width : 576px) and (max-width : 767px) {
  .slider-wrapper {
    display: flex;
    max-width: 90px;
    justify-content: center;
    margin: 0 auto;
  }
  .slider-wrapper .rm-slider {
    -ms-transform: rotate(90deg);
    margin: 0 auto;
    position: relative;
    display: inline-flex;
    padding: 10px 10px;
    -ms-flex-direction: column;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    left: 50%;
    transform: translate(-50%, 0%);
  }
  .slider-wrapper .rm-slider-track {
    display: block;
    /*position: absolute;
    height: 2px;*/
    background: #FFC425;
    height: 400px;
    width: 2px;
    -ms-flex-positive: 1;
    flex-grow: 1;
    margin: 0 auto;
  }
  .slider-wrapper .rm-slider-track .dot-container {
    display: flex;
    width: 100%;
    -ms-flex-pack: justify;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
  }
  .slider-wrapper .rm-slider-track .dot-container .dot {
    margin-left: -4px;
  }
  .slider-wrapper #slider-values {
    justify-content: space-between;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    height: 424px;
    padding-left: 0;
    position: relative;
    left: 35px;
    top: -9px;
  }
}
.transcript-dialog {
  margin: auto;
  max-width: 1060px;
  border: 3px solid black;
  border-radius: 15px;
  overflow: hidden;
  background: #FFC425;
  position: relative;
}

.transcript-dialog .body {
  padding: 0px 15px;
}

.transcript-dialog h1 {
  background: black;
  color: white;
  font-size: 1.5rem;
  padding: 5px 10px;
}

.transcript-dialog p {
  margin: 10px 0;
}

.transcript-dialog .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  color: white;
  height: 38px;
  width: 38px;
  font-size: 2rem;
  line-height: 2rem;
  padding-bottom: 2px;
}

.personalization-main {
  /* buttons */
  /*type*/
  /*select dropdown*/
  /*contact*/
  /*Checkboxes*/
  /*constant variables*/
  /*modifiable variables*/
  /*checkboxes columns*/
}
.personalization-main .material_div {
  position: relative;
}
.personalization-main .material_field {
  position: absolute;
  font-size: 13px;
  color: #b9b9b9;
  top: 6px;
  left: 11px;
  transition: 0.05s ease-in-out;
}
@media only screen and (min-width : 768px) {
  .personalization-main .material_field {
    left: 17px;
    font-size: 10px;
  }
}
.personalization-main .material_field_placeholder {
  position: absolute;
  font-size: 11px;
  color: #b9b9b9;
  top: 20px;
  left: 11px;
  transition: 0.05s ease-in-out;
}
@media only screen and (min-width : 768px) {
  .personalization-main .material_field_placeholder {
    left: 17px;
    font-size: 15px;
  }
}
.personalization-main .no-label {
  transition: 0.05s ease-in-out;
  visibility: hidden;
}
.personalization-main .button-holder {
  position: absolute;
  right: 25px;
  top: 25px;
  width: 60px;
}
.personalization-main .button-holder .canvas-site {
  position: absolute;
  right: 40px;
  top: -78px;
  width: 175px;
}
@media only screen and (max-width : 575px) {
  .personalization-main .button-holder .canvas-site {
    top: -60px;
    right: 0px;
  }
}
@media only screen and (max-width : 575px) {
  .personalization-main .button-holder .canvas-site canvas {
    width: 200px;
  }
}
.personalization-main .button-holder .pointer path {
  stroke-dasharray: 50;
  stroke-dashoffset: -50;
  animation: draw 3s forwards;
}
.personalization-main .button-holder .pointer:hover {
  opacity: 1;
}
.personalization-main .button-holder .pointer .point-arrow {
  position: absolute;
  width: 95px;
  top: 10px;
  height: 60px;
  right: 50px;
  z-index: 1;
}
@media only screen and (max-width : 575px) {
  .personalization-main .button-holder .pointer .point-arrow {
    right: 20px;
    top: -12px;
  }
}
.personalization-main .button-holder .disappear {
  opacity: 0;
  transition: 0.5s ease-in-out;
  transition-delay: 2s;
}
.personalization-main .button-holder .post-load {
  opacity: 0;
  transition: 0.5s ease-in-out;
  transition-delay: none;
}
.personalization-main .button-holder .post-load .canvas-site {
  opacity: 0;
  transition: 0.5s ease-in-out;
  overflow-x: hidden;
}
.personalization-main .button-holder .customize {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 20px;
  top: -7px;
  margin: 5px;
  cursor: pointer !important;
  z-index: 7;
  transition: 0.5s ease-in-out;
}
@media only screen and (max-width : 575px) {
  .personalization-main .button-holder .customize {
    top: 0px;
  }
}
.personalization-main .button-holder .customize:hover {
  -webkit-transform: scale(1.1);
  -webkit-transform-origin: center;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transform: scale(1.1);
  -moz-transform-origin: center;
  -moz-transition: 0.5s ease-in-out;
  transform: scale(1.1);
  transform-origin: center;
  transition: 0.5s ease-in-out;
}
@media only screen and (min-width : 576px) {
  .personalization-main .button-holder .customize {
    width: 50px;
    height: 50px;
    left: 25px;
    top: 0px;
    margin: 0;
  }
}
@media only screen and (min-width : 768px) {
  .personalization-main .button-holder .customize {
    width: 60px;
    height: 60px;
    left: 5px;
  }
}
@media only screen and (min-width : 992px) {
  .personalization-main .button-holder .customize {
    width: 60px;
    height: 60px;
    left: 0;
  }
}
.personalization-main .button-holder:hover .pointer {
  opacity: 1;
}
.personalization-main .button-holder:hover .pointer .canvas-site {
  opacity: 1;
}
.personalization-main .button-holder:hover .pointer.post-load.hover-off {
  opacity: 0;
}
.personalization-main .personalization-quiz {
  box-sizing: border-box;
  position: absolute;
  z-index: 6;
  top: 40px;
  right: 50px;
  min-height: 120px;
  background: rgba(26, 26, 26, 0.9);
  animation: quiz 0.5s ease;
  color: white;
  padding: 10px 16px;
  max-height: 520px;
  display: none;
  /* height: 360px;
  width: 230px;*/
  height: 395px;
  width: 252px;
}
@media only screen and (min-width : 320px) {
  .personalization-main .personalization-quiz {
    top: 25px;
    right: 25px;
  }
}
@media only screen and (min-width : 576px) {
  .personalization-main .personalization-quiz {
    right: 35px;
    top: 35px;
    padding: 20px 16px;
  }
}
@media only screen and (min-width : 768px) {
  .personalization-main .personalization-quiz {
    top: 40px;
    right: 43px;
    width: 271px;
    padding: 20px 16px;
  }
}
@media only screen and (min-width : 992px) {
  .personalization-main .personalization-quiz {
    top: 70px;
    right: 75px;
    width: 271px;
  }
}
.personalization-main .one {
  margin: 0 auto;
}
.personalization-main .interests {
  overflow: auto;
  max-height: 350px;
}
.personalization-main .floating {
  position: fixed;
  bottom: 20px;
  right: 0;
  text-align: left;
  width: 100vw;
  height: 60px;
  color: black;
  background-color: #FFC425;
  cursor: pointer;
  box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.4);
  padding: 0;
}
@media only screen and (min-width : 576px) {
  .personalization-main .floating {
    bottom: 8px;
    width: 100vw;
    text-align: left;
  }
}
@media only screen and (min-width : 768px) {
  .personalization-main .floating {
    bottom: 8px;
    width: 100vw;
    text-align: left;
  }
}
@media only screen and (min-width : 992px) {
  .personalization-main .floating {
    bottom: 8px;
    width: 320px;
    text-align: left;
    padding: 0;
  }
}
.personalization-main .floating .card-container {
  width: 100%;
  margin-left: 10%;
  height: inherit;
}
@media only screen and (min-width : 576px) {
  .personalization-main .floating .card-container {
    margin-left: 25%;
  }
}
@media only screen and (min-width : 768px) {
  .personalization-main .floating .card-container {
    margin-left: 30%;
  }
}
@media only screen and (min-width : 992px) {
  .personalization-main .floating .card-container {
    margin-left: 5%;
  }
}
.personalization-main .floating .left {
  float: left;
  width: 60%;
}
@media only screen and (min-width : 576px) {
  .personalization-main .floating .left {
    width: 40%;
  }
}
@media only screen and (min-width : 768px) {
  .personalization-main .floating .left {
    width: 32%;
  }
}
@media only screen and (min-width : 992px) {
  .personalization-main .floating .left {
    width: 75%;
  }
}
.personalization-main .floating .left h2 {
  font-size: 20px;
  color: black;
  padding: 5px 0 5px 0px;
}
@media only screen and (min-width : 768px) {
  .personalization-main .floating .left h2 {
    padding: 5px 0 0 5px;
  }
}
.personalization-main .floating .right {
  width: 25%;
  float: left;
}
@media only screen and (min-width : 768px) {
  .personalization-main .floating .right {
    width: 40%;
  }
}
@media only screen and (min-width : 992px) {
  .personalization-main .floating .right {
    width: 25%;
  }
}
.personalization-main .floating .right .card {
  position: absolute;
  width: 65px;
  height: 65px;
  border-radius: 80px;
  background-color: #FFC425;
  top: -10px;
  text-align: center;
}
.personalization-main .floating .right .card img {
  width: 95%;
}
.personalization-main .floating .text {
  color: black;
  padding: 5px 0 0 0px;
}
@media only screen and (min-width : 768px) {
  .personalization-main .floating .text {
    padding: 5px 0 0 5px;
  }
}
.personalization-main .button {
  background-color: #FFC425;
  color: black;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 1px;
  padding: 10px 30px;
  border: 1px solid #000000;
  border-radius: 10px;
  text-align: center;
  transition: background-color 0.5s ease;
  display: inline-block;
  margin: 15px auto;
}
@media only screen and (max-width : 575px), only screen and (min-width : 576px) and (max-width : 767px) {
  .personalization-main .button {
    font-size: 12px;
  }
}
.personalization-main .button:hover {
  background-color: #000000;
  border: 1px solid #FFC425;
  border-style: inset;
  color: #FFC425;
  transition: background-color 0.5s ease;
}
.personalization-main .button .chevron {
  color: #000000;
  top: 6px;
  left: 10px;
}
.personalization-main .button .chevron:hover {
  color: #FFC425;
}
.personalization-main .button.two {
  margin: 25px 10px 10px 10px;
}
.personalization-main .button.contact {
  margin: 5px;
  width: 175px;
  height: 35px;
  padding: 2px 22px;
  text-align: center;
  font-size: 12px;
}
@media only screen and (min-width : 992px) {
  .personalization-main .button.contact {
    margin: 25px 10px 10px 10px;
    width: 175px;
    height: 35px;
    padding: 2px 22px;
    text-align: center;
    font-size: 16px;
  }
}
.personalization-main .button a {
  text-decoration: none;
  color: black;
}
.personalization-main .button a:hover {
  color: #FFC425;
}
.personalization-main .button:hover .chevron {
  color: #FFC425;
}
.personalization-main .button:hover a {
  color: #FFC425;
}
.personalization-main h2 {
  font-size: 15px;
  color: #FFC425;
  padding: 10px 0 0 0;
}
@media only screen and (min-width : 768px) {
  .personalization-main h2 {
    font-size: 20px;
  }
}
.personalization-main h3 {
  font-size: 18px;
  color: #FFC425;
  padding: 5px 0 0 0;
}
@media only screen and (min-width : 576px) {
  .personalization-main h3 {
    color: #FFC425;
    padding: 5px 0 0 0;
  }
}
@media only screen and (min-width : 992px) {
  .personalization-main h3 {
    font-size: 30px;
    color: #FFC425;
    padding: 5px 0 0 0;
  }
}
.personalization-main .copy {
  margin-left: 10px;
  text-align: center;
}
.personalization-main .am-email {
  font-size: 14px;
}
@media only screen and (min-width : 992px) {
  .personalization-main .am-email {
    font-size: 18px;
  }
}
.personalization-main .am-phone {
  font-size: 14px;
}
@media only screen and (min-width : 992px) {
  .personalization-main .am-phone {
    font-size: 18px;
  }
}
.personalization-main p {
  font-size: 1em;
  color: white;
  padding: 15px 0 10px 0;
}
.personalization-main a {
  text-decoration: none;
  color: white;
}
.personalization-main a:hover {
  color: #FFC425;
}
.personalization-main select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  color: white;
  height: 40px;
  border-bottom: 1px solid white;
  padding: 0 10px 0 5px;
  margin-top: 17px;
  cursor: pointer;
  background-image: url(images/icons/arrow-down.png);
  background-repeat: no-repeat;
  background-position: right;
  font-size: 16px;
  width: 208px;
}
@media only screen and (min-width : 576px) {
  .personalization-main select {
    width: 208px;
  }
}
@media only screen and (min-width : 768px) {
  .personalization-main select {
    width: 215px;
    font-size: 15px;
    margin-top: 10px;
  }
}
.personalization-main select option {
  background-color: black;
  cursor: pointer;
}
.personalization-main select option:active {
  background-color: #FFC425;
  color: black;
}
.personalization-main select option:focus {
  background-color: #FFC425;
  color: black;
}
.personalization-main .select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  color: white;
  height: 40px;
  border-bottom: 1px solid white;
  padding: 0 10px 0 5px;
  margin-top: 17px;
  cursor: pointer;
  font-size: 16px;
  width: 208px;
}
@media only screen and (min-width : 576px) {
  .personalization-main .select {
    width: 185px;
    font-size: 15px;
  }
}
@media only screen and (min-width : 768px) {
  .personalization-main .select {
    width: 215px;
  }
}
.personalization-main .select option {
  background-color: black;
  cursor: pointer;
}
.personalization-main .select option:active {
  background-color: #FFC425;
  color: black;
}
.personalization-main .select option:focus {
  background-color: #FFC425;
  color: black;
}
.personalization-main .first:focus + .submenu,
.personalization-main .submenu:hover {
  display: block;
}
.personalization-main select::-ms-expand {
  display: none;
}
.personalization-main input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.personalization-main input[type=number] {
  -moz-appearance: textfield;
}
.personalization-main input[type=number]::-webkit-inner-spin-button,
.personalization-main input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.personalization-main .type-in {
  background: transparent;
  color: white;
  width: 208px;
  height: 40px;
  border-bottom: 1px solid white;
  padding: 0 10px 0 5px;
  margin-top: 17px;
  cursor: pointer;
  font-size: 16px;
}
.personalization-main .type-in:focus {
  border-bottom: 1px solid #FFC425;
}
@media only screen and (min-width : 576px) {
  .personalization-main .type-in {
    width: 208px;
    height: 40px;
  }
}
@media only screen and (min-width : 768px) {
  .personalization-main .type-in {
    width: 215px;
    font-size: 15px;
    height: 40px;
    margin-top: 10px;
  }
}
.personalization-main textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.personalization-main ::placeholder {
  color: white;
  opacity: 1;
}
.personalization-main .userMessage {
  background: transparent;
  color: white;
  border-bottom: 1px solid white;
  padding: 10px 10px 0 10px;
  margin-top: 10px;
  cursor: pointer;
  width: 260px;
  height: 60px;
  font-size: 13px;
}
.personalization-main .userMessage:focus {
  border-bottom: 2px solid #FFC425;
}
@media only screen and (min-width : 576px) {
  .personalization-main .userMessage {
    width: 285px;
    font-size: 1em;
    height: 90px;
  }
}
.personalization-main .info {
  width: 240px;
  height: auto;
  margin: auto;
}
@media only screen and (min-width : 768px) {
  .personalization-main .info {
    width: 250px;
  }
}
@media only screen and (min-width : 992px) {
  .personalization-main .info {
    width: 300px;
  }
}
.personalization-main .icon {
  margin-top: 5px;
  text-align: left;
}
.personalization-main .icon img {
  vertical-align: top;
  margin-right: 5px;
}
.personalization-main .products {
  box-sizing: border-box;
}
.personalization-main .text {
  font-size: 13px;
}
@media only screen and (min-width : 576px) {
  .personalization-main .text {
    font-size: 16px;
  }
}
.personalization-main .rm-checkbox {
  display: inline-block;
  text-align: left;
}
.personalization-main .rm-checkbox input {
  position: absolute;
  width: 0px;
  height: 0px;
  opacity: 0;
}
.personalization-main .rm-checkbox .overlay {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  border-radius: 20px;
}
.personalization-main .rm-checkbox label {
  display: block;
}
.personalization-main .rm-checkbox input + label .overlay:before {
  position: absolute;
  content: "";
  -webkit-transform: scale(0);
  transform: scale(0);
}
.personalization-main .rm-checkbox input:checked + label .overlay:before {
  -webkit-transform: scale(1);
  transform: scale(1);
}
.personalization-main checked {
  display: none;
}
.personalization-main .rm-checkbox .overlay {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border: 2px solid white;
}
@media only screen and (min-width : 768px) {
  .personalization-main .rm-checkbox .overlay {
    width: 25px;
    height: 25px;
  }
}
.personalization-main .rm-checkbox label {
  cursor: pointer;
  margin-top: 12px;
}
.personalization-main .rm-checkbox input + label .overlay:before {
  top: 5px;
  left: 5px;
  background: #FFC425;
  width: 15px;
  height: 15px;
  border-radius: 20px;
  -webkit-transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
}
@media only screen and (min-width : 768px) {
  .personalization-main .rm-checkbox input + label .overlay:before {
    width: 10px;
    height: 10px;
  }
}
.personalization-main .rm-checkbox input:focus + label .overlay {
  border: 2px solid #FFC425;
}
.personalization-main .column1,
.personalization-main .column2 {
  float: left;
  width: 100vw;
  text-align: left;
}
@media only screen and (min-width : 576px) {
  .personalization-main .column1,
  .personalization-main .column2 {
    width: 49%;
  }
}
@media only screen and (min-width : 768px) {
  .personalization-main .column1,
  .personalization-main .column2 {
    width: 49%;
  }
}
.personalization-main .avatar {
  transition: 0.5s ease-in-out;
  margin: 0 auto;
}
.personalization-main .avatar img {
  width: 20%;
}
@media only screen and (min-width : 992px) {
  .personalization-main .avatar img {
    width: 25%;
  }
}
@media only screen and (min-width : 576px) {
  .personalization-main .avatar {
    display: block;
  }
}
@media only screen and (min-width : 992px) {
  .personalization-main .avatar {
    width: 80%;
    display: block;
  }
}
.personalization-main .person {
  -webkit-animation: showUp-2 3s ease-in-out;
  -moz-animation: showUp-2 3s ease-in-out;
  animation: showUp-2 3s ease-in-out;
}
.personalization-main .inner-circle {
  -webkit-animation: rotate2 5s ease-in-out infinite;
  -moz-animation: rotate2 5s ease-in-out infinite;
  animation: rotate2 5s ease-in-out infinite;
  transform-origin: center center;
  fill: #FFC425;
}
.personalization-main svg {
  overflow: visible;
}
.personalization-main .hide {
  display: none;
  animation: quiz-gone 0.5s ease;
}
.personalization-main .show {
  display: block;
  text-align: center;
}
@keyframes dash {
  from {
    stroke-dashoffset: 1000;
  }
  to {
    stroke-dashoffset: 0;
  }
}
.personalization-main .suggestion-container {
  position: relative;
  display: inline-block;
}
.personalization-main .suggestion-items {
  position: absolute;
  text-align: left;
  width: 215px;
  font-size: 16px;
  background-color: black;
  color: white;
  border: 1px solid #ffffff;
  border-top: none;
  border-bottom: none;
  z-index: 30;
  top: 100%;
  left: 0px;
  right: 0px;
}
.personalization-main .suggestion-items.filled {
  border-top: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff;
}
.personalization-main .suggestion-items div {
  padding-left: 5px;
  cursor: pointer;
  background-color: black;
}
.personalization-main .suggestion.suggestion-active {
  background-color: #ffc425;
  color: black;
}
.personalization-main .IE-arrow {
  margin-left: -65px;
  margin-top: 15px;
  display: none;
}
.personalization-main .IE-customize {
  display: none;
  height: 42px;
  width: 42px;
  background-color: #ffffff;
  border-radius: 50%;
}
.personalization-main .IE-customize img {
  height: 100%;
  width: 100%;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .personalization-main .IE-arrow {
    display: block;
  }
  .personalization-main .IE-customize {
    display: block;
  }
  .personalization-main .customize-btn {
    display: none;
  }
}

* {
  font-family: "Open Sans", sans-serif;
}

/* PORTFOLIO TEMPLATE */
.template {
  background-color: #1A1A1A;
  padding-top: 10px;
  height: 498px;
  position: relative;
  bottom: 0;
}
@media only screen and (max-width : 575px) {
  .template {
    height: 408px;
  }
}
@media only screen and (min-width : 576px) and (max-width : 767px) {
  .template {
    height: 428px;
  }
}
@media only screen and (min-width : 768px) and (max-width : 991px) {
  .template {
    height: 488px;
  }
}
.template .content {
  max-width: 1100px;
  display: block;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
.template .content .project {
  position: absolute;
}
@media only screen and (min-width : 576px) and (max-width : 767px), only screen and (max-width : 575px), only screen and (min-width : 768px) and (max-width : 991px) {
  .template .content .project {
    max-width: none;
    display: block;
    margin: 0 auto;
    position: inherit;
  }
}
@media only screen and (min-width : 992px) and (max-width : 1199px) {
  .template .content .project {
    margin-left: 45px;
  }
}
.template .content .project .project-title {
  padding-bottom: 15px;
  color: white;
  font-size: 40px;
  font-weight: 500;
}
@media only screen and (max-width : 575px), only screen and (min-width : 576px) and (max-width : 767px) {
  .template .content .project .project-title {
    text-align: center;
    font-size: 25px;
  }
}
@media only screen and (max-width : 575px), only screen and (min-width : 576px) and (max-width : 767px) {
  .template .content .project .project-title {
    font-size: 21px;
    padding-bottom: 5px;
  }
}
@media only screen and (min-width : 768px) and (max-width : 991px) {
  .template .content .project .project-title {
    font-size: 30px;
    padding-bottom: 5px;
    text-align: center;
  }
}
.template .content .project .project-description {
  padding-bottom: 30px;
  max-width: 280px;
  color: white;
  display: block;
}
@media only screen and (min-width : 576px) and (max-width : 767px), only screen and (max-width : 575px), only screen and (min-width : 768px) and (max-width : 991px) {
  .template .content .project .project-description {
    max-width: none;
    text-align: center;
  }
}
@media only screen and (min-width : 576px) and (max-width : 767px), only screen and (max-width : 575px) {
  .template .content .project .project-description {
    font-size: 14px;
  }
}
@media only screen and (min-width : 576px) and (max-width : 767px), only screen and (max-width : 575px), only screen and (min-width : 768px) and (max-width : 991px) {
  .template .content .project .project-description {
    padding-bottom: 10px;
  }
}
@media only screen and (min-width : 768px) and (max-width : 991px) {
  .template .content .project .project-description {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width : 768px) and (max-width : 991px), only screen and (min-width : 576px) and (max-width : 767px), only screen and (max-width : 575px) {
  .template .content .project .yellow-button {
    max-width: 150px;
    display: block;
    margin: 0 auto;
    margin-top: 0;
  }
}

.gradient {
  width: 100%;
  background: linear-gradient(#c6c6c6, #d8d8d8, #ededed, white, white, white, white);
  height: 159px;
  margin-top: -110px;
}
@media only screen and (min-width : 992px) {
  .gradient {
    margin-top: -160px;
  }
}
@media only screen and (max-width : 575px) {
  .gradient {
    height: 163px;
  }
}
@media only screen and (max-width : 575px), only screen and (min-width : 576px) and (max-width : 767px), only screen and (min-width : 768px) and (max-width : 991px) {
  .gradient .client-logo {
    text-align: center;
  }
}
.gradient .client-logo img {
  max-width: 190px;
  margin-top: 80px;
}
@media only screen and (max-width : 575px), only screen and (min-width : 576px) and (max-width : 767px) {
  .gradient .client-logo img {
    margin-left: 0;
    text-align: center;
    margin-top: 85px;
    max-width: 140px;
  }
}
@media only screen and (max-width : 575px), only screen and (min-width : 576px) and (max-width : 767px), only screen and (min-width : 768px) and (max-width : 991px) {
  .gradient .client-logo img {
    display: block;
    margin: 0 auto;
    margin-top: 110px;
  }
}
@media only screen and (min-width : 768px) and (max-width : 991px) {
  .gradient .client-logo img {
    margin-top: 118px;
    max-width: 140px;
  }
}
@media only screen and (max-width : 575px) {
  .gradient .client-logo img {
    margin-top: 120px;
  }
}
@media only screen and (min-width : 576px) and (max-width : 767px) {
  .gradient .client-logo img {
    margin-top: 121px;
    max-width: 130px;
  }
}
@media only screen and (min-width : 992px) and (max-width : 1199px) {
  .gradient .client-logo img {
    margin-left: 45px;
  }
}

/* BUTTONS*/
.yellow-button {
  background-color: #FFC425;
  color: black;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 1px;
  padding: 10px 25px;
  border: 1px solid #FFC425;
  border-radius: 10px;
  text-align: center;
  transition: background-color 0.5s ease;
  display: inline-block;
}
.yellow-button:hover {
  background-color: #000000;
  border: 1px solid #FFC425;
  border-style: inset;
  color: #FFC425;
  transition: background-color 0.5s ease;
}
@media only screen and (max-width : 575px), only screen and (min-width : 576px) and (max-width : 767px) {
  .yellow-button {
    margin: 0 auto;
  }
}
@media only screen and (max-width : 575px) {
  .yellow-button {
    padding: 5px 10px 5px 10px;
    font-size: 14px;
  }
}
.yellow-button.careers-btn {
  text-align: left;
  margin: 0px;
  margin-left: 17.5px;
}
@media only screen and (min-width : 768px) and (max-width : 991px) {
  .yellow-button.careers-btn {
    margin-left: 0;
    font-size: 15px;
  }
}
.yellow-button a {
  color: #000000;
  text-decoration: none;
}

.project .yellow-button {
  display: inline-block;
  min-width: 161px;
}

.chevron {
  border-style: solid;
  border-width: 0.05em 0.05em 0 0;
  content: "";
  display: inline-block;
  height: 0.55em;
  left: 0.15em;
  position: relative;
  top: 0.25em;
  transform: rotate(-45deg);
  vertical-align: top;
  width: 0.55em;
  color: #000000;
}
@media only screen and (max-width : 575px) {
  .chevron {
    top: 0.45em;
  }
}
.chevron:hover {
  color: #FFC425;
}

.chevron-video {
  border-style: solid;
  border-width: 0.05em 0.05em 0 0;
  content: "";
  display: inline-block;
  height: 0.6em;
  left: 0.15em;
  position: relative;
  top: 0.25em;
  transform: rotate(-45deg);
  vertical-align: top;
  width: 0.6em;
  color: #000000;
}
@media only screen and (max-width : 575px) {
  .chevron-video {
    top: 0.45em;
  }
}
.chevron-video:hover {
  color: #FFC425;
}

.chevron-learn-more {
  transform: rotate(135deg);
  top: 0.3em;
}

.chevron.right {
  left: 0;
  transform: rotate(45deg);
}
.chevron.right:hover {
  color: #FFC425;
}

.chevron.down {
  left: 0;
  transform: rotate(135deg);
}

.yellow-button:hover a {
  color: #FFC425;
}

.yellow-button:hover .chevron {
  color: #FFC425;
}

/* PORTFOLIO CAROUSEL */
.carousel-left {
  display: inline-block;
  position: absolute;
  top: 40%;
  margin-left: 25px;
  z-index: 50;
}
@media only screen and (min-width : 768px) and (max-width : 991px) {
  .carousel-left {
    margin-left: 15px;
  }
}
@media only screen and (max-width : 575px), only screen and (min-width : 576px) and (max-width : 767px) {
  .carousel-left {
    display: none;
  }
}

.carousel-right {
  display: inline-block;
  position: absolute;
  top: 40%;
  right: 0;
  margin-right: 25px;
  z-index: 50;
}
@media only screen and (min-width : 768px) and (max-width : 991px) {
  .carousel-right {
    margin-right: 15px;
  }
}
@media only screen and (max-width : 575px), only screen and (min-width : 576px) and (max-width : 767px) {
  .carousel-right {
    display: none;
  }
}

.arrow::before {
  border-style: solid;
  border-width: 0.25em 0.25em 0 0;
  content: "";
  display: inline-block;
  height: 1.45em;
  left: 0.15em;
  position: relative;
  top: 0.15em;
  transform: rotate(-45deg);
  vertical-align: top;
  width: 1.45em;
  color: #FFC425;
}

.arrow.arrow-left:before {
  left: 0.25em;
  transform: rotate(-135deg);
}

.arrow.arrow-right:before {
  left: 0;
  transform: rotate(45deg);
}

/* PORTFOLIO NAV */
.portfolio-nav {
  max-width: 691px;
  display: block;
  margin: 0 auto;
  padding-top: 15px;
  text-align: center;
}
@media only screen and (max-width : 575px) {
  .portfolio-nav {
    display: none;
  }
}
@media only screen and (min-width : 768px) and (max-width : 991px) {
  .portfolio-nav {
    max-width: 601px;
  }
}
@media only screen and (min-width : 576px) and (max-width : 767px) {
  .portfolio-nav {
    max-width: 556px;
    padding-bottom: 1px;
  }
}
.portfolio-nav a {
  color: #FFC425;
  padding: 20px;
  text-decoration: none;
}
@media only screen and (min-width : 768px) and (max-width : 991px) {
  .portfolio-nav a {
    padding: 10px;
  }
}
@media only screen and (min-width : 576px) and (max-width : 767px) {
  .portfolio-nav a {
    padding: 5px;
    font-size: 15px;
  }
}

/* BANNERS */
.banner {
  width: 100%;
  max-height: 625px;
}
.banner .content {
  max-width: 1100px;
  display: block;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
@media only screen and (min-width : 576px) and (max-width : 767px), only screen and (max-width : 575px) {
  .banner .text {
    text-align: center;
  }
}
.banner .text .title {
  font-size: 50px;
  font-weight: 600;
}
@media only screen and (min-width : 768px) and (max-width : 991px) {
  .banner .text .title {
    padding-top: 80px;
    font-size: 35px;
  }
}
@media only screen and (min-width : 992px) and (max-width : 1199px) {
  .banner .text .title {
    font-size: 46px;
  }
}
@media only screen and (min-width : 992px) {
  .banner .text .title {
    padding-top: 190px;
  }
}
@media only screen and (min-width : 576px) and (max-width : 767px), only screen and (max-width : 575px) {
  .banner .text .title {
    font-size: 30px;
  }
}
.banner .text .copy {
  font-size: 20px;
  margin-top: 60px;
  margin-bottom: 80px;
}
@media only screen and (min-width : 576px) and (max-width : 767px), only screen and (max-width : 575px) {
  .banner .text .copy {
    font-size: 14px;
    margin-top: 30px;
    margin-bottom: 40px;
  }
}
@media only screen and (min-width : 768px) and (max-width : 991px) {
  .banner .text .copy {
    margin-top: 40px;
    margin-bottom: 60px;
  }
}
.banner .buttons {
  padding-bottom: 144px;
}
@media only screen and (min-width : 576px) and (max-width : 767px), only screen and (max-width : 575px) {
  .banner .buttons {
    padding-bottom: 45px;
  }
}
.banner .buttons .yellow-button {
  border: 1px solid #000000;
  /*&:first-child {
      margin-right:30px;
      @media only screen and (max-width : 575px), only screen and (min-width : 576px) and (max-width : 767px) {
          margin-right:0;
      }
      @media only screen and (min-width : 768px) and (max-width : 991px) {
          margin-right:15px;
      }
  }*/
}
.banner .buttons .black-button:hover {
  background-color: #FFC425;
  color: #000000;
  border: 1px solid #FFC425;
}
.banner .buttons .black-button:hover .chevron {
  color: #000000;
}
.banner .buttons .selected {
  background-color: #000000;
  color: white;
}
.banner .buttons .selected .chevron {
  color: white;
}
.banner .buttons .selected .chevron.selected {
  transform: rotate(315deg);
  top: 0.5em;
}
.banner .buttons-yellow .selected {
  background-color: #FFC425;
  color: #000000;
}
.banner .buttons-yellow .selected .chevron {
  color: #000000;
}
.banner .buttons-yellow .selected .chevron.selected {
  color: #000000;
  top: 0.5em;
}
.banner .buttons-yellow .selected .chevron-learn-more {
  color: #000000;
  left: 0;
}
.banner .yellow-banner {
  background: radial-gradient(circle, #FCCB5E, #FFC425, #FFC425, #FFC425, #FFC425, #FFC425);
  width: 100%;
}
.banner .yellow-banner h2 {
  color: #000000;
}
.banner .yellow-banner p {
  color: #000000;
}
.banner .black-banner {
  background-color: #1A1A1A;
}
.banner .black-banner h2 {
  color: #FFC425;
  font-size: 50px;
}
@media only screen and (min-width : 992px) {
  .banner .black-banner h2 {
    padding-top: 190px;
  }
}
.banner .black-banner p {
  color: #FFF;
  font-size: 20px;
}

.banner .black-banner .buttons .selected {
  background-color: #FFC425;
  color: #000000;
}
.banner .black-banner .buttons .selected .chevron {
  color: #000000;
  transform: rotate(135deg);
  top: 0.3em;
}
.banner .black-banner .buttons .selected .chevron.selected {
  transform: rotate(-45deg);
  top: 0.5em;
}
.banner .black-banner .buttons .right-button {
  background-color: #000000;
  color: #FFF;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 1px;
  padding: 10px 25px;
  border: 1px solid #FFF;
  border-radius: 10px;
  text-align: center;
  transition: background-color 0.5s ease;
  display: inline-block;
  margin-left: 20px;
}
@media only screen and (max-width : 575px) {
  .banner .black-banner .buttons .right-button {
    padding: 5px 10px 5px 10px;
    font-size: 14px;
  }
}
@media only screen and (max-width : 575px) {
  .banner .black-banner .buttons .right-button {
    margin-left: 5px;
  }
}
@media only screen and (min-width : 768px) and (max-width : 991px) {
  .banner .black-banner .buttons .right-button {
    margin-left: 10px;
  }
}
.banner .black-banner .buttons .right-button:hover {
  background-color: #FFC425;
  border: 1px solid #000000;
  border-style: inset;
  color: #000000;
  transition: background-color 0.5s ease;
}
.banner .black-banner .buttons .right-button .chevron {
  color: #FFF;
}
.banner .black-banner .buttons .right-button .chevron.selected {
  transform: rotate(135deg);
}
.banner .black-banner .buttons .right-button:hover .chevron {
  color: #000000;
}
.banner .yellow-banner .buttons .selected {
  background-color: #000000;
  color: #FFF;
}
.banner .yellow-banner .buttons .selected .chevron {
  color: #FFF;
  transform: rotate(135deg);
  top: 0.3em;
}
.banner .yellow-banner .buttons .selected .chevron.selected {
  transform: rotate(-45deg);
  top: 0.5em;
}
.banner .yellow-banner .buttons .right-button {
  background-color: #FFC425;
  color: #000000;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 1px;
  padding: 10px 25px;
  border: 1px solid #000000;
  border-radius: 10px;
  text-align: center;
  transition: background-color 0.5s ease;
  display: inline-block;
  margin-left: 20px;
}
@media only screen and (max-width : 575px) {
  .banner .yellow-banner .buttons .right-button {
    padding: 5px 10px 5px 10px;
    font-size: 14px;
  }
}
@media only screen and (max-width : 575px) {
  .banner .yellow-banner .buttons .right-button {
    margin-left: 5px;
  }
}
@media only screen and (min-width : 768px) and (max-width : 991px) {
  .banner .yellow-banner .buttons .right-button {
    margin-left: 10px;
  }
}
.banner .yellow-banner .buttons .right-button:hover {
  background-color: #000000;
  border: 1px solid #FFC425;
  border-style: inset;
  color: #FFC425;
  transition: background-color 0.5s ease;
}
.banner .yellow-banner .buttons .right-button .chevron {
  color: #000000;
}
.banner .yellow-banner .buttons .right-button .chevron.selected {
  transform: rotate(135deg);
}
.banner .yellow-banner .buttons .right-button:hover .chevron {
  color: #FFC425;
}

.pointer-focus :focus {
  outline: 0;
}

.rm-loader {
  display: none;
}
.rm-loader.full {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.rm-loader.show {
  display: inline-flex;
}
.rm-loader > * {
  border: 5px solid rgba(255, 255, 255, 0.3);
  border-top: 5px solid #FFC425;
  border-radius: 50%;
  animation: spinner 750ms linear infinite;
  margin: auto;
}

.rm-play-btn {
  display: none;
}
@media only screen and (max-width : 575px) {
  .rm-play-btn svg {
    width: 50px;
    height: 50px;
  }
}
@media only screen and (min-width : 576px) and (max-width : 767px) {
  .rm-play-btn svg {
    width: 100px;
    height: 100px;
  }
}
.rm-play-btn.full {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.rm-play-btn.show {
  display: inline-flex;
}
.rm-play-btn > * {
  margin: auto;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.delay1 {
  opacity: 0.01;
  animation-delay: 0.5s;
}

/*animations*/
@-webkit-keyframes pop-in {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
  }
}
@-moz-keyframes pop-in {
  0% {
    opacity: 0;
    -moz-transform: scale(0.5);
  }
  100% {
    opacity: 1;
    -moz-transform: scale(1);
  }
}
@keyframes pop-in {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
@keyframes fadeIn2 {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fadeIn {
  animation-name: fadeIn;
  animation-duration: 1s;
}

@keyframes slideUp {
  0% {
    opacity: 0.8;
    transform: translateY(450px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
.slideUp {
  transform: translateY(50px);
}
.run-animation .slideUp {
  animation-name: slideUp;
  animation-duration: 0.9s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

.hand-phone {
  position: absolute;
  bottom: 0;
  right: 370px;
}

.grow {
  animation: grow 3s forwards;
}

@keyframes grow-height {
  0% {
    height: 0px;
  }
  100% {
    height: 150px;
  }
}
@keyframes grow-width {
  0% {
    width: 0px;
  }
  100% {
    width: 400px;
  }
}
@keyframes grow-width2 {
  0% {
    width: 0px;
  }
  100% {
    width: 100%;
  }
}
@keyframes grow-width3 {
  0% {
    border-bottom: 0px;
  }
  100% {
    border-bottom: 100%;
  }
}
/*icon animation*/
@-webkit-keyframes showUp {
  0% {
    opacity: 0;
    transform: scale(0);
    transform-origin: center;
    fill: #FFC425;
  }
  50% {
    opacity: 0.3;
    transform: scale(2);
    transform-origin: center;
    fill: #FFC425;
  }
  100% {
    opacity: 1;
    transform: scale(1);
    transform-origin: center;
  }
}
@-moz-keyframes showUp {
  0% {
    opacity: 0;
    transform: scale(0);
    transform-origin: center;
    fill: #FFC425;
  }
  50% {
    opacity: 0.3;
    transform: scale(2);
    transform-origin: center;
    fill: #FFC425;
  }
  100% {
    opacity: 1;
    transform: scale(1);
    transform-origin: center;
  }
}
@keyframes showUp {
  0% {
    opacity: 0;
    transform: scale(0);
    transform-origin: center;
    fill: #FFC425;
  }
  50% {
    opacity: 0.3;
    transform: scale(2);
    transform-origin: center;
    fill: #FFC425;
  }
  100% {
    opacity: 1;
    transform: scale(1);
    transform-origin: center;
  }
}
@-webkit-keyframes showUp-2 {
  0% {
    opacity: 0;
    transform: scale(0);
    transform-origin: center;
    fill: #FFC425;
  }
  50% {
    opacity: 0.5;
    transform: scale(1.2);
    transform-origin: center;
    fill: #FFC425;
  }
  100% {
    opacity: 1;
    transform: scale(1);
    transform-origin: center;
  }
}
@-moz-keyframes showUp-2 {
  0% {
    opacity: 0;
    transform: scale(0);
    transform-origin: center;
    fill: #FFC425;
  }
  50% {
    opacity: 0.5;
    transform: scale(1.2);
    transform-origin: center;
    fill: #FFC425;
  }
  100% {
    opacity: 1;
    transform: scale(1);
    transform-origin: center;
  }
}
@keyframes showUp-2 {
  0% {
    opacity: 0;
    transform: scale(0);
    transform-origin: center;
    fill: #FFC425;
  }
  50% {
    opacity: 0.5;
    transform: scale(1.2);
    transform-origin: center;
    fill: #FFC425;
  }
  100% {
    opacity: 1;
    transform: scale(1);
    transform-origin: center;
  }
}
@-webkit-keyframes rotate2 {
  0% {
    opacity: 1;
    transform: rotate(360deg);
    transform-origin: center center;
    fill: #FFC425;
  }
  100% {
    opacity: 1;
    transform: rotate(0deg);
    transform-origin: center center;
    fill: #FFC425;
  }
}
@-moz-keyframes rotate2 {
  0% {
    opacity: 1;
    transform: rotate(360deg);
    transform-origin: center center;
    fill: #FFC425;
  }
  100% {
    opacity: 1;
    transform: rotate(0deg);
    transform-origin: center center center;
    fill: #FFC425;
  }
}
@keyframes rotate2 {
  0% {
    opacity: 1;
    transform: rotate(360deg);
    transform-origin: center center;
    fill: #FFC425;
  }
  100% {
    opacity: 1;
    transform: rotate(0deg);
    transform-origin: center center;
    fill: #FFC425;
  }
}
@-webkit-keyframes draw {
  50% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@-moz-keyframes draw {
  50% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes draw {
  50% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes quiz {
  0% {
    opacity: 0;
    transform: scale(0);
    transform-origin: top right;
  }
  100% {
    opacity: 1;
    transform: scale(1);
    transform-origin: top right;
  }
}
@-moz-keyframes quiz {
  0% {
    opacity: 0;
    transform: scale(0);
    transform-origin: top right;
  }
  100% {
    opacity: 1;
    transform: scale(1);
    transform-origin: top right;
  }
}
@keyframes quiz-gone {
  from {
    opacity: 1;
    transform: scale(1);
    transform-origin: top right;
  }
  to {
    opacity: 0;
    transform: scale(0);
    transform-origin: top right;
  }
}
@keyframes disappear {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes disappear {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes load-appear {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes arrow-scroll {
  0% {
    transform: translateY(0px) rotate(135deg);
  }
  50% {
    transform: translateY(10px) rotate(135deg);
  }
  100% {
    transform: translateY(0px) rotate(135deg);
  }
}
.no-wrap {
  flex-wrap: inherit !important;
}
@media only screen and (min-width : 576px) and (max-width : 767px), only screen and (max-width : 575px) {
  .no-wrap {
    display: block !important;
    flex-wrap: wrap !important;
  }
}

.title-2 {
  padding-bottom: 50px !important;
  margin-top: -50px !important;
}
@media only screen and (max-width: 767px) and (min-width: 576px), only screen and (max-width: 575px) {
  .title-2 {
    font-size: 14px !important;
    margin-top: -70px !important;
    padding-bottom: 40px !important;
  }
}