@rm-comp 's0tIicz21O' {

    @import 'sass/variables';
    @import 'sass/base';
    
    * {
        box-sizing: border-box;
        font-family: 'Open Sans', sans-serif;
        line-height:1.2;
    }

    a.btn:link{
        color: black;
        
        &:hover{
            text-decoration: underline;
        }
    }

    .AMIcon {
        transform: scale(0.9);
        margin-right:5px;
        
        @media #{$md-up} {
            transform: scale(1);
        }
    }
    
.floating {
    display:block;
    position:fixed;
    bottom:0px;
    right:0;
    text-align:left;
    width:100vw;
    height:60px;
    color:black;
    background-color:$yellow;
    cursor:pointer;
    box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.4);
    padding:0;
    transition: 0.5s ease-in-out;
    z-index: 10;
    @media #{$sm-up} {
        width:320px;
        text-align:left;
    }
    @media #{$md-up} {
        width:320px;
        text-align:left;
    }

    @media #{$lg-up} {
        width:295px;
        text-align:left;
        padding:0;
    }
    
    .card-container {
        width:100%;
        margin-left:10%;
        height:inherit;

        @media #{$sm-up} {
            margin-left:10%;
        }

        @media #{$md-up} {
            margin-left: 10%;
        }

        @media #{$lg-up} {
            margin-left:5%;
        }
    }

    .left {
        float:left;
        width:60%;
        @media #{$sm-up} {
            width: 60%;
        }
        @media #{$md-up} {
            width: 60%;
        }
        @media #{$lg-up} {
            width:71%
        }
    }

    .right {
        width:25%;  
        float:left;
        transition: 0.5s ease-in-out;

        @media #{$xs-only} {
            float: right;
            margin-right: 10%;
        }
        
        @media #{$md-up} {
            width:40%;
            transition: 0.5s ease-in-out;
        }

        @media #{$lg-up} {
            width:25%;
            transition: 0.5s ease-in-out;
        }

/*    ACCOUNT MANAGERS AVATAR */

        .card {
            position:absolute;
            width:65px;
            height:65px;
            top:-7px;
            right:10px;
            text-align:center;
            img {
                width:95%;
            }
            .shannon {
                display: none;
            }    
            .kelly {
                display: none;
            } 
        }
    }       
}

    .expand {
        display:block;
        position:fixed;
        bottom:0px;
        right:0;
        text-align:left;
        width:100vw;
        color:black;
        background-color:$yellow;
        cursor:pointer;
        box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.4);
        padding:0;
        transition: 0.5s ease-in-out;
        z-index: 20;
    
     @media #{$sm-up} {
        width:320px;
        text-align:left;
    }


    @media #{$md-up} {
        width:320px;
        text-align:left;
    }

    @media #{$lg-up} {
        width:312px;
        text-align:left;
        padding:0;
    }
    
    .card-container {
        width:100%;
        margin-left:10%;
        height:inherit;

        @media #{$sm-up} {
            margin-left:10%;
        }

        @media #{$md-up} {
            margin-left: 10%;
        }

        @media #{$lg-up} {
            margin-left:5%;
        }
    }

    .left {
        float:left;
        width:60%;
        @media #{$sm-up} {
            width: 60%;
        }
        @media #{$md-up} {
            width: 60%;
        }
        @media #{$lg-up} {
            width:71%
        }
    }

    .right {
        width:25%;  
        float:left;
        transition: 0.5s ease-in-out;

        @media #{$xs-only} {
            float: right;
            margin-right: 10%;
        }
        
        @media #{$md-up} {
            width:40%;
            transition: 0.5s ease-in-out;
        }

        @media #{$lg-up} {
            width:25%;
            transition: 0.5s ease-in-out;
        }

/*    ACCOUNT MANAGERS AVATAR */

        .card {
            img {
                width:95%;
            }
            .shannon {
                display: none;
            }    
             .kelly {
                display: none;
            }      
            position:absolute;
            width:65px;
            height:65px;
            top:-7px;
            right:10px;
            text-align:center;

        }
    }   
}
    
.text {
    color:black;
    padding: 10px 0 0 0px; 
    font-size:12px;
    @media #{$lg-up} {
        padding: 10px 0 0 5px; 
        font-size: 14px;
    }            
}

.left {
    /*    ACCOUNT MANAGERS NAME */
    h2 {
        font-size:20px;
        color:black;
        padding: 0px 0 5px 0px; 
        @media #{$lg-up} {
            padding: 0px 0 10px 5px; 
        }
    }  
}    
    
    .tele-contain {
        @media #{$xs-only} {
        width: 100%;
        margin-left: 10%;
        }
    }
    
    .tele {
        top: 65px;
        font-size: 14px;
        
        @media #{$xs-up} {
            width: 100%;
            margin:0;
        }
    }
    
    .email {
        top: 95px;
        font-size: 14px;
         
        @media #{$xs-up} {
            width: 100%;
            margin:0;
        }
        img {
            margin-top:5px;
        }
    }

    .contact {
        display: flex;
        align-items: center;
        &:hover {
            text-decoration: underline;
        }
        a {
            text-decoration: none;
            color: $dark-gray;
            cursor: pointer;
            @media #{$md-up} {
                font-size: 16px;
                }
            }
        }
    
}