@rm-comp 'AuEe91xBgt' {

    @import 'sass/variables';

    .banner-apps {
        .two-columns {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            @media #{$md-only} {
                padding-top: 60px;
            }
            .title {
                @media #{$md-only} {
                    padding-top: 40px;
                }
            }
            .column1 {
                width: 60%;
                @media #{$sm-only, $xs-only} {
                    text-align: center;
                }
                img {
                    width: 100%;
                    max-width: 820px;
                    padding-top: 60px;
                    @media #{$lg-up} {
                        margin-left: -60px;
                        width: 105%;
                    }
                    @media #{$sm-only, $xs-only} {
                        width: 70%;
                        text-align: center;
                        padding-top: 50px;
                    }
                    @media #{$xs-only} {
                        width: 100%;
                    }
                    @media #{$md-only} {
                        padding-top: 0;
                    }
                }
            }
            .column2 {
                width: 40%;
                h2.black-background {
                    color: #FFC425;
                    /* margin: 0;
                    padding: 0;
                    border: 0;
                    font-size: inherit;
                    vertical-align: baseline;
                    background-image: transparent;
                    line-height:1.2em; */
                }
            }
            .column {
                @media #{$sm-only, $xs-only} {
                    width: 100%;
                }
                @media #{$md-only} {
                    width: 50%;
                }
            }
        }

    }


    .banner {
        .buttons {
            .selected {
                background-color: $black;
                color: white;
                .chevron {
                    color: white;
                }
                .chevron.selected {
                    transform: rotate(135deg);
                }
            }
            .right-button {
                background-color: $yellow;
                color: black;
                text-transform: uppercase;
                text-decoration: none;
                letter-spacing: 1px;
                padding: 10px 25px;
                border: 1px solid $black;
                border-radius: 10px;
                text-align: center;
                transition: background-color 0.5s ease;
                display: inline-block;
                margin-left: 20px;
                @media #{$xs-only} {
                    padding: 5px 10px 5px 10px;
                    font-size: 14px;
                }
                @media #{$xs-only} {
                    margin-left: 5px;
                }
                @media #{$md-only} {
                    margin-left: 10px;
                }
                &:hover {
                    background-color: $black;
                    border: 1px solid $yellow;
                    border-style: inset;
                    color: $yellow;
                    transition: background-color 0.5s ease;
                }
                .chevron {
                    color: $black;
                }
                .chevron.selected {
                    transform: rotate(135deg);
                }
            }
            .right-button:hover .chevron {
                color: $yellow;
            }
        }
    }


    .learn-more {
        background-color: $yellow;
        padding-right: 20px;
        padding-left: 20px;
        .learn-more-content {
            display: flex;
            justify-content: Center;
            flex-wrap: wrap;
            width: 100%;
            max-width: 1100px;
            margin: 0 auto;
            padding-bottom: 90px;
            .column {
                width: 50%;
                max-width: 450px;
                @media #{$xs-only, $sm-only} {
                    width: 100%;
                    max-width: none;
                }
                .item {
                    display: flex;
                    align-items: center;
                    img {
                        width: 100%;
                        max-width: 70px;
                        margin-right: 20px;
                        flex-shrink: 0;
                        -webkit-animation: pop-in 0.5s 0.2s both;
                        -moz-animation: pop-in 0.5s 0.2s both;
                        -ms-animation: pop-in 0.5s 0.2s both;
                        animation-delay: 0.7s;
                    }
                    p {
                        -webkit-animation: fadeIn 1s 0.2s both;
                        -moz-animation: fadeIn 1s 0.2s both;
                        -ms-animation: fadeIn 1s 0.2s both;
                        animation-delay: 0.8s;
                    }
                }
                .line {
                    width: 100%;
                    height: 1px;
                    background-color: $black90;
                    margin-top: 20px;
                    margin-bottom: 20px;
                    animation: grow-width2 0.5s 0.2s both;
                    animation-delay: 0.6s;
                }
                .line-mobile {
                    width: 100%;
                    height: 1px;
                    background-color: $black90;
                    margin-top: 20px;
                    margin-bottom: 20px;
                    animation: grow-width2 0.5s 0.2s both;
                    animation-delay: 0.6s;
                    @media #{$md-up} {
                        opacity: 0;
                    }
                }
                .hide {
                    @media #{$xs-only, $sm-only} {
                        display: none;
                    }
                }
            }
            .column1 {
                padding-right: 60px;
                @media #{$md-only} {
                    padding-right: 20px;
                }
                @media #{$xs-only, $sm-only} {
                    padding-right: 0;
                }
            }
            .column2 {
                padding-left: 60px;
                @media #{$md-only} {
                    padding-left: 20px;
                }
                @media #{$xs-only, $sm-only} {
                    padding-left: 0;
                }
            }
        }
        &.black {
            background-color: $black90;
            .column {
                .line {
                    background-color: $white;
                }
                .line-mobile {
                    background-color: $white;
                }
                p {
                    color: $white;
                }

            }
        }
    }

}