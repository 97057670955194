@rm-comp 'C7HW8vos9g' {

/* styles go here */
.search-icon-container {
    position: absolute;
    right:0;
    top:0;
    bottom:0;
    display:flex;
    align-items: center;

    button {
        background-image:url(images/icons/SVG/search-icon.svg);
        width: 40px;
        height: 40px;
        background-size: 50%;
        background-repeat: no-repeat;
        background-position: center;
    }
}
.search-bar-outer {
    position: relative;
    height: 0px;

    @media only screen and (max-width: 768px) {
        right: 0px;
        top:67px;
        bottom:0;
        position: fixed;
        transition: right 250ms ease-in-out;
        height:100vh;
        margin-top:0;
        z-index: 99999;
    }

    &.open {
        height: 60px;

        @media only screen and (max-width: 768px) {
            height: 100vh;
        }
    }
}
.search-bar {
    display: none;
    position: fixed;
    top:30px;
    left:0;
    right:0;
    background: #424143;
    height: 60px;
    color:white;
    z-index: 1000;

    @media only screen and (max-width: 768px) {
        margin-right: -230px;
        width:230px;
        position: relative;
        height:100%;
        top:0;
        padding-top:20px;
    }
    &.open {
        display: block;
        top:90px;

        @media only screen and (max-width: 768px) {
            top:0;
            margin-right:0;
        }

    }
    .bar-content {
        max-width: 1070px;
        margin: 0 auto;
        justify-content:space-between;
        width:100%;
        padding-left: 20px;
        padding-right: 20px;
        align-items:center;
        padding-top:12.5px;
        display:flex;
        @media only screen and (max-width: 768px) {
            flex-wrap:wrap;
            padding:0;
        }
        @media screen and (max-width: 900px) and (min-width: 769px) {
            padding-right:5px;
            padding-left:5px;
        }
        .search-icon {
            width:auto;
            padding-top:5px;
            margin-right:5px;
            margin-left:5px;
            @media only screen and (max-width: 768px) {
                width:100%;
                margin-bottom: 10px;
                padding-top:10px;
                border-top: 1px solid #1d1d1d;
                margin-right:0;
                margin-left:0;
            }
            button {
                display:flex;
                align-items:center;
                @media only screen and (max-width: 768px) {
                    margin-left:20px;
                }
            }
            button:hover p {
                text-decoration:underline;
            }
            img {
                width:25px;
                margin-right:5px;
                @media screen and (max-width: 900px) and (min-width: 769px) {
                    width:20px;
                }
            }
            p {
                color:white;
                @media only screen and (max-width: 950px) {
                    font-size:13px;
                }
            }
        }
        button.close-btn {
            background-image:url(images/icons/SVG/search-close.svg);
            width:20px;
            height:20px;
            background-size:contain;
            @media only screen and (max-width: 768px) {
                display:none;
            }
        }
        .search-input {
            position:relative;
            padding-top:0;
            @media screen and (max-width: 900px) and (min-width: 769px) {
                width:140px;
            }
            @media only screen and (max-width: 768px) {
                order:1;
                margin-bottom:10px;
                margin-right:20px;
                margin-left:20px;
            }
        }
        .social {
            @media only screen and (max-width: 768px) {
                order:2;
            }
        }
        .search-icon:nth-child(2) {
            @media only screen and (max-width: 768px) {
                order:3;
            }
        }
        .search-icon:nth-child(3) {
            @media only screen and (max-width: 768px) {
                order:4;
            }
        }
        .search-icon:nth-child(4) {
            @media only screen and (max-width: 768px) {
                order:5;
            }
        }
        .search-icon:nth-child(5) {
            @media only screen and (max-width: 768px) {
                order:6;
            }
        }
        .search-icon:nth-child(6) {
            @media only screen and (max-width: 768px) {
                order:7;
            }
        }
        .search-icon:nth-child(8) {
            @media only screen and (max-width: 768px) {
                display:none;
            }
        }
    }
}
    
input[type=search] { 	
	padding: 5px;    
    border-radius:4px;
    color:#1a1a1a;
    @media screen and (max-width: 900px) and (min-width: 769px) {
        width:140px;
    }
}        
    
.search-btn {
    position:absolute;
    background-image:url(images/icons/SVG/search-icon.svg);
    width:20px;
    height:20px;
    background-size:contain;
    right:10px;
    bottom:10px;
}    
    
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}        
    
    
.mobile-header {
    .search-container {
        .search-icon-container {
            margin-right:20px;
        }
    }
}    
    
    p.active {
        font-weight:bold;
    }    
}