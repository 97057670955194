@rm-comp 'f2J4-jl69' {

    @import 'sass/variables';

    @mixin banner-img($url) {
        background-image: url($url);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    .img-1 {
        @include banner-img('images/banner-bgs/future-tech-banner-bg-1.jpg');
        @media #{$xs-only, $sm-only} {
            @include banner-img('images/banner-bgs/future-tech-banner-bg-1-mobile.jpg');
            background-position: 100% 75%;
        }
        &-halloween {
            @include banner-img('images/banner-bgs/halloween-future-tech-banner-bg-1.jpg');
            @media #{$xs-only, $sm-only} {
                @include banner-img('images/banner-bgs/halloween-future-tech-banner-bg-1-mobile.jpg');
                background-position: 100% 75%;
            }
        }
        &-pride {
            @include banner-img('images/banner-bgs/pride-future-tech-banner-bg-1.jpg');
            @media #{$xs-only, $sm-only} {
                @include banner-img('images/banner-bgs/pride-future-tech-banner-bg-1-mobile.jpg');
                background-position: 100% 75%;
            }
        }
        &-xmas {
            @include banner-img('images/banner-bgs/xmas-future-tech-banner-bg-1.jpg');
            @media #{$xs-only, $sm-only} {
                @include banner-img('images/banner-bgs/xmas-future-tech-banner-bg-1-mobile.jpg');
                background-position: 100% 75%;
            }
        }
        &-canadaday {
            @include banner-img('images/banner-bgs/canada-future-tech-banner-bg-1.png');
            @media #{$xs-only, $sm-only} {
                @include banner-img('images/banner-bgs/canada-future-tech-banner-bg-1-mobile.png');
                background-position: 100% 75%;
            }
        }
    }

    .img-2 {
        @include banner-img('images/banner-bgs/future-tech-banner-bg-2.jpg');
        @media #{$xs-only, $sm-only} {
            @include banner-img('images/banner-bgs/future-tech-banner-bg-2-mobile.jpg');
        }
    }

    .img-smart-watch {
        @include banner-img('images/banner-bgs/future-tech-banner-bg-3.jpg');
    }

    .future {
        overflow: hidden;
    }

    .smart-watch-view {
        width: 300px;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 4%;
        overflow: hidden;
        @media #{$xl-only} {
            left: 7%;
        }
        @media #{$lg-only} {
            left: 2%;
        }
        @media #{$md-only} {
            left: -7%;
        }
        @media #{$xs-only, $sm-only} {
            width: 100%;
            height: 100%;
            left: 0;
            bottom: 0;
            top: auto;
        }
        .smart-watch {
            width: 100%;
            transform: scale(1.5) translateY(-100%);
            transition: transform 1s ease;

            @media #{$xs-only, $sm-only} {
                transform: scale(1.5) rotate(-55deg) translate(9%, -70%);
                width: 300px;
            }
        }

        &.smart-watch-shown .smart-watch {
            transform: scale(1.5) translateY(-8%);

            @media #{$xs-only, $sm-only} {
                transform: scale(1.5) rotate(-55deg) translate(9%, 6%);
            }
        }
    }

    .gallery {
        width: 100%;
        height: 625px;
        @media #{$md-only} {
            height: 550px;
        }
    }

    .banner-future {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        max-width: 1100px;

        .text {
            color: $black;
            text-align: center;
            max-width: 520px;
            margin: 0 auto;
            display: block;
            @media #{$xs-only} {
                padding-top: 40px;
            }
            .copy {
                color: $black;
                margin-bottom: 30px;
                margin-top: 20px;
            }
            .title {
                padding-top: 50px;
                @media #{$sm-only} {
                    padding-top: 30px;
                }
                @media #{$lg-up} {
                    padding-top: 130px;
                }
            }
        }
        .device {
            text-align: center;
            padding-bottom: 35px;
            img {
                width: 100%;
            }
        }
        .buttons {
            padding-bottom: 258px;
            text-align: center;
            .yellow-button {
                background-color: $black;
                color: white;
                .chevron {
                    color: white;
                }
            }
            .selected {
                background-color: $black;
                color: white;
                .chevron {
                    color: white;
                }
            }
        }
    }

    .content {
        @media #{$lg-up} {
            max-width: none;
            width: 1100px;
        }
    }

    .controls {
        width: 100%;
        padding: 0 20px 30px;
        display: block;
        text-align: center;
        position: absolute;
        bottom: 0;
        .dots {
            width: 14px;
            height: 14px;
            border: 1px solid $black;
            border-radius: 100%;
            display: inline-block;
            vertical-align: middle;
            margin: 5px;
            border: 1px solid $white;
            background-color: $black;
            @media #{$sm-only, $xs-only} {
                width: 10px;
                height: 10px;
                margin: 4px;
            }
        }

        .selected-dot {
            background-color: $white;
            border: 1px solid $black;
        }
    }

    .learn-more {
        background-color: $yellow;
        padding-top: 60px;
        padding-bottom: 60px;
        padding-left: 20px;
        padding-right: 20px;
        .learn-more-content {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            max-width: 1100px;
            margin: 0 auto;
            justify-content: center;
            align-items: center;
            .item {
                width: 22%;
                text-align: center;
                @media #{$md-only, $sm-only, $xs-only} {
                    width: 50%;
                }
                img {
                    width: 70px;
                    margin-bottom: 5px;
                    flex-shrink: 0;
                    -webkit-animation: pop-in 0.5s 0.2s both;
                    -moz-animation: pop-in 0.5s 0.2s both;
                    -ms-animation: pop-in 0.5s 0.2s both;
                    animation-delay: 0.7s;
                }
            }
            p {
                -webkit-animation: fadeIn 1s 0.2s both;
                -moz-animation: fadeIn 1s 0.2s both;
                -ms-animation: fadeIn 1s 0.2s both;
                animation-delay: 0.8s;
            }
            .item-top {
                @media #{$md-only, $sm-only, $xs-only} {
                    padding-bottom: 20px;
                }
            }
            .item-bottom {
                @media #{$md-only, $sm-only, $xs-only} {
                    padding-top: 20px;
                }
            }
            .line {
                max-width: 1px;
                width: 1%;
                height: 150px;
                background-color: $black90;
                animation: grow-height 0.5s 0.2s both;
                animation-delay: 0.6s;
                @media #{$md-only, $sm-only, $xs-only} {
                    display: none;
                }
            }
            .line-mobile {
                @media #{$xs-only, $sm-only, $md-only} {
                    width: 100%;
                    height: 1px;
                    background-color: $black90;
                    margin-top: 20px;
                    margin-bottom: 20px;
                    -webkit-animation: pop-in 0.5s 0.2s both;
                    -moz-animation: pop-in 0.5s 0.2s both;
                    -ms-animation: pop-in 0.5s 0.2s both;
                    animation-delay: 0.6s;
                }
            }
        }
        &.black {
            background-color: $black90;
            .learn-more-content {
                .item-line {
                    border-bottom: $white;
                }
                .item3 {
                    border-bottom: $white;
                }
                .line {
                    background-color: $white;
                }
                .lines {
                    .line-horizontal {
                        background-color: $white;
                    }
                }

                p {
                    color: $white;
                }

            }
        }
    }

    .learn-more-pharma {
        background-color: $yellow;
        padding-top: 60px;
        padding-bottom: 60px;
        padding-left: 20px;
        padding-right: 20px;
        @media #{$sm-only, $xs-only} {
            padding-top: 20px;
            padding-bottom: 20px;
        }
        .learn-more-content-pharma {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            max-width: 1100px;
            margin: 0 auto;
            justify-content: center;
            align-items: center;
            @media #{$sm-only, $xs-only} {
                align-items: inherit;
            }
            .item {
                width: 31%;
                text-align: center;
                padding: 10px;
                @media #{$sm-only, $xs-only} {
                    width: 50%;
                    font-size: 14px;
                }
                img {
                    width: 70px;
                    margin-bottom: 5px;
                    flex-shrink: 0;
                    -webkit-animation: pop-in 0.5s 0.2s both;
                    -moz-animation: pop-in 0.5s 0.2s both;
                    -ms-animation: pop-in 0.5s 0.2s both;
                    animation-delay: 0.7s;
                    @media #{$sm-only, $xs-only} {
                        width: 60px;
                    }
                }
                p {
                    -webkit-animation: fadeIn 1s 0.2s both;
                    -moz-animation: fadeIn 1s 0.2s both;
                    -ms-animation: fadeIn 1s 0.2s both;
                    animation-delay: 0.8s;
                }
            }
            .item-line {
                @media #{$sm-only, $xs-only} {
                    border-bottom: 1px solid $black90;
                    padding-bottom: 20px;
                    padding-top: 20px;
                    animation: grow-width3 0.5s 0.2s both;
                    animation-delay: 0.6s;
                }
            }
            .item3 {
                @media #{$sm-only, $xs-only} {
                    padding-top: 20px;
                    padding-bottom: 20px;
                    border-bottom: 1px solid $black90;
                    animation: grow-width3 0.5s 0.2s both;
                    animation-delay: 0.6s;
                }
            }
            .second-row {
                padding-top: 50px;
                @media #{$sm-only, $xs-only} {
                    padding-top: 20px;
                }
            }
            .line {
                max-width: 1px;
                width: 1%;
                height: 150px;
                background-color: $black90;
                animation: grow-height 0.5s 0.2s both;
                animation-delay: 0.6s;
                @media #{$sm-only, $xs-only} {
                    display: none;
                }
            }
            .line2 {
                margin-top: 50px;
            }
            .no-line {
                @media #{$sm-only, $xs-only} {
                    border: none;
                }
            }
            .lines {
                display: flex;
                justify-content: space-around;
                width: 100%;
                @media #{$sm-only, $xs-only} {
                    display: none;
                }
                .line-horizontal {
                    width: 33%;
                    height: 1px;
                    background-color: $black90;
                    margin-top: 50px;
                    max-width: 280px;
                    margin-right: 30px;
                    margin-left: 30px;
                    animation: grow-width2 0.5s 0.2s both;
                    animation-delay: 0.6s;
                }
            }
        }
        &.black {
            background-color: $black90;
            .learn-more-content-pharma {
                .item-line {
                    border-bottom: $white;
                }
                .item3 {
                    border-bottom: $white;
                }
                .line {
                    background-color: $white;
                }
                .lines {
                    .line-horizontal {
                        background-color: $white;
                    }
                }

                p {
                    color: $white;
                }

            }
        }
    }

}