//******************************************************************************
// This file should only contain styles that are used everywhere.
// Styles that are specific to certain pages/components need to be kept
// with those components.
//******************************************************************************
@import 'variables';
@import '_base';
@import 'redacted';
@import 'survey-slider';
@import 'transcript-dialog';


.personalization-main {
    @import '_personalization';
}


* {
    font-family: 'Open Sans', sans-serif;
}


/* PORTFOLIO TEMPLATE */

.template {
    background-color: $black90;
    padding-top: 10px;
    height: 498px;
    position: relative;
    bottom: 0;
    @media #{$xs-only} {
        height: 408px;
    }
    @media #{$sm-only} {
        height: 428px;
    }
    @media #{$md-only} {
        height: 488px;
    }
    .content {
        max-width: 1100px;
        display: block;
        margin: 0 auto;
        padding-left: 20px;
        padding-right: 20px;
        .project {
            position: absolute;
            @media #{$sm-only, $xs-only, $md-only} {
                max-width: none;
                display: block;
                margin: 0 auto;
                position: inherit;
            }
            @media #{$lg-only} {
                margin-left: 45px;
            }
            .project-title {
                padding-bottom: 15px;
                color: white;
                font-size: 40px;
                font-weight: 500;
                @media #{$xs-only, $sm-only} {
                    text-align: center;
                    font-size: 25px;
                }
                @media #{$xs-only, $sm-only} {
                    font-size: 21px;
                    padding-bottom: 5px;
                }
                @media #{$md-only} {
                    font-size: 30px;
                    padding-bottom: 5px;
                    text-align: center;
                }
            }
            .project-description {
                padding-bottom: 30px;
                max-width: 280px;
                color: white;
                display: block;
                @media #{$sm-only, $xs-only, $md-only} {
                    max-width: none;
                    text-align: center;
                }
                @media #{$sm-only, $xs-only} {
                    font-size: 14px;
                }
                @media #{$sm-only, $xs-only, $md-only} {
                    padding-bottom: 10px;
                }
                @media #{$md-only} {
                    padding-right: 15px;
                    padding-left: 15px;
                }
            }
            .yellow-button {
                @media #{$md-only, $sm-only, $xs-only} {
                    max-width: 150px;
                    display: block;
                    margin: 0 auto;
                    margin-top: 0;
                }
            }
        }
    }
}


.gradient {
    width: 100%;
    background: linear-gradient(#c6c6c6, #d8d8d8, #ededed, white, white, white, white);
    height: 159px;
    margin-top: -110px;
    @media #{$lg-up} {
        margin-top: -160px;
    }
    @media #{$xs-only} {
        height: 163px;
    }
    .client-logo {
        @media #{$xs-only, $sm-only, $md-only} {
            text-align: center;
        }
        img {
            max-width: 190px;
            margin-top: 80px;
            @media #{$xs-only, $sm-only} {
                margin-left: 0;
                text-align: center;
                margin-top: 85px;
                max-width: 140px;
            }
            @media #{$xs-only, $sm-only, $md-only} {
                display: block;
                margin: 0 auto;
                margin-top: 110px;
            }
            @media #{$md-only} {
                margin-top: 118px;
                max-width: 140px;
            }
            @media #{$xs-only} {
                margin-top: 120px;
            }
            @media #{$sm-only} {
                margin-top: 121px;
                max-width: 130px;
            }
            @media #{$lg-only} {
                margin-left: 45px;
            }
        }
    }
}

/* BUTTONS*/

.yellow-button {
    background-color: $yellow;
    color: black;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 1px;
    padding: 10px 25px;
    border: 1px solid $yellow;
    border-radius: 10px;
    text-align: center;
    transition: background-color 0.5s ease;
    display: inline-block;

    &:hover {
        background-color: $black;
        border: 1px solid $yellow;
        border-style: inset;
        color: $yellow;
        transition: background-color 0.5s ease;
    }
    @media #{$xs-only, $sm-only} {
        margin: 0 auto;
    }
    @media #{$xs-only} {
        padding: 5px 10px 5px 10px;
        font-size: 14px;
    }
    @media #{$md-only} {}
    &.careers-btn {
        text-align: left;
        margin: 0px;
        margin-left: 17.5px;
        @media #{$md-only} {
            margin-left:0;
            font-size:15px;
        }
    }
    a {
        color: $black;
        text-decoration: none;
    }
}


.project {
    .yellow-button {
        display: inline-block;
        min-width: 161px;
    }
}

.chevron {
    border-style: solid;
    border-width: 0.05em 0.05em 0 0;
    content: '';
    display: inline-block;
    height: 0.55em;
    left: 0.15em;
    position: relative;
    top: 0.25em;
    transform: rotate(-45deg);
    vertical-align: top;
    width: 0.55em;
    color: $black;
    @media #{$xs-only} {
        top: 0.45em;
    }
    &:hover {
        color: $yellow;
    }
}

.chevron-video {
    border-style: solid;
    border-width: 0.05em 0.05em 0 0;
    content: '';
    display: inline-block;
    height: 0.6em;
    left: 0.15em;
    position: relative;
    top: 0.25em;
    transform: rotate(-45deg);
    vertical-align: top;
    width: 0.6em;
    color: $black;
    @media #{$xs-only} {
        top: 0.45em;
    }
    &:hover {
        color: $yellow;
    }
}

.chevron-learn-more {
    transform: rotate(135deg);
    top: 0.3em;
}

.chevron.right {
    left: 0;
    transform: rotate(45deg);
    &:hover {
        color: $yellow;
    }
}

.chevron.down {
    left: 0;
    transform: rotate(135deg);
}

.yellow-button:hover a {
    color: $yellow;
}

.yellow-button:hover .chevron {
    color: $yellow;
}

/* PORTFOLIO CAROUSEL */

.carousel-left {
    display: inline-block;
    position: absolute;
    top: 40%;
    margin-left: 25px;
    z-index: 50;

    @media #{$md-only} {
        margin-left: 15px;
    }
    @media #{$xs-only, $sm-only} {
        display: none;
    }
}

.carousel-right {
    display: inline-block;
    position: absolute;
    top: 40%;
    right: 0;
    margin-right: 25px;
    z-index: 50;

    @media #{$md-only} {
        margin-right: 15px;
    }
    @media #{$xs-only, $sm-only} {
        display: none;
    }
}

.arrow::before {
    border-style: solid;
    border-width: 0.25em 0.25em 0 0;
    content: '';
    display: inline-block;
    height: 1.45em;
    left: 0.15em;
    position: relative;
    top: 0.15em;
    transform: rotate(-45deg);
    vertical-align: top;
    width: 1.45em;
    color: $yellow;
}

.arrow.arrow-left:before {
    left: 0.25em;
    transform: rotate(-135deg);
}

.arrow.arrow-right:before {
    left: 0;
    transform: rotate(45deg);
}

/* PORTFOLIO NAV */

.portfolio-nav {
    max-width: 691px;
    display: block;
    margin: 0 auto;
    padding-top: 15px;
    text-align: center;

    @media #{$xs-only} {
        display: none;
    }
    @media #{$md-only} {
        max-width: 601px;
    }
    @media #{$sm-only} {
        max-width: 556px;
        padding-bottom: 1px;
    }
    a {
        color: $yellow;
        padding: 20px;
        text-decoration: none;
        @media #{$md-only} {
            padding: 10px;
        }
        @media #{$sm-only} {
            padding: 5px;
            font-size: 15px;
        }
    } // .selected {
    //     &:after {
    //         content: "";
    //         display: block;
    //         margin: 0 auto;
    //         width: 30%;
    //         padding-top: 10px;
    //         border-bottom: 1px solid $yellow;
    //     }
    // }
}


/* BANNERS */

.banner {
    width: 100%;
    max-height: 625px;
    .content {
        max-width: 1100px;
        display: block;
        margin: 0 auto;
        padding-left: 20px;
        padding-right: 20px;
    }
    .text {
        @media #{$sm-only, $xs-only} {
            text-align: center;
        }
        .title {
            font-size: 50px;
            font-weight: 600;
            @media #{$md-only} {
                padding-top: 80px;
                font-size: 35px;
            }
            @media #{$lg-only} {
                font-size: 46px;
            }
            @media #{$lg-up} {
                padding-top: 190px;
            }
            @media #{$sm-only, $xs-only} {
                font-size: 30px;
            }
        }
        .copy {
            font-size: 20px;
            margin-top: 60px;
            margin-bottom: 80px;
            @media #{$sm-only, $xs-only} {
                font-size: 14px;
                margin-top: 30px;
                margin-bottom: 40px;
            }
            @media #{$md-only} {
                margin-top: 40px;
                margin-bottom: 60px;
            }
        }
    }
    .buttons {
        padding-bottom: 144px;
        @media #{$sm-only, $xs-only} {
            padding-bottom: 45px;
        }
        .yellow-button {
            border: 1px solid $black;
            /*&:first-child {
                margin-right:30px;
                @media #{$xs-only, $sm-only} {
                    margin-right:0;
                }
                @media #{$md-only} {
                    margin-right:15px;
                }
            }*/
        }
        .black-button {
            &:hover {
                background-color: $yellow;
                color: $black;
                border: 1px solid $yellow;
            }
        }
        .black-button:hover .chevron {
            color: $black;
        }
        .selected {
            background-color: $black;
            color: white;
            .chevron {
                color: white;
            }
            .chevron.selected {
                transform: rotate(315deg);
                top: 0.5em;
            }
        }
    }
    .buttons-yellow {
        .selected {
            background-color: $yellow;
            color: $black;
            .chevron {
                color: $black;
            }
            .chevron.selected {
                color: $black;
                top: 0.5em;
            }
            .chevron-learn-more {
                color: $black;
                left: 0;
            }
        }
    }
    .yellow-banner {
        background: radial-gradient(circle, #FCCB5E, $yellow, $yellow, $yellow, $yellow, $yellow);
        width: 100%;
        h2 {
            color: $black;
        }
        p {
            color: $black;
        }
    }
    .black-banner {
        background-color: $black90;
        h2 {
            color: $yellow;
            font-size: 50px;
            @media #{$lg-up} {
                padding-top: 190px;
            }
        }
        p {
            color: $white;
            font-size: 20px;
        }
    }
}



.banner {
    .black-banner,
        {
        .buttons {
            .selected {
                background-color: $yellow;
                color: $black;
                .chevron {
                    color: $black;
                    transform: rotate(135deg);
                    top: 0.3em;
                }
                .chevron.selected {
                    transform: rotate(-45deg);
                    top: 0.5em;
                }
            }
            .right-button {
                background-color: $black;
                color: $white;
                text-transform: uppercase;
                text-decoration: none;
                letter-spacing: 1px;
                padding: 10px 25px;
                border: 1px solid $white;
                border-radius: 10px;
                text-align: center;
                transition: background-color 0.5s ease;
                display: inline-block;
                margin-left: 20px;
                @media #{$xs-only} {
                    padding: 5px 10px 5px 10px;
                    font-size: 14px;
                }
                @media #{$xs-only} {
                    margin-left: 5px;
                }
                @media #{$md-only} {
                    margin-left: 10px;
                }
                &:hover {
                    background-color: $yellow;
                    border: 1px solid $black;
                    border-style: inset;
                    color: $black;
                    transition: background-color 0.5s ease;
                }
                .chevron {
                    color: $white;
                }
                .chevron.selected {
                    transform: rotate(135deg);
                }
            }
            .right-button:hover .chevron {
                color: $black;
            }
        }
    }
    .yellow-banner {
        .buttons {
            .selected {
                background-color: $black;
                color: $white;
                .chevron {
                    color: $white;
                    transform: rotate(135deg);
                    top: 0.3em;
                }
                .chevron.selected {
                    transform: rotate(-45deg);
                    top: 0.5em;
                }
            }
            .right-button {
                background-color: $yellow;
                color: $black;
                text-transform: uppercase;
                text-decoration: none;
                letter-spacing: 1px;
                padding: 10px 25px;
                border: 1px solid $black;
                border-radius: 10px;
                text-align: center;
                transition: background-color 0.5s ease;
                display: inline-block;
                margin-left: 20px;
                @media #{$xs-only} {
                    padding: 5px 10px 5px 10px;
                    font-size: 14px;
                }
                @media #{$xs-only} {
                    margin-left: 5px;
                }
                @media #{$md-only} {
                    margin-left: 10px;
                }
                &:hover {
                    background-color: $black;
                    border: 1px solid $yellow;
                    border-style: inset;
                    color: $yellow;
                    transition: background-color 0.5s ease;
                }
                .chevron {
                    color: $black;
                }
                .chevron.selected {
                    transform: rotate(135deg);
                }
            }
            .right-button:hover .chevron {
                color: $yellow;
            }
        }
    }
}

.pointer-focus :focus {
    outline: 0;
}

.rm-loader {
    display: none;

    &.full {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    &.show {
        display: inline-flex;
    }

    > * {
        border: 5px solid rgba(255, 255, 255, 0.3);
        border-top: 5px solid #FFC425;
        border-radius: 50%;
        animation: spinner 750ms linear infinite;
        margin: auto;
    }
}

.rm-play-btn {
    display: none;
    svg {
        @media #{$xs-only} {
            width: 50px;
            height: 50px;
        }
        @media #{$sm-only} {
            width: 100px;
            height: 100px;
        }
    }
    &.full {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    &.show {
        display: inline-flex;
    }

    > * {
        margin: auto;
    }
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.delay1 {
    opacity: 0.01;
    animation-delay: 0.5s;
}

/*animations*/

@-webkit-keyframes pop-in {
    0% {
        opacity: 0;
        -webkit-transform: scale(0.5);
    }
    100% {
        opacity: 1;
        -webkit-transform: scale(1);
    }
}

@-moz-keyframes pop-in {
    0% {
        opacity: 0;
        -moz-transform: scale(0.5);
    }
    100% {
        opacity: 1;
        -moz-transform: scale(1);
    }
}

@keyframes pop-in {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(30px);
    }

    25% {
        opacity: 0;
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes fadeIn2 {
    0% {
        opacity: 0;
    }
    40% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.fadeIn {
    animation-name: fadeIn;
    animation-duration: 1s;
}


@keyframes slideUp {
    0% {
        opacity: 0.8;
        transform: translateY(450px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

.slideUp {
    transform: translateY(50px);

    .run-animation & {
        animation-name: slideUp;
        animation-duration: 0.9s;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
    }
}


.hand-phone {
    position: absolute;
    bottom: 0;
    right: 370px;
}

.grow {
    animation: grow 3s forwards;
}

@keyframes grow-height {
    0% {
        height: 0px;
    }
    100% {
        height: 150px;
    }
}

@keyframes grow-width {
    0% {
        width: 0px;
    }
    100% {
        width: 400px;
    }
}

@keyframes grow-width2 {
    0% {
        width: 0px;
    }
    100% {
        width: 100%;
    }
}

@keyframes grow-width3 {
    0% {
        border-bottom: 0px;
    }
    100% {
        border-bottom: 100%;
    }
}

/*icon animation*/

@-webkit-keyframes showUp {
    0% {
        opacity: 0;
        transform: scale(0);
        transform-origin: center;
        fill: $yellow;
    }

    50% {
        opacity: .3;
        transform: scale(2);
        transform-origin: center;
        fill: $yellow;
    }
    100% {
        opacity: 1;
        transform: scale(1.0);
        transform-origin: center;
    }

}

@-moz-keyframes showUp {
    0% {
        opacity: 0;
        transform: scale(0);
        transform-origin: center;
        fill: $yellow;
    }

    50% {
        opacity: .3;
        transform: scale(2);
        transform-origin: center;
        fill: $yellow;
    }
    100% {
        opacity: 1;
        transform: scale(1.0);
        transform-origin: center;
    }

}

@keyframes showUp {
    0% {
        opacity: 0;
        transform: scale(0);
        transform-origin: center;
        fill: $yellow;
    }

    50% {
        opacity: .3;
        transform: scale(2);
        transform-origin: center;
        fill: $yellow;
    }
    100% {
        opacity: 1;
        transform: scale(1.0);
        transform-origin: center;
    }

}


@-webkit-keyframes showUp-2 {
    0% {
        opacity: 0;
        transform: scale(0);
        transform-origin: center;
        fill: $yellow;
    }

    50% {
        opacity: 0.5;
        transform: scale(1.2);
        transform-origin: center;
        fill: $yellow;
    }
    100% {
        opacity: 1;
        transform: scale(1.0);
        transform-origin: center;
    }

}

@-moz-keyframes showUp-2 {
    0% {
        opacity: 0;
        transform: scale(0);
        transform-origin: center;
        fill: $yellow;
    }

    50% {
        opacity: 0.5;
        transform: scale(1.2);
        transform-origin: center;
        fill: $yellow;
    }
    100% {
        opacity: 1;
        transform: scale(1.0);
        transform-origin: center;
    }

}

@keyframes showUp-2 {
    0% {
        opacity: 0;
        transform: scale(0);
        transform-origin: center;
        fill: $yellow;
    }

    50% {
        opacity: 0.5;
        transform: scale(1.2);
        transform-origin: center;
        fill: $yellow;
    }
    100% {
        opacity: 1;
        transform: scale(1.0);
        transform-origin: center;
    }

}



@-webkit-keyframes rotate2 {
    0% {
        opacity: 1;
        transform: rotate(360deg);
        transform-origin: center center;
        fill: $yellow;
    }

    100% {
        opacity: 1;
        transform: rotate(0deg);
        transform-origin: center center;
        fill: $yellow;
    }
}

@-moz-keyframes rotate2 {
    0% {
        opacity: 1;
        transform: rotate(360deg);
        transform-origin: center center;
        fill: $yellow;
    }

    100% {
        opacity: 1;
        transform: rotate(0deg);
        transform-origin: center center center;
        fill: $yellow;

    }

}

@keyframes rotate2 {
    0% {
        opacity: 1;
        transform: rotate(360deg);
        transform-origin: center center;
        fill: $yellow;
    }

    100% {
        opacity: 1;
        transform: rotate(0deg);
        transform-origin: center center;
        fill: $yellow;

    }

}



@-webkit-keyframes draw {

  50% {
    stroke-dashoffset: 0;
  }

100% {
    stroke-dashoffset: 0;
  }
}


@-moz-keyframes draw {

  50% {
    stroke-dashoffset: 0
  }
    
    100% {
    stroke-dashoffset: 0;
  }

}
    


@keyframes draw {
 
  50% {
    stroke-dashoffset: 0;
  }
    
    100% {
    stroke-dashoffset: 0;
  }
}



@keyframes quiz {

    0% {
        opacity:0;
        transform: scale(0);
        transform-origin: top right;
      
    }

    
    100% {
        opacity: 1;
        transform: scale(1);
        transform-origin: top right;
     

    }


}

@-moz-keyframes quiz {

    0% {
        opacity:0;
        transform: scale(0);
        transform-origin: top right;
      
    }

    
    100% {
        opacity: 1;
        transform: scale(1);
        transform-origin: top right;
     

    }


}




@keyframes quiz-gone {

    from {
        opacity:1;
        transform: scale(1);
        transform-origin: top right;
      
    }

    
    to {
        opacity: 0;
        transform: scale(0);
        transform-origin: top right;
     
    }


}



@keyframes disappear {

    0% {
    opacity:1;
        
    }
    
    100% {
    opacity:0;
    }


}



@-moz-keyframes disappear {

    0% {
    opacity:1;
        
    }
    
    100% {
    opacity:0;
    }


}


@keyframes load-appear {

    0% {
    opacity:1;
        
    }
    
    100% {
    opacity:0;
    }


}

@keyframes  arrow-scroll    {

    0% {
    
    transform: translateY(0px) rotate(135deg);
   
    
    
    }
    
    50%{
        
    transform: translateY(10px) rotate(135deg);
   
    
    
    }

    100%{
    
    transform: translateY(0px) rotate(135deg);
 
    
    }

}







.no-wrap {
    flex-wrap: inherit !important;
    @media #{$sm-only, $xs-only} {
        display: block !important;
        flex-wrap: wrap !important;
    }
}

.title-2 {
    padding-bottom: 50px !important;
    margin-top: -50px !important;
    @media only screen and (max-width: 767px) and (min-width: 576px), only screen and (max-width: 575px){
        font-size: 14px !important;
        margin-top: -70px !important;
        padding-bottom: 40px !important;
    }
}

