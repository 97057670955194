@rm-comp 'WnsN6BKTKq' {

    @import 'sass/variables';

    .careers {
        font-size: 30px;
        text-align: center;
        margin: 0 auto;
        padding: 0px 30px;
        font-weight: 700;
        color: #black;
        position: relative;
        top:50%;
        @media #{$md-up} {
            font-size: 50px;
        }
    }
    .careers-description {
        text-align:center;
        margin-top:10px;
        padding: 0px 30px;
        font-size:20px;
    }
    .hero-video {
        background-image: url(images/hero-video-p.jpg);
        background-size: cover;
        width: 100%;
        height: 300px;
        margin: 0 auto;
        background-position: center;
        @media #{$md-up} {
            height: 700px;
        }
    }

    .yellow-gradient {
        padding: 50px 0px;
        @media #{$xs-only, $sm-only, $md-only} {
            padding-bottom:15px;
        }
        background: radial-gradient(circle, #FCCB5E, $yellow, $yellow, $yellow, $yellow, $yellow);
        h2 {
            animation-name: fadeIn;
            animation-duration: 1s;
        }
        p.careers-description {
            animation-name: fadeIn;
            animation-duration: 1s;
        }
    }

    .perks-banner {
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        @media #{$lg-up} {
            flex-direction: row;
            max-width: 1100px;
            justify-content: center;
        }
        .icon-text {
            padding: 30px 0px;
            width: 100%;
            display: flex;
            @media #{$xs-only, $sm-only, $md-only} {
                align-items:center;
            }
            @media #{$xs-up} {
                &:nth-child(-n+3) {
                    border-bottom: 1px solid $black90;
                }
            }
            @media #{$lg-up} {
                height:230px;
                &:nth-child(3) {
                    border-bottom: none;
                }
                &:nth-child(4) {
                    border-bottom: none;
                }
            }
            @media #{$lg-up} {
                padding: 50px 0px;
                width: 45%;
                max-width: 400px;
                &:nth-child(odd) {
                    margin-right: 50px;
                }
            }
            .perks-title {
                font-size: 20px;
                margin-bottom:5px;
                font-weight: 700;
                color: $black90;
                @media #{$xs-only} {
                    font-size: 18px;
                }
                @media #{$xs-only, $sm-only, $md-only} {
                    margin-bottom:15px;
                }
            }
            .perks-text {
                font-size: 16px;
                color: $black90;
                @media #{$xs-only} {
                    font-size:14px;
                }
            }
            .perks-icon {
                margin-right: 20px;
                @media #{$xs-only} {
                    max-width:50px;
                    margin-right:15px;
                }
            }
        }
    }


    .job-applications {
        background-color:$black90;
        width: 100%;
        height: 200px;

        @media #{$xs-only, $sm-only} {
            height:auto;
            padding-bottom:20px;
            padding-right:20px;
            padding-left:20px;
        }

        .positions {
            display:flex;
            justify-content:center;
            /*max-width:600px; for 2 postings */
            max-width: 910px;
            margin:0 auto;
            align-items:center;
            @media #{$xs-only, $sm-only} {
                flex-wrap:wrap;
                justify-content:flex-start;
            }
        }
        .divider {
            width:1px;
            background-color:$yellow;
            height:160px;
            margin-top:20px;
            flex-shrink:0;
            @media #{$xs-only, $sm-only} {
                width:100%;
                height:1px;
            }
        }
        .application {
            width:50%;
            padding-right:30px;
            padding-left:40px;
            min-width: 340px;

            @media #{$xs-only, $sm-only} {
                width:100%;
                padding:40px 0 40px 0;
            }
            @media #{$md-only} {
                padding-right:35px;
                padding-left:35px;
                min-width: 33%;
            }
            h3 {
                color:white;
                font-size:30px;
                margin-bottom:25px;
                text-align:left;
                margin-left: 17.5px;
                /* margin-right: 5px; */

                @media #{$md-only} {
                    font-size:23px;
                    /* height:54px; if theres no graphic designer & copy writer */
                    height:85px;
                    margin-left:0;
                }
            }
        }
        .less-than-three-positions-margin {
            margin-top: 25px;
        }
        .less-than-three-positions-width {
            max-width: 660px;
        }
        .more-than-three-positions-margin {
            padding-left:10px;
            padding-right:10px;

            h3 {
                font-size:27px;
            }
        }
        .more-than-three-positions-width {
            max-width: 1080px;
        }
    }

    .gallery-row {
        display: flex;
        height:200px;
        @media #{$xs-only} {
            height:150px;
        }
        > .gallery-column {
            display: flex;
            flex-direction: column;
            height:100%;

            > * {
                flex: 1 1 auto;
            }
        }
        .photo-tile {
            overflow: hidden;
            position: relative;
            > * {
                position: absolute;
                top:0;
                left:0;
                right:0;
                bottom:0;
                background-size: cover;
                background-position:center;
                background-repeat: no-repeat;
                transition: all 1s ease;
                &:hover {
                    transform: scale(1.3) rotate(0.1deg);
                }
            }
        }
    }

    .video {
        position: relative;
        background-size: cover;
        background-position: center;
        background-repeat:no-repeat;
        overflow-x:hidden;
        @media #{$xs-up}{
            background-image: url(images/banner-bgs/careers-banner-static-md.jpg);
            padding-bottom: 56.25%;
            &-halloween {
                background-image: url(images/banner-bgs/halloween-careers-banner-static-md.jpg)
            }
            &-xmas {
                background-image: url(images/banner-bgs/xmas-careers-banner-static-md.jpg)
            }
            &-pride {
                background-image: url(images/banner-bgs/pride-careers-banner-static-md.jpg)
            }
        }

        @media #{$sm-up}{
            background-image: url(images/banner-bgs/careers-banner-static-md.jpg);
            &-halloween {
                background-image: url(images/banner-bgs/halloween-careers-banner-static-md.jpg)
            }
            &-xmas {
                background-image: url(images/banner-bgs/xmas-careers-banner-static-md.jpg)
            }
            &-pride {
                background-image: url(images/banner-bgs/pride-careers-banner-static-md.jpg)
            }
        }
        @media #{$md-up}{
            background-image: url(images/banner-bgs/careers-banner-static-lg.jpg);
            padding-bottom: 41%;
            &-halloween {
                background-image: url(images/banner-bgs/halloween-careers-banner-static-lg.jpg)
            }
            &-xmas {
                background-image: url(images/banner-bgs/xmas-careers-banner-static-lg.jpg)
            }
            &-pride {
                background-image: url(images/banner-bgs/pride-careers-banner-static-lg.jpg)
            }
        }
        @media #{$lg-up}{
            padding-bottom: 41%;
            background-image: url(images/banner-bgs/careers-banner-static-lg.jpg);
            &-halloween {
                background-image: url(images/banner-bgs/halloween-careers-banner-static-lg.jpg)
            }
            &-xmas {
                background-image: url(images/banner-bgs/xmas-careers-banner-static-lg.jpg)
            }
            &-pride {
                background-image: url(images/banner-bgs/pride-careers-banner-static-lg.jpg)
            }
        }
        @media #{$xl-up} {
            min-height:calc(100vh - 270px);
            background-image: url(images/banner-bgs/careers-banner-static-lg2.jpg);
            background-size: cover;
            padding-bottom:0;
            overflow-y:hidden;
        }
        .left-img {
            position:absolute;
            left:-82px;
            transform: rotate(270deg);
            top:30%;
            display:none;
            @media #{$xl-up} {
                display:block;
            }
            img {
                width:380px;
            }
        }
        .right-img {
            position:absolute;
            right:-85px;
            transform: rotate(90deg);
            top:30%;
            display:none;
            @media #{$xl-up} {
                display:block;
            }
            img {
                width:400px;
            }
        }
        .top1, .top2, .top3 {
            position:absolute;
            top:0;
            display:none;
            @media #{$xl-up} {
                display:block;
            }
            img {
                width:400px;
            }
        }
        .top2 {
            img {
                width:500px;
            }
        }
        .top2, .bottom2 {
            left:35%;
        }
        .top3, .bottom3 {
            left:70%;
        }
        .bottom1, .bottom2, .bottom3 {
            position:absolute;
            bottom:0;
            transform: rotate(180deg);
            display:none;
            @media #{$xl-up} {
                display:block;
            }
            img {
                width:400px;
            }
        }
        .bottom2, .bottom1 {
            img {
                width:300px;
            }
        }
        .bottom2 {
            left:40%;
        }
        h2 {
            font-size: 35px;
            text-align: center;
            margin: 0 auto;
            font-weight: 700;
            color: #fff;
            position: absolute;
            top:29%;
            left: 50%;
            width:100%;
            transform: translateX(-50%);
            animation-name: fadeIn2;
            animation-duration: 1s;
            @media #{$md-up} {
                font-size: 50px;
                top: 35%;
            }
            @media #{$xs-only} {
                font-size:19px;
            }
        }

        video {
            width:100%;
            height:100%;
            z-index: 1;
        }

        .social-icons {
            display:flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 45%;
            left: 50%;
            transform: translateY(-50%);
            transform: translateX(-50%);
            z-index: 2;
            animation-name: fadeIn2;
            animation-duration: 1s;
            @media #{$lg-only, $md-only} {
                top: 50%;
            }
            @media #{$sm-only} {
                top:43%;
            }
            @media #{$xs-only} {
                top:38%;
            }
            a {
                margin:10px;
                cursor:pointer;
            }
            .bg-img {
                width:27px;
                height:25px;
                background-size:cover;
                margin:10px;
                transition:background-image 0.5s ease-in-out;
                @media #{$xs-only} {
                    width:20px;
                    height:20px;
                }
            }
            .linkedin {
                background-image: url(images/icons/SVG/linkedin-w.svg);
                &:hover {
                    background-image: url(images/icons/SVG/linkedin-y.svg);
                    transition:background-image 0.5s ease-in-out;
                }
            }
            .facebook {
                background-image: url(images/icons/SVG/facebook-w.svg);
                &:hover {
                    background-image: url(images/icons/SVG/facebook-y.svg);
                    transition:background-image 0.5s ease-in-out;
                }
            }
            .twitter {
                background-image: url(images/icons/SVG/twitter-w.svg);
                &:hover {
                    background-image: url(images/icons/SVG/twitter-y.svg);
                    transition:background-image 0.5s ease-in-out;
                }
            }
            .instagram {
                background-image: url(images/icons/SVG/instagram-w.svg);
                &:hover {
                    background-image: url(images/icons/SVG/instagram-y.svg);
                    transition:background-image 0.5s ease-in-out;
                }
            }
        }
    }


     .nowrap {
        white-space: nowrap;
    }

    .content {
        max-width: 1100px;
        display: block;
        margin: 0 auto;
        padding-left: 20px;
        padding-right:20px;
    }


}