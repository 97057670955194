@rm-comp '-Bw5dkxTr0' {

    @import 'sass/variables';

    .wrapper {
        background-color:$black90;
        .content {
            max-width: 1100px;
            display: block;
            margin: 0 auto;
            padding-left: 20px;
            padding-right: 20px;
            padding-top:60px;
            padding-bottom:100px;
            h1 {
                font-size:30px;
                font-weight:bold;
                color:$yellow;
                margin-bottom:20px;
                animation-name: fadeIn;
                animation-duration: .5s;
            }
            p {
                color:white;
                font-size:16px;
                line-height:22px;
                margin-bottom:15px;
                animation-name: fadeIn;
                animation-duration: .5s;
            }
            h2 {
                color:$yellow;
                font-size:20px;
                font-weight:bold;
                margin-bottom:15px;
                animation-name: fadeIn;
                animation-duration: .5s;
            }
            h3 {
                color:white;
                font-size:18px;
                font-weight:bold;
                margin-bottom:15px;
                animation-name: fadeIn;
                animation-duration: 0.5s;
            }
            a {
                color:$yellow;
                text-decoration:none;
                transition:0.5s ease-in-out;
                &:hover {
                    color:white;
                }
            }
            .policy {
                margin-bottom:20px;
            }
            ul {
                margin-bottom:15px;
                margin-left:20px;
                li {
                    color:white;
                }
            }
            .contact {
                p {
                    margin:0;
                }
                a {
                    display:block;
                    margin-top:10px;
                }
            }
        }
    }

}