.main-container {
    background-color: black;
    position: relative;
    display: block;
    @media screen and (max-height: 800px) {
        min-height: calc(100vh - 66.83px);
    }
    @media #{$lg-up} {
        min-height: calc(100vh - 90px);
    }
    @media #{$xs-only, $sm-only} {
        padding-right: 0px;
        padding-left: 0px;
    }
}

.rm-ba-right, .rm-ba-left {
    @media (min-width: 991px){
        top: -4%;
    }
}

#video-video {
    @media (min-width: 991px){
        top: 45%;
    }
}

.yellow-container {
    background-color: $yellow;
    box-shadow: 0px 0px 7px 2px rgba(0, 0, 0, 0.15);
}

.text-container {
    text-align: center;
    padding: 165px 20px;
    width: 100%;
    max-width: 1280px;
    @media screen and (max-height: 800px) {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        padding: 80px 20px;
    }
    @media screen and (max-height: 400px) {
        position: inherit;
        transform: none;
        left: unset;
        top: unset;
    }
    @media #{$lg-up} {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 20px 20px;
    }
}

.action-btn-wrapper {
    @media #{$xs-only, $sm-only, $md-only} {
        margin: 0 auto;
        display: block;
        width: 100%;
        max-width: 160px;
        margin-top: 50px;
        padding-bottom: 50px;
    }

    @media (min-height: 700px) and (max-height: 800px) {
        margin-top: 100px;
    }
    @media #{$md-only} {
        margin: 0 auto;
        display: block;
        width: 100%;
        max-width: 160px;
        margin-top: 50px;
    }
}

.btn {
    background-color: #ffc425;
    color: #1a1a1a;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    width: 160px;
    height: 40px;
    border-radius: 10px;
    transition: 0.5s ease;
    text-transform: uppercase;
    &:focus {
        border-radius: 10px;
    }
    &:disabled {
        background-color: #1a1a1a;

        &:hover {
            background-color: #1a1a1a;
            color: #1a1a1a;
            border: #1a1a1a;
            cursor: default;
        }
    }
}

.back-button {
    background-color: white;
    left: 50px;
    bottom: 85px;
    margin-bottom: 20px;
    @media #{$lg-up} {
        position: absolute;
        margin-bottom: 0;
        z-index: 10;
    }
    @media (pointer: fine) {
        &:hover {
            background-color: #1a1a1a;
            color: white;
            border: 1px solid white;
        }
    }

    @media (pointer: coarse) {
        &:active {
            background-color: #1a1a1a;
            color: white;
            border: 1px solid white;
        }
    }
}

.next-button {
    right: 50px;
    bottom: 85px;
    @media #{$lg-up} {
        position: absolute;
        z-index: 10;
    }

    @media (pointer: fine) {
        &:hover {
            background-color: white;
            border: 1px solid white;
        }
    }

    @media (pointer: coarse) {
        &:active {
            background-color: #ffffff;
            color: #1a1a1a;
        }
    }
}

.btn:focus {
    background-color: #ffffff;
    color: #1a1a1a;
    outline: none;
    border: 2px solid $yellow;
}

.slider-container {
    width: 100%;
    max-width: 400px;
    @media #{$xs-only} {
        max-width: 250px;
        margin: 0 auto;
        position: relative;
    }
    @media #{$sm-only, $md-only} {
        margin: 0 auto;
        position: relative;
    }
    .sign {
        color: white;
        cursor: pointer;
        -ms-user-select: none;
        user-select: none;
        @media #{$xs-only} {
            top: -15px;
        }
    }
}
.slider-outer {
    margin-top: 50px;
    @media #{$md-only} {
        margin-top: 60px;
    }
    @media #{$xs-only} {
        margin-top: 30px;
        @media (min-height: 700px) and (max-height: 799px) {
            margin-top: 60px;
        }
    }

    @media #{$lg-up} {
        position: absolute;
        bottom: 80px;
        left: 50%;
        transform: translate(-50%, 0);
    }
    p {
        color: white;
        text-align: center;
        max-width: 400px;
        margin: 0 auto;
        margin-bottom: 30px;
        @media #{$xs-only} {
            max-width: 200px;
            margin-bottom: 35px;
            height: 57px;
        }
    }
    p.number {
        color: $yellow;
        font-size: 25px;
        display: inline-block;
        font-weight: bold;
        margin-bottom: 0;
    }
    .sign {
        color: white;
        position: absolute;
        font-size: 55px;
    }
    .minus {
        left: -55px;
        @media #{$xs-only} {
            left: -15px;
        }
    }
    .plus {
        right: -55px;
        @media #{$xs-only} {
            right: -15px;
        }
    }
}

.next-outline {
    border: 1px solid #1a1a1a;
}
