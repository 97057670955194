@rm-comp 'NK3c-Il4V0' {

    @import 'sass/variables';

    .banner-animation {
        .two-columns {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            @media #{$md-only} {
                padding-top: 60px;
            }
            .column {
                width: 50%;
                @media #{$sm-only, $xs-only} {
                    width: 100%;
                }
            }
            .column1 {
                @media #{$md-only} {
                    width: 52%;
                }
            }
            .column2 {
                @media #{$sm-only, $xs-only} {
                    text-align: center;
/*                    margin-top:0px;*/
                }
                
/*
                @media #{$lg-only, $xl-only} {
                    margin-top:35px;
                   
                }
*/
                
                
                
                @media #{$md-only} {
                    width: 48%;
                }
                img {
                    width: 100%;
                    max-width: 820px;
                    @media #{$sm-only, $xs-only} {
                        width: 51%;
                        text-align: center;
                        display: block;
                        margin: 0 auto;
                    }
                    @media #{$xs-only} {
                        width: 72%;
                    }
                }
                
                .transcript {
                    width: 30px;
                    position: relative;
                    display: block;
                    left: 250px;
                    img {
                        width: 100%;
                    }
                    @media #{$sm-only} {
                        left: 0px;
                        bottom: 10px;
                    }
                    @media #{$xs-only} {
                        left: 0px;
                        bottom: 10px;
                    }
                    @media #{$md-only} {
                        left: 50%;
                    }

                    >* {
                        vertical-align: top;
                    }
                }

                .video-el {
                    width: 100%;
                    max-width: 620px;
                    min-height: 100px;
                    overflow: hidden;        
                    
                    @media #{$sm-only, $xs-only} {
                        width: 100%;
                        text-align: center;
                        display: block;
                        margin: 0 auto;
                        max-width: 300px;
                     
                        
                    }
                    
                    @media #{$sm-only} {
                        max-width: 350px;
                        
                    }
                    
                    @media #{$md-only} {
                        max-width: 420px;
                        margin-top:0px;
                        
                    }
                    
                    @media #{$lg-only} {
                        max-width: 500px;
                        margin-top:50px;
                    }
                    
                      @media #{$xl-only} {
                        max-width: 500px;
                        margin-top:50px;
        
                    }
                }
            }
        }
        .text {
            color: white;
            max-width: 500px;
            @media #{$md-only} {
                max-width: 368px;
            }
            @media #{$xs-only, $sm-only} {
                max-width: none;
                padding-top: 45px;
            }
        }
    }

    .banner .yellow-banner {
        background-color: $yellow;
        background-image: radial-gradient(circle, #FCCB5E, $yellow, $yellow, $yellow, $yellow, $yellow);
    }

    .animation {
        overflow: hidden;
    }

    .banner {
        position: relative;

        .buttons {
            .black-button {
                background-color: $black;
                color: $yellow;
                border: 1px solid $black;
                &:hover {
                    background-color: $yellow;
                    border: 1px solid $black;
                    border-style: inset;
                    color: $black;
                    transition: background-color 0.5s ease;
                }
            }

            .black-button:hover a {
                color: $yellow;
            }
            .black-button:hover .chevron {
                color: $black;
            }
            .right-button {
                margin-left: 20px;
                @media #{$xs-only} {
                    margin-left: 5px;
                }
            }
        }
    }


    .learn-more {
        background-color: $yellow;
        padding-top: 60px;
        padding-bottom: 60px;
        padding-left: 20px;
        padding-right: 20px;
        @media #{$sm-only, $xs-only} {
            padding-top: 20px;
            padding-bottom: 20px;
        }
        .learn-more-content {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            max-width: 1100px;
            margin: 0 auto;
            justify-content: center;
            align-items: center;
            @media #{$sm-only, $xs-only} {
                align-items: inherit;
            }
            .item {
                width: 31%;
                text-align: center;
                padding: 10px;
                @media #{$sm-only, $xs-only} {
                    width: 50%;
                    font-size: 14px;
                }
                img {
                    width: 70px;
                    margin-bottom: 5px;
                    flex-shrink: 0;
                    -webkit-animation: pop-in 0.5s 0.2s both;
                    -moz-animation: pop-in 0.5s 0.2s both;
                    -ms-animation: pop-in 0.5s 0.2s both;
                    animation-delay: 0.7s;
                    @media #{$sm-only, $xs-only} {
                        width: 60px;
                    }
                }
                p {
                    -webkit-animation: fadeIn 1s 0.2s both;
                    -moz-animation: fadeIn 1s 0.2s both;
                    -ms-animation: fadeIn 1s 0.2s both;
                    animation-delay: 0.8s;
                }
            }
            .item-line {
                @media #{$sm-only, $xs-only} {
                    border-bottom: 1px solid $black90;
                    padding-bottom: 20px;
                    padding-top: 20px;
                    animation: grow-width3 0.5s 0.2s both;
                    animation-delay: 0.6s;
                }
            }
            .item3 {
                @media #{$sm-only, $xs-only} {
                    padding-top: 20px;
                    padding-bottom: 20px;
                    border-bottom: 1px solid $black90;
                    animation: grow-width3 0.5s 0.2s both;
                    animation-delay: 0.6s;
                }
            }
            .second-row {
                padding-top: 50px;
                @media #{$sm-only, $xs-only} {
                    padding-top: 20px;
                }
            }
            .line {
                max-width: 1px;
                width: 1%;
                height: 150px;
                background-color: $black90;
                animation: grow-height 0.5s 0.2s both;
                animation-delay: 0.6s;
                @media #{$sm-only, $xs-only} {
                    display: none;
                }
            }
            .line2 {
                margin-top: 50px;
            }
            .no-line {
                @media #{$sm-only, $xs-only} {
                    border: none;
                }
            }
            .lines {
                display: flex;
                justify-content: space-around;
                width: 100%;
                @media #{$sm-only, $xs-only} {
                    display: none;
                }
                .line-horizontal {
                    width: 33%;
                    height: 1px;
                    background-color: $black90;
                    margin-top: 50px;
                    max-width: 280px;
                    margin-right: 30px;
                    margin-left: 30px;
                    animation: grow-width2 0.5s 0.2s both;
                    animation-delay: 0.6s;
                }
            }
        }
        &.black {
            background-color: $black90;
            .learn-more-content {
                .item-line {
                    border-bottom: $white;
                }
                .item3 {
                    border-bottom: $white;
                }
                .line {
                    background-color: $white;
                }
                .lines {
                    .line-horizontal {
                        background-color: $white;
                    }
                }

                p {
                    color: $white;
                }

            }
        }
    }

}