@rm-comp '31vCvMJ5deC' {

    @import 'sass/variables';
    @import 'sass/survey-common';

    h1 {
        color: #ffc425;
        font-size: 50px;
        font-weight: normal;
        padding-bottom: 20px;
        
        @media #{$xs-only, $sm-only} {
            font-size: 40px;
        }
    }

    h2 {
        color: #fff;
        font-size: 25px;
        font-weight: 400;
        padding-bottom: 50px;

        @media #{$xs-only, $sm-only} {
            font-size: 17px;
        }
    }

    p,
    label {
        color: #fff;
        font-size: 20px;
        display: block;
        padding-bottom: 30px;

        @media #{$xs-only, $sm-only} {
            font-size: 14px;
        }
    }
    
    @media #{$xs-only, $sm-only} {
        label {
            padding-top: 50px;
        }
    }

    .landing-container {
        min-height: calc(100vh - 409px);        
        background-color: #1a1a1a;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        height:auto;
        @media #{$lg-only} {
            min-height: calc(100vh - 411px);        
        }
        @media #{$md-only} {
            min-height:0;    
        }
        .text-container {
            text-align: center;
            padding: 20px 20px 100px 20px;
            width: 100%;
            @media #{$xs-only} {
                padding-bottom:120px;
            }
            @media #{$sm-only} {
                padding-bottom:150px;
            }
            @media #{$md-only} {
                padding-bottom:100px;   
            }
        }
    }
    
    .text-container-inner {
        max-width: 800px;
        display:block;
        margin:0 auto;
    }

    .desktop {
        @media #{$xs-only, $sm-only} {
            display: none;
        }
    }

    .mobile {
        @media #{$md-up} {
            display: none;
        }
    }

    .mobile h2 {
        padding-bottom: 15px;
    }

    .mobile p {
        padding-bottom: 20px;
        font-size: 14px;
    }

    .feedback {
        animation: fadein 2s;
    }

    .slider {
        display: block;
        background-color: #ffc425;
        max-width: 722px;
        height: 4px;
        width: 100%;

        @media #{$xs-only, $sm-only} {
            transform: rotate(90deg);
            width: 100%;
            max-width: 350px;
            margin-top: 150px;
            margin-bottom: 150px;
            margin-left: auto;
            margin-right: auto;
        }
    }
    #slider-values {
        display: flex;
        width: 100%;
        justify-content: space-between;
        & span {
            margin-top: 10px;
            color: $white;
        }
    }
    .slider-wrapper {
        margin-bottom:62px;
        @media #{$xs-only, $sm-only} {
            margin-bottom:0px;
        }
    }
    
    .service-text {
        border-radius: 10px;
        padding: 10px 20px;
        width: 100%;
        font-size: 16px;
        font-family: 'Open Sans', sans-serif;

        @media #{$xs-only, $sm-only} {
            font-size: 14px;
        }
    }
    
    .back-button-container .back-button {
        background: url("images/icons/back-button-yellow.svg");
        background-size:cover;
    }
    
    .back-text {
        position: absolute;
        display: block;
        color: #fff;
        opacity: 0;
        transition: 0.25s ease;
        p {
            padding:0;
        }
    }
   
    .back-button-container .back-button:focus, .next-button-container .next-button:focus {
        background: url("images/icons/back-button-white.svg");
        outline: none;
        background-size: cover;
        border-radius:100%;
        border: 1px solid $yellow;
    }
    
    .next-button-container .next-button {
        background: url("images/icons/back-button-yellow.svg");
        background-size:cover;
    }
    .back-button-container, .next-button-container {
        @media #{$sm-only} {
            bottom:70px;
        }
    }
    @media (-ms-high-contrast: none), (pointer: fine) {
        .back-button:hover, .next-button:hover {
            background: url("images/icons/back-button-white.svg");
            outline: none;
            background-size: cover;
        }
        .back-button:hover + .back-text, .next-button:hover + .next-text {
            opacity: 1;
        }
    }
    @media (-ms-high-contrast: none), (pointer: coarse) {
        .back-button:active, .next-button:active {
            background: url("images/icons/back-button-white.svg");
            outline: none;
            background-size: cover;
        }
    }
    
    .yellow-banner {
        background: radial-gradient(circle, #FCCB5E, #FFC425, #FFC425, #FFC425, #FFC425, #FFC425);

        h1, h2, label {
            color: $black90;
        }

        .back-button {
            background: url("images/icons/back-button.svg");
            background-size: cover;
        }
        
        .back-text {
            color: #fff;
        }
    }
    
        
}