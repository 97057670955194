@rm-comp 'w2DWdJhU2U' {

    @import 'sass/variables';

    @mixin banner-img($url) {
        width: 100%;
        height: 100%;
        background-image: url($url);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        @media #{$xs-only} {
            background-position: bottom;
        }
        @media #{$sm-only} {
            background-position: 100% 73%;
        }
        @media #{$md-only} {
            min-height: 515px;
        }
    }

    .img-1 {
        @include banner-img('images/banner-bgs/interactive-fitness.jpg');

        @media #{$xs-only} {
            @include banner-img('images/banner-bgs/interactive-mobile-fitness.jpg');
        }
        
        @media #{$sm-only} {
            @include banner-img('images/banner-bgs/interactive-small-fitness.jpg');
        }
        
        @media #{$md-only} {
            min-height: 515px;
        }
    }

    .img-2 {
        @include banner-img('images/banner-bgs/interactive-ecommerce.jpg');

        @media #{$xs-only} {
            @include banner-img('images/banner-bgs/interactive-mobile-ecommerce.jpg');
        }
        
        @media #{$sm-only} {
            @include banner-img('images/banner-bgs/interactive-small-ecommerce.jpg');
        }
        
        @media #{$md-only} {
            min-height: 515px;
        }
    }

    .img-3 {
        @include banner-img('images/banner-bgs/interactive-mortgage.jpg');

        @media #{$xs-only} {
            @include banner-img('images/banner-bgs/interactive-mobile-mortgage.jpg');
        }
        
        @media #{$sm-only} {
            @include banner-img('images/banner-bgs/interactive-small-mortgage.jpg');
        }
        
        @media #{$md-only} {
            min-height: 515px;
        }
    }

    .img-4 {
        @include banner-img('images/banner-bgs/interactive-travel.jpg');

        @media #{$xs-only} {
            @include banner-img('images/banner-bgs/interactive-mobile-travel.jpg');
        }
        
        @media #{$sm-only} {
            @include banner-img('images/banner-bgs/interactive-small-travel.jpg');
        }
        
        @media #{$md-only} {
            min-height: 515px;
        }
    }
    
    .img-1-financial {
        @include banner-img('images/banner-bgs/interactive-fitness.jpg');

        @media #{$xs-only} {
            @include banner-img('images/banner-bgs/interactive-mobile-fitness.jpg');
        }
        
        @media #{$sm-only} {
            @include banner-img('images/banner-bgs/interactive-small-fitness.jpg');
        }
        
        @media #{$md-only} {
            min-height: 515px;
        }
    }
    
    .img-2-financial {
        @include banner-img('images/banner-bgs/interactive-retire.jpg');

        @media #{$xs-only} {
            @include banner-img('images/banner-bgs/interactive-mobile-retire.jpg');
        }
        
        @media #{$sm-only} {
            @include banner-img('images/banner-bgs/interactive-small-retire.jpg');

        }
        @media #{$md-only} {
            min-height: 515px;
        }
    }
    
    .img-3-financial {
        @include banner-img('images/banner-bgs/interactive-mortgage.jpg');

        @media #{$xs-only} {
            @include banner-img('images/banner-bgs/interactive-mobile-mortgage.jpg');
        }
        
        @media #{$sm-only} {
            @include banner-img('images/banner-bgs/interactive-small-mortgage.jpg');
        }
        
        @media #{$md-only} {
            min-height: 515px;
        }
    }

    .img-4-financial {
        @include banner-img('images/banner-bgs/interactive-travel.jpg');

        @media #{$xs-only} {
            @include banner-img('images/banner-bgs/interactive-mobile-travel.jpg');
        }
        
        @media #{$sm-only} {
            @include banner-img('images/banner-bgs/interactive-small-travel.jpg');
        }
        
        @media #{$md-only} {
            min-height: 515px;
        }
    }
    
    .img-1-pharma {
        @include banner-img('images/banner-bgs/interactive-fitness.jpg');
        @media #{$xs-only} {
            @include banner-img('images/banner-bgs/interactive-mobile-fitness.jpg');
        }
        @media #{$sm-only} {
            @include banner-img('images/banner-bgs/interactive-small-fitness.jpg');
        }
        @media #{$md-only} {
            min-height: 515px;
        }
    }
    
    .img-2-pharma {
        @include banner-img('images/banner-bgs/interactive-medtracker.jpg');
        @media #{$xs-only} {
            @include banner-img('images/banner-bgs/interactive-mobile-medtracker.jpg');
        }
        @media #{$sm-only} {
            @include banner-img('images/banner-bgs/interactive-small-medtracker.jpg');
        }
        @media #{$md-only} {
            min-height: 515px;
        }
    }
    
    .img-3-pharma {
        @include banner-img('images/banner-bgs/interactive-allergy.jpg');
        @media #{$xs-only} {
            @include banner-img('images/banner-bgs/interactive-mobile-allergy.jpg');
        }
        @media #{$sm-only} {
            @include banner-img('images/banner-bgs/interactive-small-allergy.jpg');
        }
        @media #{$md-only} {
            min-height: 515px;
        }
    }

    .img-4-pharma {
        @include banner-img('images/banner-bgs/interactive-stress.jpg');
        @media #{$xs-only} {
            @include banner-img('images/banner-bgs/interactive-mobile-stress.jpg');
        }
        @media #{$sm-only} {
            @include banner-img('images/banner-bgs/interactive-small-stress.jpg');
        }
        @media #{$md-only} {
            min-height: 515px;
        }
    }
    
    .gallery {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .banner-interactive {
        position: relative;
        @media #{$md-only} {
            min-height: 515px;
        }
        .text {
            color: white;
            max-width: 450px;
            @media #{$md-only} {
                max-width: 368px;
            }
            @media #{$xs-only, $sm-only} {
                max-width: none;
                padding-top: 45px;
            }
        }
        .buttons {
            @media #{$xs-only, $sm-only} {
                padding-bottom: 352px;
            }
            @media #{$xs-only, $sm-only} {
                padding-bottom: 400px;
            }

            .selected {
                border: 1px solid $yellow;
                background-color: $yellow;
                .chevron {
                    color: $black;
                }
                &:hover {
                    background-color: $black;
                    border: 1px solid white;
                    border-style: inset;
                    color: $white;
                    transition: background-color 0.5s ease;
                }
            }
            .selected {
                background-color: $yellow;
                color: $black;
                border: 1px solid $yellow;
                .chevron {
                    color: $black;
                }
            }
            .yellow-button:hover .chevron {
                color: $black;
            }
            .black-button {
                background-color: $black90;
                color: white;
                border: 1px solid white;
                margin-left: 20px;
                @media #{$xs-only} {
                    margin-left: 5px;
                }
                .chevron {
                    color: $white;
                }
            }
        }
    }

    .controls {
        width: 100%;
        padding: 0 20px 30px;
        display: block;
        text-align: center;
        position: absolute;
        bottom: 0;
        .dots {
            width: 14px;
            height: 14px;
            border: 1px solid $black;
            border-radius: 100%;
            display: inline-block;
            vertical-align: middle;
            margin: 5px;
            border: 1px solid $white;
            background-color: $black;
            @media #{$sm-only, $xs-only} {
                width: 10px;
                height: 10px;
                margin: 4px;
            }
        }

        .selected-dot {
            background-color: $white;
            border: 1px solid $black;
        }
    }


    .learn-more {
        background-color: $yellow;
        padding-top: 60px;
        padding-bottom: 60px;
        padding-left: 20px;
        padding-right: 20px;
        @media #{$sm-only, $xs-only} {
            padding-top: 20px;
            padding-bottom: 20px;
        }
        .learn-more-content {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            max-width: 1100px;
            margin: 0 auto;
            justify-content: center;
            align-items: center;
            @media #{$sm-only, $xs-only} {
                align-items: inherit;
            }
            .item {
                width: 31%;
                text-align: center;
                padding: 10px;
                @media #{$sm-only, $xs-only} {
                    width: 50%;
                    font-size: 14px;
                }
                img {
                    width: 70px;
                    margin-bottom: 5px;
                    flex-shrink: 0;
                    -webkit-animation: pop-in 0.5s 0.2s both;
                    -moz-animation: pop-in 0.5s 0.2s both;
                    -ms-animation: pop-in 0.5s 0.2s both;
                    animation-delay: 0.7s;
                    @media #{$sm-only, $xs-only} {
                        width: 60px;
                    }
                }
                p {
                    -webkit-animation: fadeIn 1s 0.2s both;
                    -moz-animation: fadeIn 1s 0.2s both;
                    -ms-animation: fadeIn 1s 0.2s both;
                    animation-delay: 0.8s;
                }
            }
            .item-line {
                @media #{$sm-only, $xs-only} {
                    border-bottom: 1px solid $black90;
                    padding-bottom: 20px;
                    padding-top: 20px;
                    animation: grow-width3 0.5s 0.2s both;
                    animation-delay: 0.6s;
                }
            }
            .item3 {
                @media #{$sm-only, $xs-only} {
                    padding-top: 20px;
                    padding-bottom: 20px;
                    border-bottom: 1px solid $black90;
                    animation: grow-width3 0.5s 0.2s both;
                    animation-delay: 0.6s;
                }
            }
            .second-row {
                padding-top: 50px;
                @media #{$sm-only, $xs-only} {
                    padding-top: 20px;
                }
            }
            .line {
                max-width: 1px;
                width: 1%;
                height: 150px;
                background-color: $black90;
                animation: grow-height 0.5s 0.2s both;
                animation-delay: 0.6s;
                @media #{$sm-only, $xs-only} {
                    display: none;
                }
            }
            .line2 {
                margin-top: 50px;
            }
            .no-line {
                @media #{$sm-only, $xs-only} {
                    border: none;
                }
            }
            .lines {
                display: flex;
                justify-content: space-around;
                width: 100%;
                @media #{$sm-only, $xs-only} {
                    display: none;
                }
                .line-horizontal {
                    width: 33%;
                    height: 1px;
                    background-color: $black90;
                    margin-top: 50px;
                    max-width: 280px;
                    margin-right: 30px;
                    margin-left: 30px;
                    animation: grow-width2 0.5s 0.2s both;
                    animation-delay: 0.6s;
                }
            }
        }
        &.black {
            background-color: $black90;
            .learn-more-content {
                .item-line {
                    border-bottom: $white;
                }
                .item3 {
                    border-bottom: $white;
                }
                .line {
                    background-color: $white;
                }
                .lines {
                    .line-horizontal {
                        background-color: $white;
                    }
                }

                p {
                    color: $white;
                }

            }
        }
    }

  .screen_contain {
      position: relative;
        margin: 0 auto;
        max-width: 768px;
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        display: flex;
        justify-content: flex-end;
        pointer-events: none;

        @media #{$xs-only} {
        }  
      
        /* @media #{$sm-only} {
                    opacity: 0;
        }   */
        
      @media #{$md-only} {
        
      }
      
    }
    
    .hand_contain {
        position: relative;
        margin-top: 50px;
        margin-right: -170px;
        
            @media #{$xs-only} {
                transform: scale(0.725);
                max-width: 768px;
                transform-origin: center;
                margin-top: 118px;
                margin-right: calc(50% - 346px);
            }
            @media #{$sm-only} {
                transform: scale(0.725);
                max-width: 768px;
                transform-origin: center;
                margin-top: 129px;
                margin-right: calc(50% - 346px);
            }
            @media #{$md-only} {
                transform: scale(0.824);
                margin-right: -145px;
                margin-top: -9px;
            }
        }
    
    .phone_vid {
        height: 293.5px;
        width: 163px;
        position: relative;
        bottom: -199px;
        left: 295px;
        transform-origin: center;
        background-size:contain;
    
        @media #{$xs-only}  {
            width: 153px;
            position: absolute;
            transform: scale(0.77);
            bottom: 147px;
            left: calc(50% - 72px);
            height: 275px;
        }
    
        @media #{$sm-only} {
            // width: 100%;
            position: absolute;
            transform: scale(0.725);
            bottom: 137px;
            left: calc(50% - 79px);
        }
        @media #{$md-only} {
            height: 240.5px;
            width: 134px;
            position: absolute;
            bottom: 164px;
            left: auto;
            right: 156px;
        }
        
        > video {
            position: absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            display:none;

            .card-selected & {
                display: block;
            }
        }
        
    }
    
    .hand {
        @media #{$xs-only} {
            
        }

        @media #{$sm-only} {
        }
    }
}