@rm-timeline banner-apps-websites {
    animation-iteration-count: 1;

    :t(0s) {
        .content {
            transform: translate(0, -50px);
            opacity:0;
        }
    }
    :t(0.5s) {
        .content {
            transform: translate(0, 0);
            opacity:1;
        }
    }
}

@rm-timeline banner-interactive-tools {
    animation-iteration-count: 1;

    :t(0s) {
        .content {
            transform: translate(0, -50px);
            opacity:0;
        }
    }
    :t(0.5s) {
        .content {
            transform: translate(0, 0);
            opacity:1;
        }
    }
}

@rm-timeline banner-personalized-video {
    animation-iteration-count: 1;

    :t(0s) {
        .content {
            transform: translate(0, -50px);
            opacity:0;
        }
    }
    :t(0.5s) {
        .content {
            transform: translate(0, 0);
            opacity:1;
        }
    }
}