@rm-comp 'G-X9Rd7o2d' {

    @import 'sass/variables';

        .project-category {
            color:white;
            font-size:30px;
            max-width:1100px;
            margin:0 auto;
            padding-left:20px;
            padding-top:40px;
            @media #{$lg-only} {
                max-width:825px;
            }
            @media #{$md-only} {
                max-width:550px;
            }
            @media #{$sm-only} {
                max-width:550px;
            }
            @media #{$xs-only} {
                max-width:320px;
                padding-left:10px;
                margin-bottom:15px;
                padding-top:30px;
            }
        }
        .tiles {
            display:flex;
            flex-wrap:wrap;
            max-width:1100px;
            justify-content:center;
            margin:0 auto;
            padding-bottom:100px;
            @media #{$lg-only} {
                max-width:825px;
            }
            @media #{$md-only} {
                max-width:550px;
            }
            @media #{$sm-only} {
                max-width:550px;
            }
            @media #{$xs-only} {
                max-width:320px;
                padding-bottom:60px;
            }
        }
        .tile {
            width:235px;
            height:235px;
            background-color:white;
            margin:20px;
            display: block;
            text-decoration: none;
            color: inherit;
            transition:opacity 0.5s ease;
            @media #{$xs-only} {
                width:140px;
                height:140px;
                margin:5px;
                background-size:140px 140px;
            }
            .client-name {
                height:65px;
                width:100%;
                background-color:white;
                margin-top:170px;
                text-align:center;
                border-top:1px solid #d8d8d8;
                @media #{$xs-only} {
                    margin-top:100px;
                    height:40px;
                }
                &:hover {
                    background-color:transparent;
                    width:0;
                    height:0;
                }
                img {
                    max-width:150px;
                    margin-top:10px;
                    @media #{$xs-only} {
                        max-width:115px;
                        margin-top:5px;
                    }
                }
                img:hover {
                    display:none;
                }
            }
        }
        .overlay {
            position: relative;
            top: -235px;
            bottom: 0;
            left: 0;
            right: 0;
            height: 235px;
            width: 235px;
            opacity: 0;
            background-color: rgba(255,255,255, 0.85);
            text-align:center;
            transition:opacity 0.5s ease;
            @media #{$xs-only} {
                width:140px;
                height:140px;
                top:-140px;
            }
            img {
                max-width:200px;
                text-align:center;
                margin-top:40px;
                @media #{$xs-only} {
                    max-width:120px;
                }
            }
            h2 {
                font-size:22px;
                text-align:center;
                font-weight:400;
                margin-right:10px;
                margin-left:10px;
                @media #{$xs-only} {
                    font-size:16px;
                }
            }
        }
        .tile:hover .overlay {
            opacity:1;
            top:0;
            transition:opacity 0.5s ease;
        }
        .tile:hover .client-name {
            display:none;
        }
}