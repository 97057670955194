.transcript-dialog {
    margin: auto;
    max-width: 1060px;
    border: 3px solid black;
    border-radius: 15px;
    overflow: hidden;
    background: #FFC425;
    position: relative;
}

.transcript-dialog .body {
    padding: 0px 15px;
}

.transcript-dialog h1 {
    background: black;
    color: white;
    font-size: 1.5rem;
    padding: 5px 10px;
}

.transcript-dialog p {
    margin: 10px 0;
}

.transcript-dialog .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    color: white;
    height: 38px;
    width: 38px;
    font-size: 2rem;
    line-height: 2rem;
    padding-bottom: 2px;
}