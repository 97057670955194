@rm-comp '9oWiOT9Zpj_' {

    @import "sass/variables";
    @import "sass/survey-common";

    h1 {
        color: #ffc425;
        font-size: 100px;
        font-weight: 600;
        padding-bottom: 20px;
        text-align: center;
        @media #{$xs-only, $sm-only} {
            font-size: 40px;
        }
    }

    h2 {
        color: #fff;
        font-size: 30px;
        font-weight: 400;
        padding-bottom: 20px;
        text-align: center;
        @media #{$xs-only, $sm-only} {
            font-size: 17px;
        }
    }

    h3 {
        color: white;
        font-size: 30px;
        padding-bottom: 30px;
        font-weight: normal;
    }
    .h3-yellow {
        color: $yellow;
        font-weight: bold;
    }

    .btn:hover {
        background-color: white;
        color: #1a1a1a;
    }

    .main-container {
        overflow-x: hidden;
    }
}